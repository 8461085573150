import usernav from "./usernav";
import adminnav from "./adminnav";
import appsnav from "./appsnav";
import type { VerticalNavItems } from "@/@layouts/types";

export default [
  {
    title: "Home",
    to: { name: "index" },
    icon: { icon: "mdi-home-outline" },
  },
  {
    title: "Home",
    to: { name: "index" },
    icon: { icon: "mdi-home-outline" },
    isAuthRequired: false,
  },
  ...usernav,
  ...appsnav,
  ...adminnav,
] as VerticalNavItems;
