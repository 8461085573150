import { pb } from "@/utils/PocketBaseAdapter";
import { getBookingStatus } from "@/utils/Formatting";
import { debounce } from "lodash";
import { moment } from "@/utils/useTimeZone";

export const usePriorityBooking = defineStore("priorityBooking", () => {
  // Data
  const priorityBookings = ref<any[]>([]);
  const isLoading = ref<boolean>(false);
  const isConfirmEditBookingDialog = ref<any>(false);

  // Method
  const getPriorityBookings = debounce(async (userId: string) => {
    try {
      isLoading.value = true;

      const currentTime = moment().utc().format("YYYY-MM-DD HH:mm:ss"); // Use ISO format for compatibility

      // Fetch bookings with minimal required fields to reduce payload
      const { items: bookings } = await pb
        .collection("bookings")
        .getList(1, 4, {
          filter: `users ~ '${userId}' && state != 'Deleted' && endTime > '${currentTime}'`,
          sort: "startTime",
          expand: "area,activity,location,docketLine", // Expand only required fields
        });

      if (!bookings.length) {
        priorityBookings.value = [];
        return;
      }

      // assign the status of booking
      bookings?.forEach((booking) => {
        booking.status = getBookingStatus(
          booking.actualStartTime,
          booking.actualDuration
        );
      });

      // Create a lookup map for booking IDs
      const bookingIdsSet = new Set(bookings.map((booking: any) => booking.id));

      // Combine the filter string for recurringBookings and events
      const filterString = Array.from(bookingIdsSet)
        .map((id) => `bookings ~ '${id}'`)
        .join(" || ");

      // Parallel API calls for recurringBookings and events
      const [recurringBookingsList, eventsList] = await Promise.all([
        pb.collection("recurringBookings").getFullList({
          filter: filterString,
          fields: "id,bookings", // Only fetch necessary fields
        }),
        pb.collection("events").getFullList({
          filter: filterString,
          expand: "bookings", // Only expand if necessary
          fields: "id,bookings", // Minimize payload
        }),
      ]);

      // Early exit if no recurringBookings or events exist
      if (!recurringBookingsList.length && !eventsList.length) {
        priorityBookings.value = bookings;
        return;
      }

      // Create lookup maps for recurringBookings and events
      const recurringBookingsMap = new Map(
        recurringBookingsList.flatMap((recurring: any) =>
          recurring.bookings.map((booking: any) => [booking, recurring])
        )
      );

      const eventsMap = new Map(
        eventsList.flatMap((event: any) =>
          event.bookings.map((booking: any) => [booking, event])
        )
      );

      // Conditionally fetch recurringEvents based on event IDs
      let recurringEventsList = [] as any;
      if (eventsList.length) {
        const eventIdsSet = new Set(eventsList.map((event: any) => event.id));
        const eventFilterString = Array.from(eventIdsSet)
          .map((id) => `events ~ '${id}'`)
          .join(" || ");

        recurringEventsList = await pb
          .collection("recurringEvents")
          .getFullList({
            filter: eventFilterString,
            expand: "events.bookings.docketLine", // Fetch required expansions
          });
      }

      // Prepare lookup map for recurringEvents
      const recurringEventsMap = new Map(
        recurringEventsList.flatMap((recurringEvent: any) =>
          recurringEvent.expand?.events?.flatMap((event: any) =>
            event.bookings.map((booking: any) => [booking, recurringEvent])
          )
        )
      );

      // Attach details to bookings using map lookups
      bookings.forEach((booking) => {
        booking.recurringBookingDetails =
          recurringBookingsMap.get(booking.id) || {};
        booking.eventDetails = eventsMap.get(booking.id) || {};
        booking.recurringEventDetails =
          recurringEventsMap.get(booking.id) || {};
      });

      // Update state with processed bookings
      priorityBookings.value = bookings;
    } catch (e) {
    } finally {
      isLoading.value = false;
    }
  }, 300);

  return {
    isLoading,
    priorityBookings,
    isConfirmEditBookingDialog,
    getPriorityBookings,
  };
});
