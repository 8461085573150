export default [
  { heading: "Apps", permissionRequired: "canSeeApps" },
  {
    title: "Dockets",
    to: "dockets",
    icon: { icon: "mdi-cart" },
    disable: false,
    permissionRequired: "canSeeApps",
    isSetToVisible: true,
  },
  {
    title: "POS",
    to: "pos",
    icon: { icon: "mdi-network-pos" },
    disable: false,
    permissionRequired: "canSeeApps",
    isSetToVisible: true,
  },
  {
    title: "Kiosk",
    to: null,
    icon: { icon: "mdi-calendar-select" },
    disable: true,
    permissionRequired: "canSeeApps",
    isSetToVisible: true,
  },
  {
    title: "Staff TimeClock",
    to: "staff-timeclock",
    icon: { icon: "mdi-timer-sync-outline" },
    disable: false,
    permissionRequired: "canSeeAdmin",
    isSetToVisible: true,
  },
];
