import { useListStore } from "@/store/listStore";
import { useDiscountCode } from "@/store/useDiscountCode";
import { Round2Num } from "@/utils/Formatting";
import type { DocketRecordModel } from "@/utils/PocketBaseAdapter";
import { collections, pb } from "@/utils/PocketBaseAdapter";
import { useUserStore } from "@/utils/UserStore";
import { cloneDeep, isEmpty } from "lodash";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import { useCheckoutStore } from "./Docket";
import { useProductStore } from "./ProductStore";
import { useDocket } from "./useDocket";
import { moment } from "@/utils/useTimeZone";

export const useDocketStore = defineStore("DocketStore", () => {
  // Interface
  interface Discount {
    productDiscount?: number;
    bookingDiscount?: number;
    eventDiscount?: number;
  }

  // route
  const route = useRoute();

  // Store
  const docketStore = useCheckoutStore();
  const docket = useDocket();
  const userStore = useUserStore();
  const useProducts = useProductStore();
  const useList = useListStore();
  const discount = useDiscountCode();

  const {
    fetchDocketLines,
    calculateExistingDiscount,
    calculateDiscountedAmount,
    fetchDocketPayments,
    calculateTotalPaidAmount,
    updateDocketLines,
    showDiscountAppliedSuccess,
    showHigherDiscountWarning,
  } = discount;

  const { userId, currentLocation } = storeToRefs(userStore);
  const { products } = storeToRefs(useProducts);
  const {
    processingItems,
    payingNow,
    partialPayingAmount,
    balanceToPay,
    splitBy,
    isFullScreenLoading,
    activePaymentTab,
    lineTotals,
    docketLinesTotal,
    remainingBalance,
    intialSplit,
    docketNumber,
    docketDescription,
    getBookingRecords,
    fobDetails,
    originalDocketItems,
  } = storeToRefs(docketStore);
  const { productsMap } = storeToRefs(useList);

  // composable
  const { getDocketLinesItems } = docket;

  // Data
  const dockets = ref<DocketRecordModel[]>([]);
  const docketDiscountData = ref<Discount>({});
  const terminalList = ref<any[]>([]);
  const docketStatus = ref<string[]>(["Open", "Partial"]);
  const isInitialized = ref<boolean>(false);
  const createdGiftCards = ref<any[]>([]);
  const createdEvents = ref<any[]>([]);
  const createdCoupons = ref<any[]>([]);
  const maxDiscountApplicable = ref<number>(0);

  // Actions
  // 👉 Fetch all Dockets
  const fetchDockets = (params: any) => {
    let filters = "";

    if (params.location) {
      if (filters) filters += ` && location="${params?.location}"`;
      else filters = `location="${params?.location}"`;
    } else {
      filters = `location="${currentLocation.value}"`;
    }

    if (params.status) {
      const statusFilter = params.status?.length
        ? `(${params.status
            .map((status: any) => `status="${status}"`)
            .join(" || ")})`
        : "";

      if (filters) filters += `${statusFilter}`;
      else filters = `${statusFilter}`;
    }

    if (params.q) {
      if (filters)
        filters += `&& (description ~ "${params.q}" || docketNumber ~ "${params.q}" || total = "${params.q}" || due = "${params.q}") `;
      else
        filters = `description ~ "${params.q}" || docketNumber ~ "${params.q}"`;
    }
    let sortParam = "-docketNumber";
    if (params?.sortBy.length) {
      sortParam =
        params?.sortBy[0].order === "desc"
          ? `-${params?.sortBy[0].key}`
          : params?.sortBy[0].key;
    }

    return pb
      .collection(collections.dockets)
      .getList(params.page, params.itemsPerPage, {
        filter: filters,
        expand: "user,location, docketLines(docket),docketPayments(docket)",
        sort: sortParam ? sortParam : "",
      });
  };

  // fetch applied discount of docket
  const fetchDiscountPercentage = async () => {
    if (!route?.query?.did) {
      // clear percentage record for new cart
      docketDiscountData.value = {
        bookingDiscount: 0,
        productDiscount: 0,
        eventDiscount: 0,
      };
      maxDiscountApplicable.value = 0;
      return;
    }
    try {
      let docketId = route?.query?.did || ("" as any);
      const docket = await pb.collection("dockets").getOne(docketId, {
        expand: "discountCode",
      });
      if (docket) {
        const { discountBookings, discountProducts, discountEvents, expand } =
          docket;
        // update product/service discount
        docketDiscountData.value = {
          bookingDiscount: discountBookings,
          productDiscount: discountProducts,
          eventDiscount: discountEvents,
        };
        const { discountCode } = expand || {};

        if (discountCode && discountCode?.maxDiscountValue) {
          maxDiscountApplicable.value = discountCode?.maxDiscountValue;
        }
      }
    } catch (e) {}
  };

  // calculate docketLine total payment
  const calculateRemainingPayAmount = async (docketId: any) => {
    try {
      console.log("in the calculate payment", docketId);
      const DocketPromise = await pb
        .collection(collections.dockets)
        .getOne<DocketRecordModel>(docketId);

      docketNumber.value = DocketPromise.docketNumber;
      docketDescription.value = DocketPromise.description;

      // get docket lines items using docket id
      const docketLines = await pb
        .collection(collections.docketLines)
        .getList(1, 200, {
          filter: `docket = '${docketId}'`,
        });

      // The lines in the docketLines need to be total and the approved docketPayments should be subtracted to get the BallanceToPay
      let docketLinesItemsTotal = 0;
      // let docketLineItemsBalance = 0;
      if (docketLines.items.length) {
        docketLines.items.forEach((docket) => {
          docketLinesItemsTotal += docket.itemPrice * docket.qty;
          // docketLineItemsBalance += docket.lineTotal;
        });
      }

      let totalDiscount = docketLines.items?.reduce((acc, item) => {
        if (item.discount > 0) {
          const totalAmount = item.qty * item.itemPrice;
          const discountedAmount = totalAmount - item?.lineTotal;
          return acc + discountedAmount;
        }
        return acc;
      }, 0);

      // round upto 2 decimal
      totalDiscount = Round2Num(totalDiscount, 2);

      lineTotals.value = docketLinesItemsTotal - totalDiscount;
      docketLinesTotal.value = docketLinesItemsTotal;

      // get docket payments
      const docketPayments = await pb
        .collection(collections.docketPayments)
        .getList(1, 50, {
          filter: `docket = '${docketId}' && status = 'Approved'`,
        });

      let paymentDone = 0;
      if (docketPayments.items.length) {
        docketPayments.items.forEach((payment) => {
          paymentDone += payment.amount;
        });
      }

      // store balanceToPay for globally use in all tabs
      balanceToPay.value = Number((lineTotals.value - paymentDone).toFixed(2));
      remainingBalance.value = Number(
        (lineTotals.value - paymentDone).toFixed(2)
      );
    } catch (e) {}
  };

  const totalPayingNow = () => {
    return processingItems.value.reduce(
      (
        total,
        item: {
          discount: number;
          inProcessing: number;
          itemPrice: number;
        }
      ) => {
        const discountMultiplier = 1 - item.discount / 100;
        const itemTotal =
          item?.itemPrice * item.inProcessing * discountMultiplier;
        return total + itemTotal;
      },
      0
    );
  };

  // Update all required field of store after payment tab change
  const paymentModeTabChanging = async (tab: any, docketId: any) => {
    try {
      // split payment tab
      if (tab === 1) {
        // not in partial tab clear the partial amount
        partialPayingAmount.value = 0;

        // set payingNow amount to 0 and splitBy to 1
        payingNow.value = 0;
        splitBy.value = 1;

        // set initial split to 1 as well
        intialSplit.value = 1;
      }

      // items payment tab
      if (tab == 2) {
        // closure function to calculate payingNow Amount

        // calculate and assign the value into payingNow
        if (processingItems.value.length) {
          payingNow.value = totalPayingNow();
        } else {
          // no item in processing
          payingNow.value = 0;
        }

        // not in partial tab clear the partial amount
        partialPayingAmount.value = 0;

        // not in split tab, clear the splitBy
        splitBy.value = 1;
      }

      // partial payment tab
      if (tab == 3) {
        // not in split tab, clear the splitBy
        splitBy.value = 1;
        payingNow.value = 0;
      }

      // remainder payment tab
      if (tab == 4) {
        // not in partial tab clear the partial amount
        partialPayingAmount.value = 0;

        // not in split tab, clear the splitBy
        splitBy.value = 1;

        // update paying now to full remaining
        payingNow.value = balanceToPay.value;

        // calculate prices and dues again
        await calculateRemainingPayAmount(docketId);
      }

      // not in item tab and there any processing items clear all the item and show in available list
      if (tab != 2) {
        try {
          let filter = `docket="${docketId}"`;
          const docketLineItems = await pb
            .collection("docketLines")
            .getFullList({
              filter: filter,
            });

          if (docketLineItems) {
            const promises: any[] = [];
            docketLineItems.forEach(async (dLine) => {
              // any items goes in processing state only need to update otherwise ignore unnecessary API call
              if (dLine.processing > dLine.processed) {
                // make all the items move back to available
                dLine.processing = dLine.processed;
                dLine.updatedBy = userId.value;
                promises.push(
                  pb.collection("docketLines").update(dLine.id, dLine)
                );
              }
            });

            // after update completely then refetch list
            let record = await Promise.all(promises);
            if (record) {
              // fetch the all list items
              await getDocketLinesItems(docketId);
              processingItems.value = [];
            }
          }
        } catch (e) {}
      }
    } catch (e) {}
  };

  // Payment approved, so update all the processed key
  const paymentApproved = async (docketId: any) => {
    try {
      // start loading until fetch the details
      isFullScreenLoading.value = true;

      // calculate prices and dues again
      await calculateRemainingPayAmount(docketId);

      // split tab
      if (activePaymentTab.value == 1) {
        if (splitBy.value > 2) {
          splitBy.value -= 1;

          // update the splitBy
          payingNow.value = balanceToPay.value / splitBy.value;
        } else if (splitBy.value == 2) {
          splitBy.value = 1;
          intialSplit.value = 1;

          // update the splitBy
          payingNow.value = balanceToPay.value / 1;
        }
      }

      // in item tab update the processed key
      if (activePaymentTab.value == 2) {
        try {
          let filter = `docket="${docketId}"`;
          const docketLineItems = await pb
            .collection("docketLines")
            .getFullList({
              filter: filter,
            });

          // update processed key as payment success
          let promises: any[] = [];
          docketLineItems.forEach(async (item) => {
            // update processed key as number of quantity of item as its processed payment
            item.processed = item.processing;
            item.updatedBy = userId.value;
            promises.push(
              await pb.collection("docketLines").update(item.id, item)
            );
          });

          // after update completely then refetch list
          const record = await Promise.all(promises);
          if (record) {
            // fetch the all list items
            await getDocketLinesItems(docketId);

            payingNow.value = totalPayingNow();
            processingItems.value = [];
          }
        } catch (e) {}
      }

      // partial tab
      if (activePaymentTab.value == 3) {
        partialPayingAmount.value = 0;
        payingNow.value = 0;
      }

      if (activePaymentTab.value == 4) {
        payingNow.value = remainingBalance.value;
      }

      // docker due cleared and there a booking docketLine exist
      // update booking as paid and due as 0
      if (getBookingRecords.value?.length > 0 && !balanceToPay.value) {
        for (const record of getBookingRecords.value) {
          const bookingData = await pb
            .collection("bookings")
            .getOne(record?.booking, {
              fields: "due,paid,id,state,payMethod",
            });

          // If booking data exists, update it
          if (bookingData) {
            await pb.collection("bookings").update(record?.booking, {
              paid: bookingData.due, // Set paid to the current due amount
              due: 0, // Set due to 0
              state: "Confirmed", // Update state
              payMethod: Array.from(
                new Set([...(bookingData?.payMethod || []), "POS"])
              ),
            });
          }
        }
      }

      // stop the loading
      isFullScreenLoading.value = false;
    } catch (e) {
      // stop the loading
      isFullScreenLoading.value = false;
    }
  };

  // Fetch terminal list
  const getAvailableTerminalList = async () => {
    try {
      const records = await pb.collection("paymentTerminals").getFullList({
        filter: `location="${currentLocation.value}"`,
      });
      if (records) {
        let listItem = [] as any;
        records.forEach((el: any) => {
          if (el.enabled) {
            el.label = `# ${el.terminalNumber} ${el.description}`;
            listItem.push(el);
          }
        });

        terminalList.value = listItem;
      }
    } catch (e) {}
  };

  const discountPercentage = (item: any) => {
    const product = productsMap.value[item.product];
    const { maxDiscount, memberBookingDiscountApplies } = product || {};

    // Early return if no discount data
    if (isEmpty(docketDiscountData?.value)) {
      return 0;
    }

    // Determine discount type
    const discountType = memberBookingDiscountApplies
      ? "bookingDiscount"
      : "productDiscount";
    const applicableDiscount = docketDiscountData.value[discountType] || 0;

    // Apply the smaller of the applicable discount and the max discount
    return Math.min(applicableDiscount, maxDiscount || 0);
  };

  const applyDiscountsToDocketLines = (docketLines: any[]) => {
    const { eventDiscount, bookingDiscount, productDiscount } =
      fobDetails.value;

    return docketLines.map((line) => {
      const updatedLine = { ...line };

      if (line.transactionType === "Product") {
        const maxDiscountOfProduct = line.expand?.product?.maxDiscount;
        if (maxDiscountOfProduct) {
          updatedLine.discount = Math.min(
            maxDiscountOfProduct,
            productDiscount
          );
        } else {
          updatedLine.discount = productDiscount;
        }
      } else if (line.transactionType === "Event") {
        updatedLine.discount = eventDiscount;
      } else if (line.transactionType === "Booking Payment") {
        updatedLine.discount = bookingDiscount;
      }

      const discountAmount = (line.itemPrice * updatedLine.discount) / 100;
      updatedLine.lineTotal = Round2Num(
        line.qty * (line.itemPrice - discountAmount),
        2
      );

      return updatedLine;
    });
  };

  const calculateAndUpdateDocket = async (
    docketId: string,
    discountedAmount: number,
    totalPaidAmount: number
  ) => {
    const docketData = await pb.collection("dockets").getOne(docketId);
    const due = Round2Num(
      docketData.total - totalPaidAmount - discountedAmount,
      2
    );

    const { discountSources = [] } = docketData;
    let sourceOfDiscount = [];

    if (discountSources.includes("POS")) {
      sourceOfDiscount = ["POS", "Membership"];
    } else {
      sourceOfDiscount = ["Membership"];
    }

    await pb.collection("dockets").update(docketId, {
      discountProducts: fobDetails.value.productDiscount,
      discountBookings: fobDetails.value.bookingDiscount,
      discountEvents: fobDetails.value.eventDiscount,
      due: due,
      discountSources: sourceOfDiscount,
      user: fobDetails?.value?.userId,
    });

    docketDiscountData.value = {
      bookingDiscount: fobDetails.value?.productDiscount,
      productDiscount: fobDetails.value?.productDiscount,
      eventDiscount: fobDetails.value?.eventDiscount,
    };

    return due;
  };

  const handleAutoDiscountForFobUser = async (userId: string) => {
    const docketId = route?.query?.did as string;
    if (!userId) {
      fobDetails.value = {
        isVisible: true,
        membership: "",
        productDiscount: 0,
        bookingDiscount: 0,
        eventDiscount: 0,
        userId: null,
        userName: "",
      };

      // only remove user from the docket, let discount as it is
      await pb.collection("dockets").update(docketId, {
        user: "",
      });
      return;
    }

    const docketLines = cloneDeep(originalDocketItems.value);
    const discountedAmount = calculateExistingDiscount(docketLines);

    const updatedDocketLines = applyDiscountsToDocketLines(docketLines);

    // Calculate new discounted amount
    const newDiscountedAmount = calculateDiscountedAmount(updatedDocketLines);

    if (discountedAmount > newDiscountedAmount) {
      showHigherDiscountWarning();
      return;
    }

    // Calculate due amount and update docket
    const docketPayments = await fetchDocketPayments(docketId);
    const totalPaidAmount = calculateTotalPaidAmount(docketPayments);
    await calculateAndUpdateDocket(
      docketId,
      newDiscountedAmount,
      totalPaidAmount
    );

    // Update docket lines with new discount and totals
    await updateDocketLines(updatedDocketLines);

    showDiscountAppliedSuccess();

    await calculateRemainingPayAmount(docketId);
  };

  const handleGenerateGiftCardOrEventTickets = async (
    giftCardEvents: any[]
  ) => {
    try {
      for (let i = 0; i < giftCardEvents.length; i++) {
        const item = giftCardEvents[i];

        if (item?.type === "Gift Card" && item?.qty > 0) {
          const giftCardIds = [];

          // Loop through the quantity to create each gift card
          for (let i = 0; i < item.qty; i++) {
            const giftCardRecord = await pb.collection("giftCards").create({
              void: false,
              originalAmount: item.itemPrice,
              balanceRemaining: item.itemPrice,
              paid: true,
              createdBy: userId.value,
            });

            // Store each gift card ID in the array
            giftCardIds.push(giftCardRecord.id);
            createdGiftCards.value.push(giftCardRecord);
          }

          // Update the docketLine with the array of gift card IDs
          await pb.collection("docketLines").update(item.docketLineId, {
            giftCard: giftCardIds,
          });
        }

        if (item?.type === "Event" && item?.qty > 0) {
          const eventTicketIds = [];
          const eventDetails = productsMap.value?.[item?.product];

          // Loop through the quantity to create each event ticket
          for (let i = 0; i < item.qty; i++) {
            const eventTicket = await pb.collection("eventTickets").create({
              event: eventDetails?.event,
              user: fobDetails?.value?.userId || userId.value || "",
              total: item?.itemPrice,
              due: 0,
              paid: true,
              productRecord: item?.product,
              barValue: eventDetails?.eventBarValue,
              barRemaining: eventDetails?.eventBarValue,
              eventDate: eventDetails?.eventDate,
            });

            // Store each event ticket ID in the array
            eventTicketIds.push(eventTicket.id);
            createdEvents.value.push(eventTicket);
          }

          // Update the docketLine with the array of event ticket IDs
          await pb.collection("docketLines").update(item.docketLineId, {
            eventTicket: eventTicketIds,
          });
        }

        if (item?.type === "Coupon" && item?.qty > 0) {
          const couponsId = [];
          let expiryTime = moment()
            .add(2, "years")
            .format("YYYY-MM-DD HH:mm:ss");

          // Loop through the quantity to create each event ticket
          for (let i = 0; i < item.qty; i++) {
            const coupon = await pb.collection("coupons").create({
              name: "test",
              qty: item?.couponQty,
              activity: "",
              qtyRemaining: item?.couponQty,
              expiryTime: expiryTime,
              expired: false,
              user: userId.value,
            });

            // Store each event ticket ID in the array
            couponsId.push(coupon.id);
            createdCoupons.value.push(coupon);
          }

          // Update the docketLine with the array of event ticket IDs
          await pb.collection("docketLines").update(item.docketLineId, {
            coupon: couponsId,
          });
        }
      }
    } catch (e) {}
  };

  // Initialization logic inside the docketStore
  const init = async () => {
    if (!isInitialized.value) {
      isInitialized.value = true; // Ensure it's initialized only once
    }
  };

  // Automatically initialize when the store is accessed or used
  watchEffect(async () => {
    if (!isInitialized.value) {
      await init();
    }
  });

  return {
    dockets,
    docketNumber,
    docketDescription,
    docketDiscountData,
    terminalList,
    docketStatus,
    createdGiftCards,
    createdEvents,
    createdCoupons,
    maxDiscountApplicable,
    fetchDockets,
    fetchDiscountPercentage,
    paymentModeTabChanging,
    paymentApproved,
    calculateRemainingPayAmount,
    getAvailableTerminalList,
    handleAutoDiscountForFobUser,
    handleGenerateGiftCardOrEventTickets,
  };
});
