import { pb } from "@/utils/PocketBaseAdapter";
import { getBookingStatus } from "@/utils/Formatting";
import { useBooking as useBookingStore } from "@/store/useBooking";
import { usePaymentStore } from "@/components/payment/usePaymentStore";
import { useGlobalSetupStore } from "@/utils/GlobalSetupStore";
import { useGlobalErrorStore } from "@/store/useGlobalError";
import { moment } from "@/utils/useTimeZone";
import isEmpty from "lodash/isEmpty";
import type { TimeSlot } from "@/components/types";

export const useBooking = () => {
  // composable
  const bookingStore = useBookingStore();
  const paymentStore = usePaymentStore();
  const globalStore = useGlobalSetupStore();
  const globalError = useGlobalErrorStore();

  const { editBookingData, recurringBookingId } = storeToRefs(bookingStore);
  const { docketId, docketLineId, isPendingBooking } =
    storeToRefs(paymentStore);
  const { globalSetup } = storeToRefs(globalStore);
  const { isBookingWarningDialogVisible, errorDateList } =
    storeToRefs(globalError);

  // Data
  const paymentOptions = ref<any[]>([
    {
      title: "Coupon / Gift card",
      value: "couponOrGift",
    },
    {
      title: "User Credit",
      value: "userCredit",
    },
    {
      title: "POS",
      value: "pos",
    },
    {
      title: "Wave Fee",
      value: "waveFee",
    },
  ]);
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const recurringWeeks = ref([
    { id: 1, label: "M", value: "Monday" },
    { id: 2, label: "T", value: "Tuesday" },
    { id: 3, label: "W", value: "Wednesday" },
    { id: 4, label: "T", value: "Thursday" },
    { id: 5, label: "F", value: "Friday" },
    { id: 6, label: "S", value: "Saturday" },
    { id: 7, label: "S", value: "Sunday" },
  ]);

  const alternativeAreaList = ref<any[]>([]);

  // computed
  const getSelectedRecurringItem = computed(() => (type: string) => {
    // Map recurringWeeks by id
    return recurringWeeks.value.reduce((acc: any, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  });

  const recurringEndDateConfig = computed(() => (maxWeek: number) => {
    // Calculate the maximum date using Moment.js
    const maxDate = moment().add(maxWeek * 7, "days");
    return {
      dateFormat: "d-m-Y",
      disable: [
        function (date: any) {
          const today = moment().startOf("day");
          const momentDate = moment(date).startOf("day");
          // Disable past dates or dates beyond the max week range
          return momentDate.isBefore(today) || momentDate.isAfter(maxDate);
        },
      ],
    };
  });

  const getAreaSingular = computed(() => {
    return globalSetup?.value?.areaSingular || "Bay";
  });

  // method
  const getColor = (item: TimeSlot) => {
    /**
     * Function to generate dynamic colors based on booking status and payment.
     *
     * @param {Object} item - The time slot object to determine the color for.
     * @returns {String} - The CSS class name representing the color for the time slot.
     */
    if (item?.isBooked) {
      const { due, state, status: bookingStatus } = item?.bookingDetails || {};
      // Determine color based on booking status and payment
      switch (true) {
        case state === "Pending":
          // "#F7DC6F" - Yellow Case 1: Booking state is pending
          return "yellow_bg";
        case !due && bookingStatus === "futureBooking":
          // "#E5E7E9" - Grey Case 2: Paid, future booking
          return "gray_bg";
        case !due && bookingStatus === "inprogress":
          // "#82E0AA" - Green Case 3: Paid, booking in progress
          return "green_bg";
        case due && bookingStatus === "futureBooking":
          // "#F8C471" - Orange Case 4: Not paid, future booking
          return "orange_bg";
        case due && bookingStatus === "inprogress":
          // "#F1948A" - Red Case 5: Not paid, booking in progress
          return "red_bg";
        case !due && bookingStatus === "completed":
          // "#99A3A4" - DarkGrey Case 6: Paid, booking completed
          return "darkgray_bg";
        case due && bookingStatus === "completed":
          // "#F1948A" - Red Case 7: Not paid, booking completed
          return "red_bg";
        default:
          // "lightgray" - Default color for other cases
          return "lightgray_bg";
      }
    }

    // If the time slot is not a normal hour, mark it as restricted
    if (!item?.isNormalHour) {
      // "lightgray"
      return "lightgray_bg";
    }

    return null; // Default return if no conditions are met
  };

  const getDayIdFromDate = (dateString: string) => {
    console.log(dateString);
    const dayName = moment(dateString).format("dddd"); // e.g., "Thursday"
    return recurringWeeks.value.find((week) => week.value === dayName)?.id || 1; // Default to Monday (id: 1)
  };

  const getDuration = (duration: any, getBookingSpacing: number) => {
    // Ensure duration and bookingSpacing are positive numbers
    if (duration < 0 || getBookingSpacing <= 0) {
      throw new Error(
        "Duration must be a positive number and bookingSpacing must be greater than 0"
      );
    }

    // Convert duration to minutes if it's not already
    const durationMinutes =
      typeof duration === "number" ? duration : Math.floor(duration / 60000);

    // Round the duration to the nearest booking spacing
    const roundedMinutes =
      Math.round(durationMinutes / getBookingSpacing) * getBookingSpacing;

    // Convert roundedMinutes to hours and minutes
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    // Return formatted duration
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  const mapSelectionToMinutes = async (selectedValue: any) => {
    // Ensure the input is a string
    selectedValue = selectedValue.toString();

    // If the input is a whole number (e.g., "1"), treat it as hours
    if (!selectedValue.includes(".")) {
      return Number(selectedValue) * 60;
    }

    // Split the time string and convert to minutes
    const [hours = "0", minutes = "0"] = selectedValue.split(".").map(Number);
    return hours * 60 + minutes;
  };

  const handleTicketPrint = async (
    type: "PRINT" | "EMAIL",
    bookingId: string
  ) => {
    const updateField =
      type === "PRINT" ? { requiresPrinting: true } : { requiresEmail: true };
    await pb.collection("bookings").update(bookingId, updateField);
  };

  const reserveSlot = async (
    duration: number,
    date: any,
    time: any,
    activity: string,
    area: string,
    owner: any,
    userId: string,
    pendingBookingId: string,
    due?: number,
    members?: any[]
  ) => {
    try {
      // Convert duration to minutes
      const durationTotal = await mapSelectionToMinutes(
        String(duration)?.replace(":", ".")
      );

      // Create ISO start time from date and time
      const startTime = moment(
        `${date} ${time}`,
        "DD-MM-YYYY HH:mm"
      ).toISOString();

      // Define booking data
      const bookingData = {
        startTime,
        duration: durationTotal,
        area,
        activity,
        owner,
        createdBy: userId,
        due,
        state: "Pending",
        users: members,
      };

      isPendingBooking.value = true;

      if (pendingBookingId) {
        // Update existing booking
        const record = await pb.collection("bookings").getOne(pendingBookingId);
        if (record) {
          Object.assign(record, bookingData, { updatedBy: userId }); // Update record with new values
          const response = await pb
            .collection("bookings")
            .update(record.id, record);
          return response?.id;
        }
      } else {
        // Create new booking
        const response = await pb.collection("bookings").create(bookingData);
        return response?.id;
      }
    } catch (e: any) {
      throw e;
    }
  };

  const removeExistingPendingBooking = async (bookingId: string) => {
    if (!recurringBookingId.value && !bookingId) return;

    try {
      // Handle recurring bookings
      if (recurringBookingId.value) {
        const { expand: { bookings } = {} } = await pb
          .collection("recurringBookings")
          .getOne(recurringBookingId.value, { expand: "bookings" });

        if (bookings?.length) {
          const deletePromises = bookings
            .filter((booking: any) => booking?.state === "Pending")
            .map((booking: any) =>
              pb.collection("bookings").delete(booking.id)
            );

          await Promise.all(deletePromises);
        }
      }
      // Handle single booking
      else {
        const { state, docketLine, id, actualStartTime } = await pb
          .collection("bookings")
          .getOne(bookingId);

        if (state === "Pending") {
          await (docketLine || actualStartTime
            ? pb
                .collection("bookings")
                .update(id, { state: "Deleted", value: 0 })
            : pb.collection("bookings").delete(id));
        }
      }
    } catch (error) {
      console.error("Error removing existing pending booking:", error);
    }
  };

  // update booking
  const updateBooking = async (booking: any) => {
    try {
      // Format date and time
      const formattedDate = moment(
        `${booking.date} ${booking.time}`,
        "DD-MM-YYYY HH:mm"
      ).toISOString();

      const updateRecord = async (record: any, booking: any) => {
        Object.assign(record, {
          startTime: formattedDate,
          duration: booking.duration,
          area: booking.area,
          users: booking.members,
          activity: booking.activity,
          visitors: booking.guests?.join(","),
          notes: booking.notes,
          due: booking.due,
          owner: booking.owner,
          updatedBy: booking.updatedBy,
          state: "Confirmed",
          discount: booking.discount,
          value: booking.value,
          ...(booking.actualStartTime && {
            actualStartTime: booking.actualStartTime,
            actualDuration: booking.actualDuration,
          }),
        });
        delete record.endTime;

        if (record?.docketLine === docketLineId.value && docketId.value) {
          await pb
            .collection("dockets")
            .update(docketId.value, { due: booking.due });
          await pb.collection("docketLines").update(docketLineId.value, {
            qty: booking.slot,
            lineTotal: booking.slot * booking.sessionPrice,
          });
        }

        await pb.collection("bookings").update(record.id, record);
        await refetchBooking(record.id);
        isPendingBooking.value = false;
      };

      if (!isEmpty(booking?.recurring)) {
        const { id, days, endDate, frequency, interval, isEditAll } =
          booking.recurring;
        const recurringItems = getSelectedRecurringItem.value(interval);
        const daysString = days
          .map((dayId: number) => recurringItems[dayId]?.value)
          .filter(Boolean)
          .join(", ");

        const endMoment = moment(endDate).startOf("day");
        let currentDate = moment().startOf("day");
        const allDates: any[] = [];

        // Collect valid booking dates
        while (currentDate.isSameOrBefore(endMoment)) {
          days.forEach((dayId: string) => {
            const dayOfWeek = recurringItems[dayId]?.value;
            if (dayOfWeek) {
              const targetDate = currentDate.clone().day(dayOfWeek);

              // Adjust to the next week's day if the target date is in the past
              const nextValidDate = targetDate.isBefore(currentDate)
                ? targetDate.add(1, "week")
                : targetDate;

              // Add the date to the list if it's within the end date range
              if (nextValidDate.isSameOrBefore(endMoment)) {
                allDates.push(nextValidDate.format("DD-MM-YYYY"));
              }
            }
          });
          currentDate
            .add(frequency, interval === "Weeks" ? "week" : "month")
            .startOf("day");
        }

        if (id) {
          const recurringBookingRecords = await pb
            .collection("recurringBookings")
            .getOne(id, { expand: "bookings" });

          const earliestBooking = (
            recurringBookingRecords?.expand?.bookings || []
          )?.reduce((earliest: any, current: any) => {
            const currentStartTime = moment(current.starttime);
            const earliestStartTime = earliest
              ? moment(earliest.starttime)
              : null;

            // Compare the start times using moment's isBefore method
            return !earliestStartTime ||
              currentStartTime.isBefore(earliestStartTime)
              ? current
              : earliest;
          }, null);

          let bookingId = booking.id || earliestBooking?.id;

          if (recurringBookingRecords?.bookings?.length) {
            // Update booking state if any in pending
            recurringBookingRecords?.expand?.bookings?.map(
              async (booking: any) => {
                if (booking.state === "Pending") {
                  await pb.collection("bookings").update(booking?.id, {
                    state: "Confirmed",
                  });
                }
              }
            );
            if (isEditAll) {
              await handleRecurringBookingUpdate(
                { ...booking, daysString },
                allDates,
                recurringBookingRecords?.expand?.bookings,
                id,
                endDate
              );
            } else {
              if (booking?.id) {
                // Update single booking state
                const record = await pb
                  .collection("bookings")
                  .getOne(bookingId);
                await updateRecord(record, booking);
              }
            }
          }

          // if booking.id exist then user try to updating the booking so fetch that so payment will further process
          // else recurring booking list exist then fetch the first booking or fetch booking.id
          // recurring booking then the payment flow need to manage for first booking only.
          await refetchBooking(bookingId);
        }
      } else {
        // Handle non-recurring booking
        const record = await pb.collection("bookings").getOne(booking.id);
        await updateRecord(record, booking);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const refetchBooking = async (bookingId: any = null) => {
    try {
      const id = editBookingData?.value?.id || bookingId;
      const bookingData = await pb.collection("bookings").getOne(id, {
        expand: "docketLine",
      });

      const status = getBookingStatus(
        bookingData?.actualStartTime,
        bookingData?.actualDuration
      );

      editBookingData.value = {
        ...bookingData,
        status,
        startDate: bookingData?.startTime,
        docketId: bookingData?.expand?.docketLine?.docket,
      };
    } catch (e) {}
  };

  const startBooking = async (booking: any) => {
    // Format date and time
    const date = moment(
      `${booking.date} ${booking.time}`,
      "DD-MM-YYYY HH:mm"
    ).toISOString();

    const record = await pb.collection("bookings").getOne(booking.id);
    // Update record fields
    Object.assign(record, {
      startTime: date,
      duration: booking.duration,
      area: booking.area,
      users: booking.members,
      activity: booking.activity,
      visitors: booking.guests?.join(","),
      notes: booking.notes,
      due: booking.due,
      owner: booking.owner,
      updatedBy: booking.updatedBy,
      state: "Confirmed",
      discount: booking.discount,
      value: booking.value,
      ...(booking.actualStartTime && {
        actualStartTime: booking.actualStartTime,
        actualDuration: booking.actualDuration,
      }),
    });

    // Remove endTime if it exists
    delete record.endTime;
    await pb.collection("bookings").update(record.id, record);
  };

  const handleRecurringBookingUpdate = async (
    booking: any,
    allDates: string[],
    bookingsList?: any[],
    recurringId?: string,
    endDate?: string
  ) => {
    const errorDates: string[] = [];
    const bookingIds: string[] = [];
    let allUpdatesSuccessful = true; // Flag to track overall success

    // Convert allDates to a Set for faster lookup
    const allDatesSet = new Set(allDates);

    // Helper function to update or create a booking in a specific area
    const tryBookingInArea = async (
      area: string,
      date: string,
      bookingData: any,
      isUpdate: boolean = false
    ) => {
      const discountPercentage = bookingData?.discount || 0;
      // Calculate the discount amount
      const discountAmount = (booking?.value * discountPercentage) / 100;
      // Apply the discount and ensure the due amount is non-negative
      const discountedValue = booking?.value - discountAmount;
      const dues = discountedValue - bookingData?.paid || 0;
      const finalDue = dues > 0 ? dues : 0;

      const updatedBooking = {
        ...bookingData,
        area: area,
        startTime: moment(
          `${date} ${booking.time}`,
          "DD-MM-YYYY HH:mm"
        ).toISOString(),
        activity: booking?.activity,
        due: finalDue,
        value: booking.value,
      };

      try {
        if (isUpdate) {
          await pb
            .collection("bookings")
            .update(updatedBooking.id, updatedBooking);
        } else {
          const createdBooking = await pb
            .collection("bookings")
            .create(updatedBooking);
          return createdBooking;
        }
        return true;
      } catch (e) {
        console.error(
          `Error ${
            isUpdate ? "updating" : "creating"
          } booking for ${date} in area ${area}:`,
          e
        );
        return false;
      }
    };

    // Step 1: Iterate over existing bookings and update them if they match the dates in `allDates`
    for (const existingBooking of bookingsList || []) {
      const existingBookingDate = moment(existingBooking.startTime).format(
        "DD-MM-YYYY"
      );

      if (allDatesSet.has(existingBookingDate)) {
        // Update booking if the date exists in allDates
        let areaUpdated = false;

        // Try booking in different areas in descending order
        for (let i = alternativeAreaList.value?.length - 1; i >= 0; i--) {
          const area = alternativeAreaList.value[i]?.id;
          const success = await tryBookingInArea(
            area,
            existingBookingDate,
            {
              ...existingBooking,
              id: existingBooking.id, // Ensure the ID is included for updates
            },
            true
          );

          if (success) {
            bookingIds.push(existingBooking.id);
            allDatesSet.delete(existingBookingDate); // Remove from set as it has been handled
            areaUpdated = true;
            break;
          }
        }

        if (!areaUpdated) {
          errorDates.push(existingBookingDate);
          allUpdatesSuccessful = false; // Set flag to false on error
        }
      } else {
        // If booking's date is not in allDates, try to use a new date from allDatesSet to update this booking
        if (allDatesSet.size > 0) {
          const newDate = allDatesSet.values().next().value as any; // Get the first remaining date
          let areaUpdated = false;

          // Try to update with the new date in different areas
          for (let i = alternativeAreaList.value?.length - 1; i >= 0; i--) {
            const area = alternativeAreaList.value[i]?.id;
            booking.area = area;
            const success = await tryBookingInArea(
              area,
              newDate,
              {
                ...existingBooking,
                startTime: moment(
                  `${newDate} ${booking.time}`,
                  "DD-MM-YYYY HH:mm"
                ).toISOString(),
                id: existingBooking.id, // Ensure the ID is included for updates
              },
              true
            );

            if (success) {
              bookingIds.push(existingBooking.id);
              allDatesSet.delete(newDate); // Remove the used date from set
              areaUpdated = true;
              break;
            }
          }

          if (!areaUpdated) {
            errorDates.push(newDate);
            allUpdatesSuccessful = false; // Set flag to false on error
          }
        } else {
          // No dates left to update with, so this booking is no longer needed
          try {
            console.log(
              `Booking for ${existingBookingDate} is no longer required. Deleting it.`
            );
            await pb.collection("bookings").delete(existingBooking.id);
          } catch (e) {
            console.error(
              `Error deleting booking for ${existingBookingDate}:`,
              e
            );
            errorDates.push(existingBookingDate);
            allUpdatesSuccessful = false; // Set flag to false on error
          }
        }
      }
    }

    // Step 2: Create new bookings for remaining dates in `allDatesSet`
    for (const newDate of allDatesSet) {
      let areaBooked = false;

      // Try to book in different areas in descending order
      for (let i = alternativeAreaList.value.length - 1; i >= 0; i--) {
        const area = alternativeAreaList.value[i]?.id;
        const createdBooking = await tryBookingInArea(area, newDate, {
          startTime: moment(
            `${newDate} ${booking.time}`,
            "DD-MM-YYYY HH:mm"
          ).toISOString(),
          duration: booking.duration,
          area: area,
          users: booking.members,
          activity: booking.activity,
          visitors: booking.guests?.join(","),
          notes: booking.notes,
          due: booking.due,
          owner: booking.owner,
          state: "Confirmed",
          discount: booking.discount,
          value: booking.value,
        });

        if (createdBooking) {
          bookingIds.push(createdBooking.id);
          areaBooked = true;
          break;
        }
      }

      if (!areaBooked) {
        console.error(`Failed to book ${newDate} in all available areas.`);
        errorDates.push(newDate);
        allUpdatesSuccessful = false; // Set flag to false on error
      }
    }

    // Step 3: Update or create the recurring bookings record only if all updates and creations were successful
    if (recurringId && allUpdatesSuccessful && bookingIds.length > 0) {
      try {
        const data = {
          bookings: bookingIds,
          days: booking.daysString,
          endDate: endDate,
          frequency: booking.frequency,
          interval: booking.interval,
        };
        await pb.collection("recurringBookings").update(recurringId, data);
      } catch (e) {
        console.error("Error updating recurring booking record:", e);
      }
    } else if (recurringId && !allUpdatesSuccessful) {
      console.error(
        "Not updating recurring record due to errors in booking operations."
      );
    }

    // Return any dates that had errors
    if (errorDates.length) {
      isBookingWarningDialogVisible.value = true;
      errorDateList.value = Array.from(new Set(errorDates)); // Convert Set back to array
    }
    console.log("Error dates:", errorDates);
  };

  const handleRecurringBooking = async (booking: any) => {
    try {
      // Format date and time
      const formattedDate = moment(
        `${booking.date} ${booking.time}`,
        "DD-MM-YYYY HH:mm"
      ).toISOString();

      const updateRecord = async (record: any, booking: any) => {
        Object.assign(record, {
          startTime: formattedDate,
          duration: booking.duration,
          area: booking.area,
          users: booking.members,
          activity: booking.activity,
          visitors: booking.guests?.join(","),
          notes: booking.notes,
          due: booking.due,
          owner: booking.owner,
          updatedBy: booking.updatedBy,
          state: "Confirmed",
          discount: booking.discount,
          value: booking.value,
        });
        delete record.endTime;

        if (record?.docketLine === docketLineId.value && docketId.value) {
          await pb
            .collection("dockets")
            .update(docketId.value, { due: booking.due });
          await pb.collection("docketLines").update(docketLineId.value, {
            qty: booking.slot,
            lineTotal: booking.slot * booking.sessionPrice,
          });
        }

        await pb.collection("bookings").update(record.id, record);
      };

      const handleRecurringBookingCreation = async (
        booking: any,
        allDates: string[]
      ) => {
        let bookingIds: string[] = [];

        // Create individual bookings for each date in the recurring series
        for (const bookingDate of allDates) {
          const bookingParams = {
            startTime: moment(
              `${bookingDate} ${booking.time}`,
              "DD-MM-YYYY HH:mm"
            ).toISOString(),
            duration: booking.duration,
            area: booking.area,
            users: booking.members,
            activity: booking.activity,
            visitors: booking.guests?.join(","),
            notes: booking.notes,
            due: booking.due,
            owner: booking.owner,
            state: "Pending",
            discount: booking.discount,
            value: booking.value,
          };
          const createdBooking = await pb
            .collection("bookings")
            .create(bookingParams);
          bookingIds.push(createdBooking.id);
        }

        // Prepare data for recurring booking configuration
        const data = {
          bookings: [...bookingIds],
          days: booking?.daysString,
          endDate: booking?.recurring?.endDate,
          frequency: booking?.recurring?.frequency,
          interval: booking?.recurring?.interval,
        };

        const newRecurringBooking = await pb
          .collection("recurringBookings")
          .create(data);
        // Update the booking with the new recurring booking ID
        booking.recurring.id = newRecurringBooking.id;
        recurringBookingId.value = newRecurringBooking.id;
      };

      if (!isEmpty(booking?.recurring)) {
        const { id, days, endDate, frequency, interval, isEditAll } =
          booking.recurring;
        const recurringItems = getSelectedRecurringItem.value(interval);
        const daysString = days
          .map((dayId: number) => recurringItems[dayId]?.value)
          .filter(Boolean)
          .join(", ");

        const endMoment = moment(endDate).startOf("day");
        let currentDate = moment().startOf("day");
        const allDates: any[] = [];

        // Collect valid booking dates
        while (currentDate.isSameOrBefore(endMoment)) {
          days.forEach((dayId: string) => {
            const dayOfWeek = recurringItems[dayId]?.value;
            if (dayOfWeek) {
              const targetDate = currentDate.clone().day(dayOfWeek);

              // Adjust to the next week's day if the target date is in the past
              const nextValidDate = targetDate.isBefore(currentDate)
                ? targetDate.add(1, "week")
                : targetDate;

              // Add the date to the list if it's within the end date range
              if (nextValidDate.isSameOrBefore(endMoment)) {
                allDates.push(nextValidDate.format("DD-MM-YYYY"));
              }
            }
          });
          currentDate
            .add(frequency, interval === "Weeks" ? "week" : "month")
            .startOf("day");
        }

        if (id) {
          const recurringBookingRecords = await pb
            .collection("recurringBookings")
            .getOne(id, { expand: "bookings" });

          if (recurringBookingRecords?.expand?.bookings?.length) {
            if (isEditAll) {
              // Update recurring record
              await handleRecurringBookingUpdate(
                { ...booking, daysString },
                allDates,
                recurringBookingRecords?.expand?.bookings,
                id,
                endDate
              );
              isPendingBooking.value = false;
            } else {
              // Update single record
              const record = await pb.collection("bookings").getOne(booking.id);
              await updateRecord(record, booking);
            }
          }
        } else {
          // If no recurring booking exists, create a new one
          await handleRecurringBookingCreation(
            { ...booking, daysString },
            allDates
          );
          isPendingBooking.value = true;
        }
      }
    } catch (e: any) {
      throw e;
    }
  };

  return {
    paymentOptions,
    daysOfWeek,
    recurringWeeks,
    recurringEndDateConfig,
    getAreaSingular,
    alternativeAreaList,
    getColor,
    reserveSlot,
    getDuration,
    removeExistingPendingBooking,
    mapSelectionToMinutes,
    updateBooking,
    refetchBooking,
    startBooking,
    handleRecurringBooking,
    getDayIdFromDate,
    handleTicketPrint,
  };
};
