import { pb } from "@/utils/PocketBaseAdapter";
import { useEventStore } from "@/store/useEventStore";
import { useGlobalErrorStore } from "@/store/useGlobalError";
import { useUserStore } from "@/utils/UserStore";
import { useListStore } from "@/store/listStore";
import { moment } from "@/utils/useTimeZone";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export const useEvent = () => {
  // Store
  const eventStore = useEventStore();
  const globalError = useGlobalErrorStore();
  const userStore = useUserStore();
  const list = useListStore();

  const { handleEventEdit } = eventStore;
  const { listArea, activityMap } = storeToRefs(list);
  const {
    eventBookingsId,
    eventId,
    recurringEventId,
    isEventPending,
    findSoldTicket,
  } = storeToRefs(eventStore);
  const { isBookingWarningDialogVisible, errorDateList } =
    storeToRefs(globalError);
  const { currentLocation } = storeToRefs(userStore);

  // Data
  const paymentOptions = ref<any[]>([
    {
      title: "Coupon / Gift card",
      value: "couponOrGift",
    },
    {
      title: "User Credit",
      value: "userCredit",
    },
    {
      title: "POS",
      value: "pos",
    },
    {
      title: "Wave Fee",
      value: "waveFee",
    },
  ]);
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const recurringWeeks = ref([
    { id: 1, label: "M", value: "Monday" },
    { id: 2, label: "T", value: "Tuesday" },
    { id: 3, label: "W", value: "Wednesday" },
    { id: 4, label: "T", value: "Thursday" },
    { id: 5, label: "F", value: "Friday" },
    { id: 6, label: "S", value: "Saturday" },
    { id: 7, label: "S", value: "Sunday" },
  ]);
  const totalPaidAmount = ref<number>(0);

  // computed
  const recurringEndDateConfig = computed(() => (maxWeek: number) => {
    // Calculate the maximum date using Moment.js
    const maxDate = moment().add(maxWeek * 7, "days");
    return {
      dateFormat: "d-m-Y",
      disable: [
        function (date: any) {
          const today = moment().startOf("day");
          const momentDate = moment(date).startOf("day");
          // Disable past dates or dates beyond the max week range
          return momentDate.isBefore(today) || momentDate.isAfter(maxDate);
        },
      ],
    };
  });

  const getSelectedRecurringItem = computed(() => (type: string) => {
    // Map recurringWeeks by id
    return recurringWeeks.value.reduce((acc: any, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  });

  const alternativeAreaList = computed(() => (activityId: string) => {
    return listArea.value?.filter(
      (area: any) =>
        area.activities?.includes(activityId) &&
        area.location === currentLocation.value
    );
  });

  const getRecurringDates = computed(() => (config: any) => {
    const { days, endDate, frequency, interval } = config;
    const recurringItems = getSelectedRecurringItem.value(interval);
    const endMoment = moment(endDate, "DD-MM-YYYY").startOf("day");
    const today = moment().startOf("day");
    const recurringConfigDates: string[] = [];

    // Generate valid booking dates based on the selected days
    while (today.isSameOrBefore(endMoment)) {
      days.forEach((dayId: string) => {
        const dayOfWeek = recurringItems[dayId]?.value;
        if (dayOfWeek) {
          const targetDate = today.clone().day(dayOfWeek);

          // Adjust to the next week's day if the target date is in the past
          const nextValidDate = targetDate.isBefore(today)
            ? targetDate.add(1, "week")
            : targetDate;

          // Add the date to the list if it's within the end date range
          if (nextValidDate.isSameOrBefore(endMoment)) {
            recurringConfigDates.push(nextValidDate.format("YYYY-MM-DD"));
          }
        }
      });

      // Move to the next frequency period
      today
        .add(frequency, interval === "Weeks" ? "week" : "month")
        .startOf("day");
    }

    return recurringConfigDates;
  });

  // Method
  const mapSelectionToMinutes = async (selectedValue: any) => {
    // Ensure the input is a string
    selectedValue = selectedValue.toString();

    // If the input is a whole number (e.g., "1"), treat it as hours
    if (!selectedValue.includes(".")) {
      return Number(selectedValue) * 60;
    }

    // Split the time string and convert to minutes
    const [hours = "0", minutes = "0"] = selectedValue.split(".").map(Number);
    return hours * 60 + minutes;
  };

  const getDuration = (duration: any, getBookingSpacing: number) => {
    // Ensure duration and bookingSpacing are positive numbers
    if (duration < 0 || getBookingSpacing <= 0) {
      throw new Error(
        "Duration must be a positive number and bookingSpacing must be greater than 0"
      );
    }

    // Convert duration to minutes if it's not already
    const durationMinutes =
      typeof duration === "number" ? duration : Math.floor(duration / 60000);

    // Round the duration to the nearest booking spacing
    const roundedMinutes =
      Math.round(durationMinutes / getBookingSpacing) * getBookingSpacing;

    // Convert roundedMinutes to hours and minutes
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    // Return formatted duration
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  const reArrangeBookingOnActivity = (bookings: any[]) => {
    // Sort the bookings
    const sortedBookings = bookings?.sort((a: any, b: any) => {
      // Sort by activity ID first
      if (a.activity < b.activity) return -1;
      if (a.activity > b.activity) return 1;

      // If activity IDs are the same, sort by areas from the area
      const areaNumberA = a.area;
      const areaNumberB = b.area;
      if (areaNumberA !== areaNumberB) {
        return areaNumberA - areaNumberB;
      }
      // Default return value when both activity and areas are equal
      return 0;
    });

    return sortedBookings;
  };

  // Helper function to calculate docket line values
  const calculateLineValues = (
    activity: string,
    value: number,
    discount: number
  ) => {
    const activityData = activityMap.value[activity] || {};
    const bookingSpacing = activityData.bookingSpacing || 1;
    const discountAmount = (value * discount) / 100;
    const lineTotal = value - discountAmount;

    return { bookingSpacing, discountAmount, lineTotal };
  };

  const refetchEvent = async () => {
    const eventData = await pb.collection("events").getOne(eventId.value, {
      expand: "bookings.docketLine.docket",
    });
    handleEventEdit(eventData);
  };

  const updateDocketDues = async (docketId: string, bookings: any) => {
    if (docketId) {
      // Calculate total and totalDue in a single loop
      const { total, totalDue } = bookings?.reduce(
        (acc: any, booking: any) => {
          acc.total += booking.value || 0;
          acc.totalDue += booking.due || 0;
          return acc;
        },
        { total: 0, totalDue: 0 }
      );

      // Update the docket with the new values
      await pb.collection("dockets").update(docketId, {
        total: total,
        due: totalDue,
      });
    }
  };

  const confirmDelete = async () => {
    try {
      const { expand: { bookings } = {} } = await pb
        .collection("events")
        .getOne(eventId.value, { expand: "bookings" });

      if (bookings?.length) {
        const deletePromises = bookings?.map((booking: any) => {
          if (booking?.docketLineId || booking.actualStartTime) {
            // Update state to 'Deleted' for valid docketLineId
            return pb
              .collection("bookings")
              .update(booking.id, { state: "Deleted", due: 0 });
          } else {
            // Delete booking if no valid docketLineId
            return pb.collection("bookings").delete(booking.id);
          }
        });

        await Promise.all(deletePromises);

        await pb.collection("events").delete(eventId.value);
      }
    } catch (e) {}
  };

  const removeExistingPendingBooking = async () => {
    try {
      const { expand: { bookings } = {} } = await pb
        .collection("events")
        .getOne(eventId.value, { expand: "bookings" });

      if (bookings?.length && bookings[0]?.state === "Pending") {
        const deletePromises = bookings?.map((booking: any) => {
          return pb.collection("bookings").delete(booking.id);
        });
        await Promise.all(deletePromises);
        await pb.collection("events").delete(eventId.value);
      }
      return;
    } catch (e) {}
  };

  const reserveSlot = async (params: any) => {
    const { duration, date, time, activity, owner, userId } = params;

    // Convert duration to minutes
    const durationTotal = await mapSelectionToMinutes(
      String(duration)?.replace(":", ".")
    );

    // Create ISO start time from date and time
    const startTime = moment(
      `${date} ${time}`,
      "DD-MM-YYYY HH:mm" // Adjusting date format for moment
    ).toISOString();

    // Variable to store all booking IDs
    let bookingIds = [];

    // Iterate over the activities object
    for (const activityId in activity) {
      const bayIds = activity[activityId];

      // Loop through each bayId and create booking
      for (const bayId of bayIds) {
        const due =
          (durationTotal / activityMap.value[activityId]?.bookingSpacing) *
          activityMap.value[activityId]?.sessionFullPrice;

        // Example booking creation (replace with your actual API call logic)
        const booking = await pb.collection("bookings").create({
          startTime: startTime,
          duration: durationTotal,
          area: bayId,
          owner: owner,
          activity: activityId,
          value: params?.trackTickets ? 0 : due,
          discount: 0,
          due: params?.trackTickets ? 0 : due,
          state: "Pending",
          createdBy: userId,
        });
        bookingIds.push(booking.id); // Collecting booking ID
      }
    }
    eventBookingsId.value = bookingIds;

    // create a event
    const eventData = await pb.collection("events").create({
      bookings: eventBookingsId.value,
      trackTickets: params?.trackTickets,
    });

    eventId.value = eventData.id;

    isEventPending.value = true;
  };

  const updateEvent = async (params: any) => {
    try {
      const {
        name,
        description,
        activity,
        date,
        time,
        duration,
        owner,
        updatedBy,
        trackTickets,
        ticketsConfig,
        docketId,
      } = params;

      // Convert duration to minutes and create ISO start time
      const durationTotal = await mapSelectionToMinutes(
        String(duration)?.replace(":", ".")
      );
      const startTime = moment(
        `${date} ${time}`,
        "DD-MM-YYYY HH:mm"
      ).toISOString();

      // Fetch all current bookings for the event
      const eventData = await pb.collection("events").getOne(eventId.value, {
        expand: "bookings",
      });

      const existingBookings = eventData?.expand?.bookings || [];
      const existingBookingIds = existingBookings.map((b: any) => b.id);

      // Flatten the activity data to determine required bookings
      const multiAreasBookings = Object.entries(activity).flatMap(
        ([activityId, bayIds]: any) =>
          bayIds.map((bayId: string) => ({ activityId, bayId }))
      );

      const bookingsToUpdate = [];
      const bookingsToCreate = [];
      const bookingsToDelete = new Set(existingBookingIds);

      // Use discount from the first existing booking (if available)
      const discountPercentage = cloneDeep(
        existingBookings?.[0]?.discount || 0
      );

      for (const [index, requiredBooking] of multiAreasBookings.entries()) {
        // Match with existing booking
        const existingBooking = existingBookings[index] || null;

        // Get activity data
        const activityData =
          activityMap.value[
            existingBooking?.activity || requiredBooking.activityId
          ] || {};

        // Calculate values
        const bookingSpacing = activityData.bookingSpacing || 1;
        const sessionFullPrice = activityData.sessionFullPrice || 60;

        const dynamicValue = trackTickets
          ? 0
          : (durationTotal / bookingSpacing) * sessionFullPrice;

        let dynamicDue = 0;
        if (!trackTickets) {
          const discountAmount = (dynamicValue * discountPercentage) / 100;
          const discountedValue = dynamicValue - discountAmount;
          const paidAmount = existingBooking?.paid || 0;
          dynamicDue = Math.max(discountedValue - paidAmount, 0);
        }

        if (existingBooking) {
          // Update existing booking
          bookingsToUpdate.push({
            id: existingBooking.id,
            area: requiredBooking.bayId,
            activity: requiredBooking.activityId,
            startTime,
            duration: durationTotal,
            owner,
            value: dynamicValue,
            due: dynamicDue,
            docketLine: existingBooking?.docketLine,
            discount: discountPercentage,
            visitors: name,
            state: "Confirmed",
            updatedBy,
          });
          bookingsToDelete.delete(existingBooking.id); // Mark as updated
        } else {
          // Create new booking
          bookingsToCreate.push({
            startTime,
            duration: durationTotal,
            area: requiredBooking.bayId,
            owner,
            activity: requiredBooking.activityId,
            value: dynamicValue,
            discount: discountPercentage,
            due: dynamicDue,
            visitors: name,
            state: "Confirmed",
            createdBy: updatedBy,
          });
        }
      }

      // Perform updates
      for (const update of bookingsToUpdate) {
        await pb.collection("bookings").update(update.id, update);

        if (docketId && update.docketLine) {
          const { bookingSpacing, lineTotal } = calculateLineValues(
            update.activity,
            update.value,
            update.discount
          );

          await pb.collection("docketLines").update(update.docketLine, {
            qty: durationTotal / bookingSpacing,
            itemPrice: update.value,
            lineTotal,
            discount: update.discount,
          });
        }
      }

      // Perform creations
      for (const create of bookingsToCreate) {
        let docketLineId = null;

        if (docketId) {
          const { bookingSpacing, lineTotal } = calculateLineValues(
            create.activity,
            create.value,
            create.discount
          );

          const docketLine = await pb.collection("docketLines").create({
            docket: docketId,
            transactionType: "Event",
            qty: durationTotal / bookingSpacing,
            itemPrice: create.value,
            lineTotal,
            discount: create.discount,
            status: "Available",
          });

          docketLineId = docketLine.id;
        }

        const booking = await pb.collection("bookings").create({
          ...create,
          docketLine: docketLineId,
        });
        existingBookingIds.push(booking.id);

        if (docketLineId) {
          await pb.collection("docketLines").update(docketLineId, {
            booking: booking.id,
          });
        }
      }

      // Perform deletions
      for (const idToDelete of bookingsToDelete) {
        // Fetch the booking to check if it has a docketLine
        const bookingToDelete = existingBookings.find(
          (b: any) => b.id === idToDelete
        );

        if (bookingToDelete?.docketLine) {
          // Delete the associated docketLine
          await pb.collection("docketLines").delete(bookingToDelete.docketLine);

          if (bookingToDelete?.paid) {
            // Delete the booking
            await pb.collection("bookings").update(idToDelete as string, {
              status: "Deleted",
            });
          }
        } else {
          // Delete the booking
          await pb.collection("bookings").delete(idToDelete as string);
        }

        // Remove the booking ID from the existingBookingIds array
        const index = existingBookingIds.indexOf(idToDelete);
        if (index !== -1) {
          existingBookingIds.splice(index, 1);
        }
      }

      isEventPending.value = false;

      // Update event record
      const updatedEventData = await pb.collection("events").update(
        eventId.value,
        {
          bookings: existingBookingIds,
          name,
          description,
          updatedBy,
          trackTickets,
        },
        {
          expand: "bookings.docketLine.docket",
        }
      );

      // Update docket dues
      const bookings = updatedEventData.expand?.bookings || [];

      await updateDocketDues(docketId, bookings);

      // Update products
      if (ticketsConfig?.length) {
        for (const item of ticketsConfig) {
          const soldTickets = findSoldTicket.value(item?.product);
          const inStock = Math.max(item?.ticketLimit - soldTickets, 0);

          await pb.collection("products").update(item?.product, {
            eventTicketLimit: item?.ticketLimit,
            event: eventId.value,
            eventBarValue: item?.eventBarValue,
            eventDate: startTime,
            inStock,
          });
        }
      }

      handleEventEdit(updatedEventData);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRecurringEventUpdate = async (
    booking: any,
    recurringConfigDates: string[],
    eventList?: any,
    recurringId?: string,
    endDate?: string
  ) => {
    const errorDates: string[] = [];
    const bookingCombinations = Object.entries(booking.activity)?.flatMap(
      ([activityId, areaIds]) =>
        (areaIds as string[])?.map((areaId) => ({ areaId, activityId }))
    );

    const durationTotal = await mapSelectionToMinutes(
      String(booking?.duration)?.replace(":", ".")
    );

    // Closure function
    const createOrUpdateBooking = async (
      areaId: string,
      activityId: string,
      bookingDate: string,
      bookingParam: any
    ): Promise<string> => {
      let bookingId = "";

      const discountPercentage = bookingParam?.discount || 0;
      const paidAmount = bookingParam?.paid || 0;

      // Extract activity details
      const activityDetails = activityMap.value[activityId] || {};
      const bookingSpacing = activityDetails?.bookingSpacing || 1;
      const sessionFullPrice = activityDetails?.sessionFullPrice || 0;

      // Calculate the total value
      const dynamicValue = (durationTotal / bookingSpacing) * sessionFullPrice;

      // Calculate the discount amount and final due
      const discountAmount = (dynamicValue * discountPercentage) / 100;
      const discountedValue = dynamicValue - discountAmount;
      const dynamicDue = Math.max(discountedValue - paidAmount, 0);

      const bookingPayload = {
        startTime: moment(
          `${bookingDate} ${booking.time}`,
          "DD-MM-YYYY HH:mm"
        ).toISOString(),
        duration: durationTotal,
        area: areaId,
        activity: activityId,
        discount: discountPercentage,
        due: dynamicDue,
        value: dynamicValue,
        state: "Confirmed",
        owner: booking.owner,
        createdBy: booking?.loggedInUser,
      };

      if (bookingParam.id) {
        // Update existing booking
        const updatedBooking = await pb
          .collection("bookings")
          .update(bookingParam.id, bookingPayload);

        bookingId = updatedBooking?.id;
      } else {
        // Create new booking
        const newBooking = await pb
          .collection("bookings")
          .create(bookingPayload);

        bookingId = newBooking?.id;
      }
      return bookingId;
    };

    // Closure function
    const handleBookingCreation = async (
      areaId: string,
      activityId: string,
      bookingDate: string,
      singleBooking: any = {}
    ): Promise<string | null> => {
      const alternativeAreaList = listArea.value?.filter(
        (area: any) => area.activity === activityId && area.id !== areaId
      );

      // Try the existing areaId first
      try {
        const bookingId = await createOrUpdateBooking(
          areaId,
          activityId,
          bookingDate,
          singleBooking
        );
        return bookingId || null;
      } catch (error) {
        console.warn(`Failed to book with areaId: ${areaId}`, error);
      }

      // Try alternative areas if the existing areaId fails
      for (let i = alternativeAreaList?.length - 1; i >= 0; i--) {
        const alterAreaId = alternativeAreaList[i]?.id;
        try {
          const bookingId = await createOrUpdateBooking(
            alterAreaId,
            activityId,
            bookingDate,
            singleBooking
          );
          if (bookingId) return bookingId;
        } catch (error) {
          console.warn(
            `Failed to book with alterAreaId: ${alterAreaId}`,
            error
          );
        }
      }

      return null; // Return null if no booking was created
    };

    // Start of handleRecurringEventUpdate
    let { events } = eventList?.expand;
    const eventIds: string[] = [];

    // Loop for each date
    for (const bookingDate of recurringConfigDates) {
      // recurringConfigDates holds list of dates upon the recurring config
      const existedEvent = events?.shift();
      const { bookings } = existedEvent?.expand || {};
      const sortedBookings = reArrangeBookingOnActivity(bookings);
      const bookingIds: string[] = [];

      if (existedEvent) {
        // Update existing event's bookings
        for (const [
          index,
          { areaId, activityId },
        ] of bookingCombinations.entries()) {
          const discountOfAnyExistingBooking = sortedBookings?.find(
            (booking: any) => booking?.discount || {}
          );
          const singleBooking = sortedBookings[index] || {};
          singleBooking.discount = discountOfAnyExistingBooking?.discount || 0;
          // singleBooking.discount =
          const bookingId = await handleBookingCreation(
            areaId,
            activityId,
            bookingDate,
            singleBooking
          );
          if (bookingId) bookingIds.push(bookingId);
        }

        // Remove unused bookings for release areas
        const unusedBookings = sortedBookings?.filter(
          (booking) => !bookingIds?.includes(booking.id)
        );

        for (const unusedBooking of unusedBookings) {
          await pb.collection("bookings").delete(unusedBooking.id);
        }

        // Update event with new booking IDs
        await pb.collection("events").update(existedEvent.id, {
          bookings: bookingIds,
          name: booking?.name,
          description: booking?.description,
        });
        eventIds.push(existedEvent.id);
      } else {
        // Create new event with bookings
        for (const { areaId, activityId } of bookingCombinations) {
          const bookingId = await handleBookingCreation(
            areaId,
            activityId,
            bookingDate,
            {}
          );
          if (bookingId) bookingIds.push(bookingId);
        }

        const eventData = await pb.collection("events").create({
          name: booking?.name,
          description: booking?.description,
          bookings: bookingIds,
        });

        eventIds.push(eventData.id);
      }
    }

    // // Remove any remaining events that are no longer needed
    for (const remainingEvent of events) {
      await pb.collection("events").delete(remainingEvent.id);
    }

    const updatedRecurringEvent = await pb.collection("recurringEvents").update(
      recurringId as string,
      {
        events: eventIds,
        endDate,
        days: booking?.daysString,
        frequency: booking?.recurring?.frequency,
        interval: booking?.recurring?.interval,
      },
      {
        expand: "events.bookings.docketLine.docket",
      }
    );

    // Update docket dues
    const bookings =
      updatedRecurringEvent?.expand?.events?.[0]?.expand?.bookings || [];

    const docketId = booking?.docketId;

    await updateDocketDues(docketId, bookings);

    if (
      updatedRecurringEvent &&
      updatedRecurringEvent?.expand?.events?.length
    ) {
      const { id, events, frequency, interval, days, endDate } =
        updatedRecurringEvent;
      const editEventData = {
        ...updatedRecurringEvent?.expand?.events?.[0],
        recurring: {
          recurringId: id,
          events,
          frequency,
          interval,
          days,
          endDate,
        },
      };
      handleEventEdit(editEventData);
    }

    if (errorDates.length) {
      console.error("Errors occurred on the following dates:", errorDates);
    }
  };

  const handleRecurringEvent = async (booking: any) => {
    const errorDates: string[] = []; // Initialize error tracking list
    let allBookingsSuccessful = true; // Flag to track overall booking success

    const startTime = moment(
      `${booking?.date} ${booking?.time}`,
      "DD-MM-YYYY HH:mm"
    ).toISOString();

    try {
      // Function to create bookings for all selected areas and dates
      const handleRecurringBookingCreation = async (
        booking: any,
        recurringConfigDates: string[]
      ) => {
        // Flatten the areas and activities into a single list of booking combinations
        const bookingCombinations = Object.entries(
          booking.activity as Record<string, string[]>
        )?.flatMap(([activityId, areaIds]) =>
          areaIds?.map((areaId) => ({ areaId, activityId }))
        );

        const durationTotal = await mapSelectionToMinutes(
          String(booking?.duration)?.replace(":", ".")
        );

        let eventIds: string[] = [];

        // Create individual bookings for each combination of date, area, and activity
        for (const bookingDate of recurringConfigDates) {
          let bookingIds: string[] = [];

          // Create bookings for each area-activity combination on this date
          for (const { areaId, activityId } of bookingCombinations) {
            // Calculate the due and value in one go to avoid redundancy
            const bookingSpacing =
              activityMap.value[activityId]?.bookingSpacing;
            const sessionFullPrice =
              activityMap.value[activityId]?.sessionFullPrice;

            const calculatedValue = booking?.trackTickets
              ? 0
              : (durationTotal / bookingSpacing) * sessionFullPrice;

            // Make sure areaId comes first and activityId comes second
            const bookingParams = {
              startTime: moment(
                `${bookingDate} ${booking.time}`,
                "DD-MM-YYYY HH:mm"
              ).toISOString(),
              duration: durationTotal,
              area: areaId,
              activity: activityId,
              due: calculatedValue, // Reusing the calculated value
              value: calculatedValue, // Reusing the calculated value
              state: "Confirmed",
              owner: booking.owner,
              createdBy: booking?.loggedInUser,
            };

            try {
              // Create the booking
              const createdBooking = await pb
                .collection("bookings")
                .create(bookingParams);
              bookingIds.push(createdBooking.id);
            } catch (e) {
              console.error(
                `Error creating booking for ${bookingDate} in area ${areaId} and activity ${activityId}:`,
                e
              );
              errorDates.push(bookingDate); // Track the date where error occurred
              allBookingsSuccessful = false;
            }
          }

          // Only create event if bookings were successful
          if (allBookingsSuccessful) {
            try {
              const eventData = await pb.collection("events").create({
                name: booking?.name,
                bookings: bookingIds,
                description: booking?.description,
                trackTickets: booking?.trackTickets,
              });
              eventIds.push(eventData.id);
            } catch (e) {
              console.error(`Error creating event for ${bookingDate}:`, e);
              errorDates.push(bookingDate); // Track the date where error occurred
              allBookingsSuccessful = false;
            }
          }
        }

        // Only create recurring event if all bookings and events were successful
        if (allBookingsSuccessful) {
          try {
            const newRecurringEvent = await pb
              .collection("recurringEvents")
              .create(
                {
                  events: [...eventIds],
                  days: booking?.daysString,
                  endDate: booking?.recurring?.endDate,
                  frequency: booking?.recurring?.frequency,
                  interval: booking?.recurring?.interval,
                },
                {
                  expand: "events.bookings.docketLine.docket",
                }
              );

            // update earlier event id into store
            eventId.value = eventIds[0];

            // Update recurring eventId to store
            recurringEventId.value = newRecurringEvent.id;

            if (
              newRecurringEvent &&
              newRecurringEvent?.expand?.events?.length
            ) {
              const { id, events, frequency, interval, days, endDate } =
                newRecurringEvent;
              const editEventData = {
                ...newRecurringEvent?.expand?.events[0],
                recurring: {
                  recurringId: id,
                  events,
                  frequency,
                  interval,
                  days,
                  endDate,
                },
              };
              handleEventEdit(editEventData);
            }

            // update products
            if (booking?.ticketsConfig?.length) {
              for (const item of booking.ticketsConfig) {
                const inStock = Math.max(
                  item.ticketLimit - findSoldTicket.value(item.product),
                  0
                );
                await pb.collection("products").update(item.product, {
                  eventTicketLimit: item.ticketLimit,
                  event: eventId.value,
                  eventBarValue: item.eventBarValue,
                  eventDate: startTime,
                  inStock,
                });
              }
            }
          } catch (e) {
            console.error("Error saving recurring event:", e);
            allBookingsSuccessful = false;
          }
        }
      };

      // Check if the booking is a recurring booking
      if (!isEmpty(booking?.recurring)) {
        const { id, days, endDate, frequency, interval, isEditAll } =
          booking.recurring;

        const recurringItems = getSelectedRecurringItem.value(interval);
        const daysString = days
          .map((dayId: number) => recurringItems[dayId]?.value)
          .filter(Boolean)
          .join(", ");

        // Setup end date and initialize current date
        const endMoment = moment(endDate).startOf("day");
        let currentDate = moment().startOf("day");
        const recurringConfigDates: any[] = [];

        // Generate valid booking dates based on the selected days
        while (currentDate.isSameOrBefore(endMoment)) {
          days.forEach((dayId: string) => {
            const dayOfWeek = recurringItems[dayId]?.value;
            if (dayOfWeek) {
              const targetDate = currentDate.clone().day(dayOfWeek);

              // Adjust to the next week's day if the target date is in the past
              const nextValidDate = targetDate.isBefore(currentDate)
                ? targetDate.add(1, "week")
                : targetDate;

              // Add the date to the list if it's within the end date range
              if (nextValidDate.isSameOrBefore(endMoment)) {
                recurringConfigDates.push(nextValidDate.format("DD-MM-YYYY"));
              }
            }
          });
          currentDate
            .add(frequency, interval === "Weeks" ? "week" : "month")
            .startOf("day");
        }

        // If this is an existing recurring booking
        if (id) {
          try {
            const recurringEventsRecords = await pb
              .collection("recurringEvents")
              .getOne(id, { expand: "events.bookings" });

            if (recurringEventsRecords?.expand?.events?.length) {
              if (isEditAll) {
                // Handle updating all records (if editing all)
                await handleRecurringEventUpdate(
                  { ...booking, daysString },
                  recurringConfigDates,
                  recurringEventsRecords,
                  id,
                  endDate
                );
              } else {
                // Handle updating a single record
                await updateEvent(booking);
                await refetchEvent();
              }
            }
          } catch (e) {
            console.error("Error fetching recurring bookings for update:", e);
            allBookingsSuccessful = false;
          }
        } else {
          // If no recurring booking exists, create a new one
          await handleRecurringBookingCreation(
            { ...booking, daysString },
            recurringConfigDates
          );
        }
      }
    } catch (e) {
      console.error("Error handling recurring booking:", e);
      allBookingsSuccessful = false;
    }

    // Handle error notifications or logging if errors occurred
    if (errorDates.length) {
      isBookingWarningDialogVisible.value = true;
      errorDateList.value = Array.from(new Set(errorDates)); // Convert Set back to array
    }

    if (!allBookingsSuccessful) {
      console.log(
        "Some bookings failed, not creating events or recurring events."
      );
    }

    console.log("Error dates:", errorDates);
  };

  /**
   * Fetches the list of ticket products from the database that can be bought in the webshop.
   *
   * @returns {Promise<any[]>} - A promise that resolves to an array of ticket products.
   * If the request fails or if the response does not contain the expected data, the promise will resolve to `undefined`.
   */
  const getTicketProducts = async (eventId: string) => {
    try {
      const resultList = await pb.collection("products").getList(1, 50, {
        filter: `canBuyInWebshop = true`,
      });

      let totalTicketCount = resultList.items
        .filter((el) => el.event === eventId)
        .reduce((e, o) => e + o.eventTicketLimit, 0);

      return {
        products: resultList.items,
        ticketConfigs: resultList.items?.length
          ? resultList.items
              .filter((el) => el.event === eventId)
              .map((el) => ({
                product: el.id,
                ticketLimit: el.eventTicketLimit,
                eventBarValue: el?.eventBarValue,
              }))
          : [
              {
                product: "",
                ticketLimit: 1,
                eventBarValue: 0,
              },
            ],

        totalTicket: totalTicketCount,
      };
    } catch (error) {
      console.error("Error fetching ticket products:", error);
      return {
        products: [],
        ticketConfigs: [
          {
            product: "",
            ticketLimit: 1,
            eventBarValue: 0,
          },
        ],
      };
    }
  };

  return {
    paymentOptions,
    daysOfWeek,
    recurringWeeks,
    alternativeAreaList,
    recurringEndDateConfig,
    activityMap,
    getRecurringDates,
    getDuration,
    reserveSlot,
    updateEvent,
    confirmDelete,
    removeExistingPendingBooking,
    refetchEvent,
    handleRecurringEvent,
    getTicketProducts,
  };
};
