import { useCheckoutStore } from "@/utils/Docket";
import { useDocketStore } from "@/utils/DocketStore";
import { Round2Num, Round2String } from "@/utils/Formatting";
import { pb } from "@/utils/PocketBaseAdapter";
import { moment } from "@/utils/useTimeZone";
import { getBarCodeReader } from "@/utils/common";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import isEmpty from "lodash/isEmpty";

// interface
interface DiscountCode {
  id: string;
  simpleCode: string;
  expiry: string; // ISO date string
  discountBookings: number;
  discountEvents: number;
  discountProduct: number;
  maxDiscountValue: number;
  inactive: boolean;
  singleUse: boolean;
}

export const useDiscountCode = defineStore("DiscountCode", () => {
  const route = useRoute();

  // store
  const checkoutStore = useCheckoutStore();
  const docketStore = useDocketStore();

  const { checkDocketDues } = checkoutStore;

  const { calculateRemainingPayAmount } = docketStore;

  const { loading } = storeToRefs(checkoutStore);
  const { docketDiscountData } = storeToRefs(docketStore);

  // Data
  const discountCodeList = ref<any[]>([]);
  const totalDiscountCode = ref<number>(0);
  const discountCode = ref<string>("");
  const discountCodeData = ref<DiscountCode | any>({
    id: "",
    simpleCode: "",
    expiry: "",
    discountBookings: 0,
    discountEvents: 0,
    discountProduct: 0,
    maxDiscountValue: 0,
    inactive: false,
    singleUse: false,
  });

  // Methods
  const getDiscountCodeList = async (params: any) => {
    let filters = "";

    if (params?.inactive) {
      filters += filters
        ? ` && inactive = ${params.inactive}`
        : `inactive = ${params.inactive}`;
    }

    if (params?.singleUse) {
      filters += filters
        ? ` && singleUse = ${params.singleUse}`
        : `singleUse = ${params.singleUse}`;
    }

    if (params.q) {
      const query = `simpleCode ~ "${params.q}" || id ~ "${params.q}"`;

      filters += filters ? ` && ${query}` : query;
    }

    let sortParam = "-created";
    if (params?.sortBy.length) {
      sortParam =
        params?.sortBy[0].order === "desc"
          ? `-${params?.sortBy[0].key}`
          : params?.sortBy[0].key;
    }

    // fetch a paginated records list
    const response = await pb
      .collection("discountCodes")
      .getList(params.page, params.itemsPerPage, {
        filter: filters,
        sort: sortParam ? sortParam : "",
      });

    discountCodeList.value = response?.items || [];
    totalDiscountCode.value = response?.totalItems || 0;
  };

  /**
   * Validates the applied discount code by fetching it from the database and checking its validity.
   *
   * @remarks
   * This function fetches the discount code from the database based on the provided `discountCode.value`.
   * It checks if the code is active, not expired, and not a single-use code.
   * If the code is valid, it updates the `discountCodeData` with the fetched code data.
   * If the code is not valid or an error occurs, it resets `discountCodeData` to an empty object.
   *
   * @returns {Promise<void>} - A promise that resolves when the validation is complete.
   */
  const validateAppliedDiscountCode = async () => {
    try {
      const currentDate = moment()
        .utc()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      const response = await pb.collection("discountCodes").getFullList({
        filter: `(id = "${discountCode.value}" || simpleCode = "${discountCode.value}") && inactive = false && expiry > "${currentDate}"`,
      });

      if (response?.length) {
        discountCodeData.value = response[0];
        return;
      } else {
        discountCodeData.value = {};
        loading.value = false;
        toast.error("Invalid discount code", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (e) {
      loading.value = false;
    }
  };

  // Helper Functions
  const fetchDocketLines = async (docketId: string) => {
    return await pb.collection("docketLines").getFullList({
      filter: `docket = '${docketId}'`,
      expand: "product",
    });
  };

  const calculateExistingDiscount = (docketLines: any[]) => {
    let totalItemPrice = 0;
    let lineTotal = 0;
    for (let i = 0; i < docketLines.length; i++) {
      const line = docketLines[i];

      if (
        ["Event", "Booking Payment", "Product"].includes(line.transactionType)
      ) {
        totalItemPrice = line.itemPrice * line.qty;
        lineTotal = line.lineTotal;
      }
    }
    return Round2Num(totalItemPrice - lineTotal, 2);
  };

  const showHigherDiscountWarning = () => {
    toast.info(
      "A higher discount has already been applied. The current discount code will not replace the existing discount.",
      { position: toast.POSITION.BOTTOM_RIGHT }
    );
  };

  const showDiscountAppliedSuccess = () => {
    toast.success(
      "The current discount code provides a higher discount and has been applied successfully.",
      { position: toast.POSITION.BOTTOM_RIGHT }
    );
  };

  /**
   * Applies discounts to the docket lines based on the discount code data.
   *
   * @remarks
   * This function iterates through the provided `docketLines` and applies discounts based on the transaction type.
   * It determines the applicable discount percentage for each line based on the transaction type and the discount code data.
   * The function calculates the potential discount amount for each line and checks if adding this discount would exceed the `maxDiscountValue`.
   * If the total discount applied does not exceed the `maxDiscountValue`, the function applies the full discount to the line.
   * If the total discount applied exceeds the `maxDiscountValue`, the function applies a partial discount to the line.
   * Once the discounts are applied, the function calculates and sets the line's total based on the applied discount.
   *
   * @param docketLines - An array of docket line objects to apply discounts to.
   * @param source - An optional parameter representing the source of the discount code application.
   *
   * @returns An array of docket line objects with the applied discounts.
   */
  const applyDiscountsToDocketLines = (
    docketLines: any[],
    source: string = ""
  ) => {
    const {
      discountBookings,
      discountEvents,
      discountProduct,
      maxDiscountValue,
    } = discountCodeData.value;

    let totalDiscountApplied = 0;

    return docketLines.map((line) => {
      const updatedLine = { ...line };

      // Determine discount percentage based on transaction type
      let applicableDiscount = 0;
      if (line.transactionType === "Product") {
        const maxDiscountOfProduct =
          line.expand?.product?.maxDiscount || discountProduct;
        applicableDiscount = Math.min(discountProduct, maxDiscountOfProduct);
      } else if (line.transactionType === "Event") {
        applicableDiscount = discountEvents;
      } else if (line.transactionType === "Booking Payment") {
        applicableDiscount = discountBookings;
      }

      // Calculate potential discount amount for this line
      const potentialDiscountAmount = Round2Num(
        line.itemPrice * (line.qty * (applicableDiscount / 100)),
        2
      );

      // Check if adding this discount would exceed maxDiscountValue
      if (totalDiscountApplied + potentialDiscountAmount <= maxDiscountValue) {
        // Apply the full discount to this line
        updatedLine.discount = Round2Num(applicableDiscount, 2);
        totalDiscountApplied += potentialDiscountAmount;
      } else if (totalDiscountApplied < maxDiscountValue) {
        // Apply partial discount so total doesn't exceed maxDiscountValue
        const remainingDiscountAmount = maxDiscountValue - totalDiscountApplied;
        const partialDiscount =
          (remainingDiscountAmount / (line.itemPrice * line.qty)) * 100;
        updatedLine.discount = Round2Num(partialDiscount, 2);
        totalDiscountApplied += remainingDiscountAmount;
      } else {
        // Set discount to 0% once maxDiscountValue is reached
        updatedLine.discount = 0;
      }

      // Calculate and set the line's total based on the applied discount
      const discountAmount = Round2Num(
        line.itemPrice * (updatedLine.discount / 100),
        2
      );
      updatedLine.lineTotal = Round2String(
        line.qty * (line.itemPrice - discountAmount),
        2
      );

      return updatedLine;
    });
  };

  const calculateDiscountedAmount = (docketLines: any[]) => {
    let totalItemPrice = 0;
    let lineTotal = 0;

    for (let i = 0; i < docketLines.length; i++) {
      const item = docketLines[i];
      totalItemPrice += item?.itemPrice * item?.qty;
      lineTotal += Round2Num(item?.lineTotal, 2);
    }

    return Round2Num(totalItemPrice - lineTotal, 2);
  };

  /**
   * Fetches and returns a list of docket payments based on the provided docket ID.
   *
   * @remarks
   * This function retrieves a list of docket payments from the database using the provided `docketId`.
   * It filters the payments based on the docket ID and the status being 'Approved'.
   *
   * @param docketId - The unique identifier of the docket for which to fetch the payments.
   *
   * @returns A promise that resolves to an array of docket payment objects.
   *
   * @throws Throws an error if the fetch operation fails.
   */
  const fetchDocketPayments = async (docketId: string) => {
    return await pb.collection("docketPayments").getFullList({
      filter: `docket = '${docketId}' && status = 'Approved'`,
    });
  };

  const calculateTotalPaidAmount = (docketPayments: any[]) => {
    return docketPayments.reduce((total, payment) => total + payment.amount, 0);
  };

  /**
   * Calculates and updates the docket's due amount based on the provided discounted amount and total paid amount.
   *
   * @param docketId - The unique identifier of the docket.
   * @param discountedAmount - The total amount discounted for the docket.
   * @param totalPaidAmount - The total amount paid for the docket.
   *
   * @returns The updated due amount for the docket.
   *
   * @remarks
   * This function fetches the docket data using the provided `docketId`.
   * It calculates the new due amount by subtracting the `discountedAmount` and `totalPaidAmount` from the docket's total.
   * The function then updates the docket with the new discount details and due amount.
   * Finally, it returns the updated due amount.
   */
  const calculateAndUpdateDocket = async (
    docketId: string,
    discountedAmount: number,
    totalPaidAmount: number
  ) => {
    const docketData = await pb.collection("dockets").getOne(docketId);
    const due = Round2Num(
      docketData.total - totalPaidAmount - discountedAmount,
      2
    );

    const { discountSources = [] } = docketData;
    let sourceOfDiscount = [];

    if (discountSources.includes("POS")) {
      sourceOfDiscount = ["POS", "DiscountCode"];
    } else {
      sourceOfDiscount = ["DiscountCode"];
    }

    await pb.collection("dockets").update(docketId, {
      discountProducts: discountCodeData.value.discountProduct,
      discountBookings: discountCodeData.value.discountBookings,
      discountEvents: discountCodeData.value.discountEvents,
      due: due,
      discountSources: sourceOfDiscount,
      discountCode: discountCodeData.value?.id,
    });

    docketStore.docketDiscountData = {
      bookingDiscount: discountCodeData.value?.discountBookings,
      productDiscount: discountCodeData.value?.discountProduct,
      eventDiscount: discountCodeData.value?.discountEvents,
    };

    return due;
  };

  const updateDocketLines = async (docketLines: any[]) => {
    for (const line of docketLines) {
      const docketLineResponse = await pb.collection("docketLines").update(
        line.id,
        {
          lineTotal: line.lineTotal,
          discount: line.discount,
        },
        {
          expand: "booking",
        }
      );

      if (docketLineResponse && docketLineResponse?.booking) {
        await pb.collection("bookings").update(docketLineResponse?.booking, {
          discount: line.discount,
          due: line.lineTotal,
        });
      }
    }
  };

  /**
   * Handles the application of a discount code to a docket.
   *
   * This function performs the following tasks:
   * 1. Validates the applied discount code.
   * 2. Fetches and processes docket lines.
   * 3. Applies discounts to the docket lines based on the discount code data.
   * 4. Calculates the new discounted amount and updates the docket's due amount.
   * 5. Updates the docket lines with the new discount and totals.
   * 6. Displays appropriate success or warning messages.
   *
   * @returns {Promise<void>} - A promise that resolves when the discount code application is complete.
   */
  const handleDiscountCodeApply = async (
    source: string = "",
    docketid: string = "",
    externalDetails = {}
  ) => {
    try {
      loading.value = true;

      await validateAppliedDiscountCode();

      const docketId = (route?.query?.did as string) || docketid;

      if (!isEmpty(discountCodeData.value) && docketId) {
        // Fetch and process docket lines
        const docketLines = await fetchDocketLines(docketId);
        const discountedAmount = calculateExistingDiscount(docketLines);

        console.log(discountedAmount);

        const updatedDocketLines = applyDiscountsToDocketLines(docketLines);

        // Calculate new discounted amount
        const newDiscountedAmount =
          calculateDiscountedAmount(updatedDocketLines);

        if (
          discountedAmount > discountCodeData.value.maxDiscountValue ||
          discountedAmount > newDiscountedAmount
        ) {
          loading.value = false;
          discountCodeData.value = {};
          showHigherDiscountWarning();
          return;
        }

        // Calculate due amount and update docket
        const docketPayments = await fetchDocketPayments(docketId);
        const totalPaidAmount = calculateTotalPaidAmount(docketPayments);

        const possibleDiscountedAmount = ["POS", "BOOKING", "EVENT"]?.includes(
          source
        )
          ? Math.min(
              newDiscountedAmount,
              discountCodeData.value?.maxDiscountValue
            )
          : newDiscountedAmount;

        await calculateAndUpdateDocket(
          docketId,
          possibleDiscountedAmount,
          totalPaidAmount
        );

        // Update docket lines with new discount and totals
        await updateDocketLines(updatedDocketLines);

        showDiscountAppliedSuccess();

        loading.value = false;
      }
    } catch (e) {
      console.error("Error applying discount code:", e);
      loading.value = false;
    } finally {
      await docketStore.calculateRemainingPayAmount(route?.query?.did);

      if (source === "POS") {
        await checkDocketDues();
      }
    }
  };

  const subscribeUnSubscribeBarCodeReaders = (type: string) => {
    try {
      if (type === "subscribe") {
        const barCodeReader = getBarCodeReader();
        if (!barCodeReader) return;

        pb.collection("barcodeReaders").subscribe("*", async (e) => {
          const { record } = e || {};
          if (
            record.id === barCodeReader &&
            record?.lastType === "Discount Code"
          ) {
            const { lastRead } = record;
            discountCode.value = lastRead;
            await handleDiscountCodeApply("POS");
          }
        });
      } else {
        pb.collection("barcodeReaders").unsubscribe("*");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    discountCodeList,
    totalDiscountCode,
    discountCode,
    discountCodeData,
    handleDiscountCodeApply,
    getDiscountCodeList,
    showHigherDiscountWarning,
    showDiscountAppliedSuccess,
    calculateExistingDiscount,
    fetchDocketLines,
    calculateDiscountedAmount,
    fetchDocketPayments,
    calculateTotalPaidAmount,
    calculateAndUpdateDocket,
    updateDocketLines,
    subscribeUnSubscribeBarCodeReaders,
  };
});
