<script setup lang="ts">
import { pb } from "@/utils/PocketBaseAdapter";
import { usePaymentStore } from "@/components/payment/usePaymentStore";
import {
  customRegexValidator,
  decimalRule,
  minMaxValidator,
  minValidator,
  requiredValidator,
} from "@validators";
import { moment } from "@/utils/useTimeZone";
import { useDiscountCode } from "@/store/useDiscountCode";
import isEmpty from "lodash/isEmpty";
import { toast } from "vue3-toastify";
import type { Props, Emit, Tab, Slot } from "./types";

// props
const props = defineProps<Props>();

// emit
const emit = defineEmits<Emit>();

// store
const usePayment = usePaymentStore();
const discount = useDiscountCode();

const {
  getCouponList,
  payWithGiftCardOrCoupon,
  initiateDocketForBooking,
  handleUserCreditAndWaveFeePayment,
  payWithAutoApplyCoupons,
  createEditDocketForCustomizePurchase,
  payByUserCredit,
  payByGiftCard,
  payByPos,
  eventDuePayByPos,
  eventDuePayByWaveFee,
  initiateDocketForEvent,
} = usePayment;
const { handleDiscountCodeApply } = discount;

const { couponList, docketId, docketLineId, lowBalanceError } =
  storeToRefs(usePayment);

// Form Refs
const refVFormCoupon = ref<any>("");
const refVFormGiftCard = ref<any>("");
const refVFormWave = ref<any>();

// Data
const selectedUser = ref<any>();
const quantity = ref<any>("");
const selectedCoupon = ref<any>();
const giftCardId = ref<string>("");
const appliedGiftCardDetails = ref<any>("");
const isInvalidCoupon = ref<boolean>(false);
const amountToRedeem = ref<number>();
const feeWaveReason = ref<string>("");
const isTooltipVisible = ref<boolean>(false);

// computed
const getCoupons = computed(() => {
  return (
    couponList.value?.filter((coupon: any) => coupon.user === props?.owner) ||
    []
  );
});

const tabs = computed<Tab[]>(() => {
  const tabsList = [
    {
      value: "coupon",
      label: "Coupon",
      icon: "mdi-ticket-percent-outline",
      visible:
        getCoupons.value?.length > 0 &&
        props.type !== "coupon" &&
        props.type !== "transferCredit" &&
        props.type !== "giftCard" &&
        props?.type !== "addCredit" &&
        props?.type !== "event",
    },
    {
      value: "userCredit",
      label: "User Credit",
      icon: "mdi-account-credit-card-outline",
      visible: props.type !== "transferCredit" && props?.type !== "event",
    },
    {
      value: "pos",
      label: "Pos",
      icon: "mdi-network-pos",
      visible: props.haveCreatePermission,
    },
    {
      value: "giftCard",
      label: "Gift Card",
      icon: "mdi-wallet-giftcard",
      visible: props.type !== "giftCard" && props?.type !== "event",
    },
    {
      value: "waveFee",
      label: "Wave Fee",
      icon: "mdi-gift-open-outline",
      visible:
        props.haveCreatePermission &&
        ["event", "Booking"].includes(props?.type),
    },
  ];

  return tabsList.filter((tab) => tab.visible);
});

const currentTab = ref<string>(tabs.value[0]?.value || "");

// method
const updateErrorMessage = (
  isVisible: boolean,
  message: string,
  color: any = null,
  icon: any = null
) => {
  emit("updateMessage", {
    isVisible,
    message,
    color: color ? color : "error",
    icon: icon ? icon : "tabler-xbox-x",
  });
};

/**
 * Validates the gift card entered by the user.
 *
 * @returns {Promise<void>}
 */
const validateGiftCard = async () => {
  try {
    // If no gift card ID is provided, return early
    if (!giftCardId.value) {
      return;
    }

    // Fetch the gift card details from the database
    const response = await pb.collection("giftCards").getOne(giftCardId.value);

    if (response) {
      // If the gift card is void, display an error message
      if (response?.void) {
        updateErrorMessage(true, "Gift Card is void");
        return;
      }

      // Store the gift card details
      appliedGiftCardDetails.value = response;

      // If the gift card balance is 0, display an error message
      if (response?.balanceRemaining <= 0) {
        isInvalidCoupon.value = true;
        updateErrorMessage(true, "Gift Card has 0 Balance");
      } else {
        // If the gift card balance is valid, update the amount to redeem
        isInvalidCoupon.value = false;
        // Calculate the minimum value between balanceRemaining and booking due amount
        amountToRedeem.value = Math.min(
          response.balanceRemaining,
          props?.totalDue || 0
        );
        updateErrorMessage(false, "");
      }
    }
  } catch (e) {
    // If the gift card is not found, display an error message
    isInvalidCoupon.value = true;
    updateErrorMessage(true, "Gift Card Not Found");
  }
};

const handleInitiateFlow = async (type: string) => {
  try {
    updateErrorMessage(
      true,
      "Payment Inprogress...",
      "warning",
      "mdi-account-credit-card"
    );

    // Validate form based on type
    if (["Coupon", "GiftCard", "WaveFee"].includes(type)) {
      const form = {
        Coupon: refVFormCoupon,
        GiftCard: refVFormGiftCard,
        WaveFee: refVFormWave,
      }[type]?.value;

      const { valid } = (await form?.validate?.()) || { valid: true };

      if (!valid) return;
    }

    if (props?.type === "Booking") {
      const bookingDetails = {
        location: props?.location,
        discountProduct: props?.discountProduct,
        discountBookings: props?.discountBookings,
        discountEvents: props?.discountEvents,
        discountSources: props?.discountSources,
        due: props?.totalDue,
        loginUser: props?.loggedInUserId,
        amount: props?.bookingAmount,
        owner: props?.owner,
        paid: props?.totalPaid,
        ...(type === "WaveFee" && { description: feeWaveReason.value }),
        qty: props?.bookingSlot?.slotQty,
        itemPrice: props?.bookingSlot?.sessionPrice,
        payMethods: props?.payMethods,
      };

      await initiateDocketForBooking(bookingDetails, props?.bookingId);

      try {
        // Handle different payment types
        switch (type) {
          case "Coupon":
          case "GiftCard":
            await handleApplyProcess(type);
            break;
          case "autoApplyCoupon":
            refVFormCoupon.value?.resetValidation();
            await handleAutoApplyCoupons();
            break;
          case "pos":
            emit("goToPosPayment", docketId.value);
            break;
          case "UserCredit":
          case "WaveFee":
            await handleUserCreditAndWaveFeePayment(
              bookingDetails,
              props?.bookingId,
              type
            );
            emit("paymentSuccess", true);
            break;
        }
      } catch (err: any) {
        if (err?.message) {
          updateErrorMessage(true, err?.message, "error", "tabler-xbox-x");
        }
      }
      return;
    } else if (props?.type === "event") {
      const details = {
        eventId: props?.eventId,
        loggedInUser: props?.loggedInUserId,
        activityMap: props?.activityMap,
        due: props?.totalDue,
        location: props?.location,
        owner: props?.owner,
      } as any;
      switch (type) {
        case "pos":
          await eventDuePayByPos(details);
          emit("goToPosPayment", docketId.value);
          return;
        case "WaveFee":
          await eventDuePayByWaveFee(details);
          emit("paymentSuccess", true);
          break;
      }
    } else {
      // coupon/giftCard/userCredit
      const paymentDetails = {
        ...props?.requestedPaymentDetails,
        owner: props?.owner,
        loggedInUserId: props?.loggedInUserId,
        location: props?.location,
        identifier: giftCardId.value,
        emailAddress: props?.emailAddress,
      };
      await createEditDocketForCustomizePurchase(paymentDetails, props?.type);

      // Handle different payment types
      try {
        switch (type) {
          case "GiftCard":
            paymentDetails.amount = amountToRedeem.value;
            await payByGiftCard(paymentDetails, props?.type);
            break;
          case "UserCredit":
            await payByUserCredit(paymentDetails, props?.type);
            break;
          case "pos":
            await payByPos(paymentDetails, props?.type);
            emit("goToPosPayment", docketId.value);
            return;
        }
        toast.success("Payment successful", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        docketId.value = "";
        docketLineId.value = "";
        emit("paymentApproved", false);
        emit("closeDialog", false);
      } catch (e) {
        if (!lowBalanceError?.value) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          docketId.value = "";
          docketLineId.value = "";
          emit("closeDialog", false);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Function to calculate and set the required quantity for a coupon to cover the total due.
 *
 * @function handleRequiredQuantity
 * @returns {void}
 */
const handleRequiredQuantity = () => {
  // Coupon value, default to 1 if not provided
  const couponValue = props?.couponValue ?? 1;
  // Booking discount, default to 0 if not provided
  const discount = props?.bookingDiscount ?? 0;
  // Total due, default to 0 if not provided
  const totalDue = props?.totalDue ?? 0;

  // Calculate the maximum quantity that can be applied to cover the total due
  const maxRequiredQuantity = Math.ceil(
    (totalDue * 100) / (couponValue * (100 - discount))
  );

  // Find the selected coupon from the list of coupons
  const couponSelected = getCoupons.value?.find(
    (el: any) => el.id === selectedCoupon.value
  );

  // Determine the quantity to use: the lesser of maxRequiredQuantity or qtyRemaining
  quantity.value = Math.min(
    maxRequiredQuantity,
    couponSelected?.qtyRemaining || 0
  );
};

/**
 * This function handles the automatic application of coupons to a booking.
 * It calculates the maximum quantity of each coupon that can be applied to cover the remaining amount,
 * and then makes a payment using the selected coupons.
 *
 * @returns {Promise<void>}
 */
const handleAutoApplyCoupons = async () => {
  try {
    const identifier: any[] = [];
    let totalAmount = 0;
    let remainingAmount = props?.totalDue ?? 0;

    for (const coupon of getCoupons.value) {
      if (remainingAmount <= 0) break;

      const couponValue = props?.couponValue ?? 1;
      const discount = props?.bookingDiscount ?? 0;
      const amountToApplyPerCoupon = Number(
        (couponValue * (100 - discount)) / 100
      ).toFixed(2);

      const maxCouponQty = coupon?.qtyRemaining; // Quantity of the coupon available
      let qtyToUse = 0;
      let amountToUse = 0;

      // Calculate how many of this coupon we need to cover the remaining amount
      for (let i = 1; i <= maxCouponQty; i++) {
        const totalAmountToApply = i * Number(amountToApplyPerCoupon);
        if (totalAmountToApply >= remainingAmount) {
          qtyToUse = i;
          amountToUse = remainingAmount;
          break;
        } else {
          qtyToUse = i;
          amountToUse = totalAmountToApply;
        }
      }

      identifier.push({
        coupon: coupon?.id,
        amount: amountToUse,
        qty: qtyToUse,
      });

      totalAmount += Number(amountToUse);
      remainingAmount -= Number(amountToUse);
    }

    const paymentParams = {
      status: "Approved",
      paymentType: "Coupon",
      amount: totalAmount,
      identifier,
      toPay: props?.totalDue,
      type: props?.type,
      bookingId: props?.bookingId,
      totalPaid: props?.totalPaid,
      userId: props?.loggedInUserId,
      payMethods: props?.payMethods,
    };

    try {
      const response = await payWithAutoApplyCoupons(paymentParams);
      if (response) {
        clearForm();
        emit("paymentSuccess", true);
        if (props?.haveCreatePermission) {
          // Admin role: get all coupons
          await getCouponList();
        } else {
          // Fetch the list of available user coupons
          await getCouponList(props?.owner);
        }
      }
    } catch (e) {
      console.error(e);
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * Handles the payment process for either a coupon or a gift card.
 *
 * @param {string} type - The type of payment to be processed. It can be either 'Coupon' or 'Gift Card'.
 *
 * @returns {Promise<void>} - A promise that resolves when the payment process is completed successfully.
 *
 * @throws {Error} - Throws an error if there is an issue with the payment process.
 */
const handleApplyProcess = async (type: string) => {
  try {
    let amount;
    let paymentType;
    let identifier;

    if (type === "Coupon") {
      const couponValue = props?.couponValue ?? 1;
      const discount = props?.bookingDiscount ?? 0;
      amount = Number(
        (couponValue * quantity.value * (100 - discount)) / 100
      ).toFixed(2);
      paymentType = "Coupon";
      identifier = selectedCoupon.value;
    } else {
      amount = Number(amountToRedeem.value).toFixed(2);
      paymentType = "Gift Card";
      identifier = giftCardId.value;
    }

    const params = {
      status: "Approved",
      paymentType,
      amount,
      identifier,
      qty: quantity.value,
      toPay: props?.totalDue,
      type: props?.type,
      bookingId: props?.bookingId,
      totalPaid: props?.totalPaid,
      userId: props?.loggedInUserId,
      payMethods: props?.payMethods,
    };

    try {
      const response = await payWithGiftCardOrCoupon(params);
      if (response) {
        clearForm();
        emit("paymentSuccess", true);
        if (props?.haveCreatePermission) {
          // admin role get all coupons
          await getCouponList();
        } else {
          // fetch list of available users coupons
          await getCouponList(props?.owner);
        }
      }
    } catch (e) {}
  } catch (e) {
    console.error(e);
  }
};

const handleDiscountCode = async (code: string) => {
  try {
    updateErrorMessage(true, "Processing your request...", "warning", "");

    // Determine booking or event type details and prepare data accordingly
    const isBooking = props?.type === "Booking";

    // Check if docket already exists
    if (!docketId.value) {
      const details = isBooking
        ? {
            location: props?.location,
            discountProduct: props?.discountProduct,
            discountBookings: props?.discountBookings,
            discountEvents: props?.discountEvents,
            due: props?.totalDue,
            loginUser: props?.loggedInUserId,
            amount: props?.bookingAmount,
            owner: props?.owner,
            paid: props?.totalPaid,
            qty: props?.bookingSlot?.slotQty,
            itemPrice: props?.bookingSlot?.sessionPrice,
            payMethods: props?.payMethods,
          }
        : {
            eventId: props?.eventId,
            discountProduct: props?.discountProduct,
            discountBookings: props?.discountBookings,
            discountEvents: props?.discountEvents,
            loggedInUser: props?.loggedInUserId,
            activityMap: props?.activityMap,
            due: props?.totalDue,
            location: props?.location,
            owner: props?.owner,
          };
      // Initiate docket if not present
      if (isBooking) {
        await initiateDocketForBooking(details, props?.bookingId);
      } else {
        await initiateDocketForEvent(details);
      }
    }

    // Apply discount code after ensuring docket exists
    await handleDiscountCodeApply(
      isBooking ? "BOOKING" : "EVENT",
      docketId.value
    );

    emit("refetch", true);
  } catch (e) {
    console.error("Error applying discount code:", e);
  }
};

/**
 * Handles the error scenario when the user's credit balance is insufficient.
 * It checks if the user has any credit cards and displays appropriate error messages.
 * If no cards exist, it prompts the user to add a new card.
 *
 * @returns {Promise<void>} - A promise that resolves when the error handling process is completed.
 *
 * @throws {Error} - Throws an error if there is an issue with checking the user's cards.
 */
const handleCreditError = async () => {
  try {
    // Check if any card exists
    const resultList = await pb.collection("bankCards").getList(1, 50, {
      filter: `user = "${props?.owner}"`,
    });

    const cards = resultList?.items || [];
    const primaryCard = cards.find((card: any) => card?.isPrimary);

    if (primaryCard) {
      // Extract the last 4 digits of the card number
      const lastFourDigits = primaryCard.cardNum.slice(-4);
      updateErrorMessage(
        true,
        `Insufficient balance. The remaining amount will be charged to your card ending in ${lastFourDigits}.`,
        "error",
        null
      );
    } else if (cards.length) {
      // If there are cards but no primary card, notify the user
      updateErrorMessage(
        true,
        `Insufficient balance. No primary card found. Please add a primary card.`,
        "error",
        null
      );
    } else {
      // If no cards exist, prompt the user to add a new card
      updateErrorMessage(
        true,
        `Insufficient balance and no credit card on file. Please add a new card into your account.`,
        "error",
        null
      );
      console.log("show add new card dialog");
      setTimeout(() => {
        emit("addNewCardDialog", true);
      }, 2000);
    }
  } catch (e) {
    updateErrorMessage(
      true,
      `An error occurred while checking your cards. Please try again later.`,
      "error",
      null
    );
    emit("closeDialog", false);
  } finally {
    setTimeout(() => {
      clearForm();
      emit("closeDialog", false);
    }, 3000);
  }
};

const clearForm = () => {
  selectedUser.value = null;
  selectedCoupon.value = null;
  quantity.value = null;
  giftCardId.value = "";
  appliedGiftCardDetails.value = "";
  amountToRedeem.value = null;
  isInvalidCoupon.value = false;
  lowBalanceError.value = false;
};

// watcher
watch(
  () => [props?.owner, lowBalanceError?.value],
  async ([newOwner, newLowBalanceError]) => {
    // Handle owner changes
    if (newOwner !== undefined) {
      if (newOwner) {
        // Admin role gets all coupons
        await getCouponList();
      } else {
        // Fetch list of available user's coupons
        await getCouponList(props?.owner);
      }
    }

    // Handle lowBalanceError changes
    if (newLowBalanceError) {
      await handleCreditError();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <VRow>
      <VCol sd cols="12" sm="4" md="3" class="position-relative pt-5">
        <!-- 👉 Tabs -->
        <VTabs
          v-model="currentTab"
          direction="vertical"
          class="v-tabs-pill"
          grow
        >
          <VTab v-for="item in tabs" :key="item.icon" :value="item.value">
            <VIcon start :icon="item.icon" />
            {{ item.label }}
          </VTab>
        </VTabs>
      </VCol>

      <VCol cols="12" sm="8" md="9">
        <!-- 👉 Windows -->
        <VWindow v-model="currentTab" :touch-less="true">
          <div class="info-button-container">
            <VTooltip
              v-model="isTooltipVisible"
              activator="click"
              location="top"
              content-class="payment_info_tooltip"
            >
              <template #activator="{ props }">
                <IconBtn
                  v-bind="props"
                  icon="mdi-information-outline"
                  class="info-icon"
                  @click.stop
                  @touchstart.stop="isTooltipVisible = !isTooltipVisible"
                />
              </template>
              <div class="tooltip-content">
                <span v-if="currentTab === 'coupon'">
                  You have two options for applying coupons:
                  <ul class="mt-1">
                    <li>
                      <strong>Apply Coupon:</strong> Select a coupon from the
                      dropdown list and apply it. The required quantity will be
                      deducted from the selected coupon.
                    </li>
                    <li class="mt-1">
                      <strong>Auto Apply Coupon:</strong> Automatically apply
                      the coupon(s) that are closest to their expiration date.
                      This option can use multiple coupons if necessary to meet
                      the required quantity.
                    </li>
                  </ul>
                </span>
                <span v-else-if="currentTab === 'userCredit'">
                  The total amount will be automatically deducted from your
                  available account balance, or charged to your nominated credit
                  card if required.
                </span>
                <span v-else-if="currentTab === 'pos'">
                  You will be redirected to the Point of Sale (POS) screen to
                  complete the payment. Please ensure all details are correct
                  before proceeding.
                </span>
                <span v-else-if="currentTab === 'giftCard'">
                  Enter the gift card code in the provided field. We will
                  validate the code and apply the corresponding balance to your
                  purchase.
                </span>
                <span v-else-if="currentTab === 'waveFee'">
                  Please ensure you have a valid reason to Wave the fee!.
                </span>
              </div>
            </VTooltip>
          </div>
          <VWindowItem
            v-if="getCoupons?.length && props?.type !== 'coupons'"
            value="coupon"
          >
            <VCardText class="pt-0 pb-0">
              <VCardTitle class="d-flex justify-left px-0 pt-0">
                Pay with coupons
              </VCardTitle>
              <VForm ref="refVFormCoupon">
                <VRow>
                  <VCol lg="6" md="6" sm="12" xs="12" cols="12">
                    <VSelect
                      v-model="selectedCoupon"
                      :items="getCoupons"
                      :rules="[requiredValidator]"
                      :menu-props="{ maxHeight: '400' }"
                      clearable
                      item-value="id"
                      item-text="id"
                      label="Select a Coupon"
                      @update:modelValue="handleRequiredQuantity"
                    >
                      <template v-slot:item="{ props, item }">
                        <VListItem
                          v-bind="props"
                          :title="item.raw?.id"
                          :subtitle="`Expires on
                    ${moment(item.raw?.expiryTime).format('YYYY-MM-DD')}`"
                        />
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <span>
                          {{ item?.raw?.id }}
                        </span>
                      </template>
                    </VSelect>
                  </VCol>
                </VRow>
                <VCardActions class="d-flex justify-end mt-2">
                  <VRow class="d-flex justify-end">
                    <VCol
                      class="d-flex flex-column flex-xs-column flex-sm-row justify-end gap-2 px-0 mt-5"
                    >
                      <VBtn
                        variant="flat"
                        color="primary"
                        @click.prevent="handleInitiateFlow('Coupon')"
                      >
                        Apply Coupon
                      </VBtn>
                      <VBtn
                        variant="flat"
                        color="primary"
                        class="ml-0"
                        @click.prevent="handleInitiateFlow('autoApplyCoupon')"
                      >
                        Auto Apply Coupons
                      </VBtn>
                    </VCol>
                  </VRow>
                </VCardActions>
              </VForm>
            </VCardText>
          </VWindowItem>

          <VWindowItem
            v-if="props?.type !== 'transferCredit'"
            value="userCredit"
          >
            <VCardText class="pt-0 pb-0">
              <VCardTitle class="d-flex justify-left px-0 pt-0">
                Pay By Account Credit
              </VCardTitle>
              <div>
                Use the Balance on the account or pay using the Credit Card on
                the account .
              </div>
              <VCardActions class="d-flex justify-end mt-5 px-0">
                <VBtn
                  variant="flat"
                  color="primary"
                  class="mt-16"
                  @click.prevent="handleInitiateFlow('UserCredit')"
                >
                  Pay
                </VBtn>
              </VCardActions>
            </VCardText>
          </VWindowItem>

          <!-- pos payment -->
          <VWindowItem v-if="props?.haveCreatePermission" value="pos">
            <VCardText class="pt-0 pb-0">
              <VCardTitle class="d-flex justify-left px-0 pt-0">
                Pay from POS screen
              </VCardTitle>
              <div>
                This payment method allows you to use the POS to pay for the
                booking.
              </div>
              <VCardActions class="d-flex justify-end mt-5 px-0">
                <VBtn
                  prepend-icon="mdi-network-pos"
                  variant="flat"
                  color="primary"
                  class="mt-16"
                  @click.prevent="handleInitiateFlow('pos')"
                >
                  Pay from pos
                </VBtn>
              </VCardActions>
            </VCardText>
          </VWindowItem>

          <VWindowItem v-if="props?.type !== 'giftCard'" value="giftCard">
            <VCardText class="pt-0 pb-0">
              <VCardTitle class="d-flex justify-left px-0 pt-0">
                Pay from Gift Card
              </VCardTitle>
              <div class="mb-2">
                Use all or part of a Gift Card to pay for this booking.
              </div>
              <VForm ref="refVFormGiftCard">
                <VRow>
                  <VCol
                    v-if="!isInvalidCoupon && !isEmpty(appliedGiftCardDetails)"
                    class="d-flex justify-center"
                    cols="12"
                  >
                    <div>
                      Balance Remaining: ${{
                        appliedGiftCardDetails?.balanceRemaining
                      }}
                    </div>
                  </VCol>

                  <VCol lg="6" md="6" sm="12" xs="12" cols="12">
                    <VTextField
                      v-model="giftCardId"
                      :rules="[requiredValidator]"
                      label="Enter Gift Card Id"
                      @blur="validateGiftCard"
                    />
                  </VCol>
                  <VCol lg="6" md="6" sm="12" xs="12" cols="12">
                    <VTextField
                      v-if="props?.type === 'Booking'"
                      v-model="amountToRedeem"
                      :disabled="isInvalidCoupon || !giftCardId"
                      :rules="[
                        requiredValidator,
                        minMaxValidator(
                          amountToRedeem,
                          1,
                          Math.min(
                            props?.totalDue,
                            appliedGiftCardDetails?.balanceRemaining || 1
                          )
                        ),
                        decimalRule,
                      ]"
                      label="Enter Amount"
                      type="number"
                      prefix="$"
                    />
                    <VTextField
                      v-else
                      v-model="amountToRedeem"
                      :rules="[
                        requiredValidator,
                        minValidator(
                          amountToRedeem,
                          props?.requestedPaymentDetails?.amount
                        ),
                        decimalRule,
                      ]"
                      label="Enter Amount"
                      type="number"
                      prefix="$"
                    />
                  </VCol>
                </VRow>
              </VForm>
              <VCardActions class="d-flex justify-end mt-5 px-0">
                <VBtn
                  v-if="props?.type === 'Booking'"
                  variant="flat"
                  color="primary"
                  @click.prevent="handleInitiateFlow('GiftCard')"
                >
                  Apply gift card
                </VBtn>
                <VBtn
                  v-else
                  :disabled="
                    isInvalidCoupon ||
                    !giftCardId ||
                    appliedGiftCardDetails?.balanceRemaining <
                      props?.requestedPaymentDetails?.amount
                  "
                  variant="flat"
                  color="primary"
                  @click.prevent="handleInitiateFlow('GiftCard')"
                >
                  Apply gift card
                </VBtn>
              </VCardActions>
            </VCardText>
          </VWindowItem>

          <VWindowItem v-if="props?.haveCreatePermission" value="waveFee">
            <VCardText class="pt-0 pb-0">
              <VCardTitle class="d-flex justify-left px-0 pt-0">
                Wave Fee
              </VCardTitle>
              <div class="mb-2">Allows you wave the fee for the booking.</div>
              <VForm ref="refVFormWave">
                <VRow>
                  <VCol lg="6" md="8" sm="10" xs="12" cols="12">
                    <VTextarea
                      v-model="feeWaveReason"
                      :rules="[
                        requiredValidator,
                        customRegexValidator(
                          feeWaveReason,
                          '^.{4,}$',
                          'Please enter minimum of four characters'
                        ),
                      ]"
                      rows="2"
                      label="Reason for Waving the fee"
                      class="waveFee_textarea"
                    />
                  </VCol>
                </VRow>
              </VForm>
              <VCardActions class="d-flex justify-end px-0 mt-5">
                <VBtn
                  variant="flat"
                  color="primary"
                  @click.prevent="handleInitiateFlow('WaveFee')"
                >
                  Wave Fee
                </VBtn>
              </VCardActions>
            </VCardText>
          </VWindowItem>
        </VWindow>
        <div v-if="props?.type && ['event', 'Booking']?.includes(props?.type)">
          <DiscountApply
            :docket="docketId"
            @applyDiscount="handleDiscountCode"
          />
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<style scoped lang="scss">
.waveFee_textarea {
  textarea {
    scrollbar-width: thin;
  }
}

.info-button-container {
  position: absolute;
  z-index: 10;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.info-icon {
  cursor: pointer;
}

:global(.payment_info_tooltip) {
  max-inline-size: 250px !important;
}

.tooltip-content ul {
  margin: 0;
  padding-inline-start: 20px;
}

.tooltip-content li {
  list-style-type: disc;
  margin-block-end: 4px;
}

.tooltip-content strong {
  font-weight: bold;
}

.tooltip-content span {
  display: block;
  margin-block-end: 8px;
}
</style>
