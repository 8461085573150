import type { FileOptions, RecordModel } from "pocketbase";
import PocketBase from "pocketbase";
import type { ISortable } from "@/utils/ISortable";

let BaseURL = window.location.origin;
if (BaseURL.includes("localhost")) BaseURL = "https://data.baybooker.com";

// export const pb = new PocketBase("https://foreseasons.baybooker.com");

export const pb = new PocketBase(BaseURL);

export const collections = {
  products: "products",
  productCategories: "productCategories",
  productSubCategories: "productSubCategories",
  dockets: "dockets",
  docketLines: "docketLines",
  docketPayments: "docketPayments",
  roles: "roles",
  users: "users",
  locations: "locations",
  memberships: "memberships",
  suppliers: "suppliers",
};

export interface ProductRecordModel extends RecordModel, ISortable {
  name: string;
  desc: string;
  sellPrice: number;
  category: string;
  subCategory: string;
  sortOrder: number;
  location: string;
}

export interface ProductCategoryRecordModel extends RecordModel, ISortable {
  name: string;
  icon: string;
  sortOrder: number;
}

export interface ProductSubCategoryRecordModel extends RecordModel, ISortable {
  name: string;
  category: string;
  sortOrder: number;
}

export interface DocketRecordModel extends RecordModel {
  docketNumber: number;
  location: string;
  status: string;
  due: number;
  description: string;
  createdBy: string;
  updatedBy: string;
}

export interface DocketLineRecordModel extends RecordModel {
  docket: string;
  transactionType: string;
  product: string;
  userAccountCredit: string;
  giftCard: string;
  bookingPayment: string;
  qty: number;
  itemPrice: number;
  lineTotal: number;
  status: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface DocketPaymentRecordModel extends RecordModel {
  docket: string;
  paymentType: string;
  amount: string;
  cardTransactionReff: string;
  user: string;
  giftCard: string;
  email: number;
  status: string;
  cardExpiry: string;
  cardCvc: string;
}

export interface SupplierRecordModel extends RecordModel {
  id: string;
  name: string;
  phone: string;
  orderEmail: string;
  accountsEmail: string;
}

export interface RoleRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface GlobalSetupRecordModel {
  id: string;
  collectionId: string;
  collectionName: string;
  created?: string;
  updated?: string;
  areaSingular: string;
  areaPlural: string;
  backGroundImage: string;
  companyName: string;
  SubDomain: string;
  logo?: string;
  signInLogo?: string;
  headerLogo?: string;
  moduleBookings: boolean;
  modulePOS: boolean;
  signUpImage?: string;
  signUpTerms: string;
  taxName: string;
  taxRate: number;
  couponValue: number;
  buyGiftcardDialogDesc: string;
  buyCouponsDialogDesc: string;
  transferCreditDialogDesc: string;
  addCreditDialogDesc: string;
  buyMembershipTerms: string;
  buyMembershipDialogDesc: string;
  redeemGiftDialogDesc: string;
  requireLRHandPreferance: boolean;
}

export interface LocationRecordModel extends RecordModel {
  name?: string;
  description?: string;
  enabled?: boolean;
  address?: string;
  suburb?: string;
  city?: string;
  postcode?: string;
  country?: string;
  phone?: string;
  email?: string;
  website?: string;
  adminEmail?: string;
  taxID?: string;
  taxName?: string;
  taxRate?: number;
  maxBookAheadWeeks?: number;
}

export interface MembershipsRecordModel extends RecordModel {
  id: string;
  name: string;
}
export interface MembershipCategoriesRecordModel extends RecordModel {
  id: string;
  name: string;
}
export interface CouponTypeRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface CouponRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface ActivityRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface AreaRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface TimesRecordModel extends RecordModel {
  id: string;
  name: string;
}

export interface UserRecordModel extends RecordModel {
  username: string;
  email: string;
  emailVisibility?: boolean;
  firstName: string;
  lastName: string;
  status?: string;
  address?: string;
  postcode?: string;
  suburb?: string;
  city?: string;
  state?: string;
  country?: string;
  mobileNumber?: string;
  fob?: string;
}

export function getFullName(user: UserRecordModel | undefined) {
  if (!user) return "";

  let name = user.firstName;
  if (name) name += ` ${user.lastName}`;
  else name = user.username;

  return name;
}

export interface HomeAlerts {
  id: string;
  collectionId: string;
  collectionName: string;
  created: string;
  updated: string;
  message: string;
  startTime: string;
  endTime: string;
  color: string;
  type: "Info" | "Warning" | "Error";
}

export function getFileUrl(
  record: RecordModel,
  fileName: string,
  options?: FileOptions | undefined
) {
  return pb.files.getUrl(record, fileName, options);
}
