<script setup lang="ts">
import { useDiscountCode } from "@/store/useDiscountCode";
import { requiredValidator } from "@validators";

// interface
interface Props {
  docket?: string;
}

interface Emit {
  (e: "applyDiscount", value: string): void;
}

// pops
const props = defineProps<Props>();

// Emits
const emits = defineEmits<Emit>();

// store
const discount = useDiscountCode();

const { discountCode } = storeToRefs(discount);

// Data
const refDiscountCodeVRef = ref<any>("");
</script>

<template>
  <VCardText class="pb-0">
    <VRow>
      <VCol lg="8" md="8" sm="12" xs="12" cols="12">
        <VForm ref="refDiscountCodeVRef">
          <VTextField
            v-model="discountCode"
            :rules="[requiredValidator]"
            placeholder="Discount code"
            label="Discount code"
            density="compact"
            class="barcode_scan_field"
          >
            <template #append-inner>
              <IconBtn
                text="apply"
                color="primary"
                @click="
                  async () => {
                    let { valid } = await refDiscountCodeVRef?.validate();
                    if (valid) {
                      emits('applyDiscount', discountCode);
                    }
                  }
                "
              />
            </template>
          </VTextField>
        </VForm>
      </VCol>
    </VRow>
  </VCardText>
</template>
