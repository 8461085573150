<script setup lang="ts">
import PaymentForm from "@/components/payment/PaymentForm.vue";
import UserChip from "@/components/user/userChips.vue";
import UserListItem from "@/components/user/userListItem.vue";
import FlatPicker from "vue-flatpickr-component";
import { useEvent } from "@/components/events/useEvent";
import { usePaymentStore } from "@/components/payment/usePaymentStore";
import { useDiscountCode } from "@/store/useDiscountCode";
import { useEventStore } from "@/store/useEventStore";
import { useListStore } from "@/store/listStore";
import { useGlobalErrorStore } from "@/store/useGlobalError";
import { pb } from "@/utils/PocketBaseAdapter";
import { moment } from "@/utils/useTimeZone";
import { requiredValidator } from "@validators";
import { cloneDeep, isEmpty } from "lodash";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";
import { VForm } from "vuetify/components/VForm";
import type { EventEmit, EventProps } from "./types";

// props
const props = defineProps<EventProps>();

// Emit
const emit = defineEmits<EventEmit>();

// composable
const globalErrorStore = useGlobalErrorStore();
const eventStore = useEventStore();
const paymentStore = usePaymentStore();
const discount = useDiscountCode();
const listStore = useListStore();

const { handleUpdateErrorMessage } = globalErrorStore;
const { fetchTotalSoldEvents } = eventStore;
const { fetchActivities } = listStore;

const { globalErrorMessage, isBookingWarningDialogVisible, errorDateList } =
  storeToRefs(globalErrorStore);
const { docketId, docketLineId } = storeToRefs(paymentStore);
const {
  eventId,
  eventBookingsId,
  recurringEventId,
  isEventPending,
  soldEvents,
  findSoldTicket,
} = storeToRefs(eventStore);
const { discountCode, discountCodeData } = storeToRefs(discount);
const { activityMap } = storeToRefs(listStore);

// composable
const events = useEvent();

const {
  reserveSlot,
  updateEvent,
  confirmDelete,
  removeExistingPendingBooking,
  refetchEvent,
  handleRecurringEvent,
  getTicketProducts,
} = events;

const {
  paymentOptions,
  daysOfWeek,
  recurringWeeks,
  recurringEndDateConfig,
  getRecurringDates,
} = events;
const vuetifyTheme = useTheme();

// Router
const router = useRouter();

// Refs
const dateTimeForRef = ref<any>("");
const nameDescriptionFormRef = ref<any>("");
const refRecurringVForm = ref<any>("");
const fifthFormRef = ref<any>("");
const eventBookingsState = ref<any>("");
const totalSoldEvents = ref<number>(0);
const productsList = ref<any[]>([]);
const totalTicket = ref<number>(0);
const isLoading = ref<boolean>(false);

// Data
const form = ref<any>({
  id: "",
  title: "",
  date: null,
  activity: {},
  time: null,
  name: "",
  description: false,
  owner: null,
  isRecurring: false,
  trackTickets: false,
  ticketsConfig: [
    {
      product: "",
      ticketLimit: 0,
      eventBarValue: 0,
    },
  ],
});
const role = ref<string>("Member");
const currentStep = ref<number>(0);
const showPopup = ref<boolean>(false);
const actualEndTime = ref<any>("");
const isDeleteDialogVisible = ref<boolean>(false);
const isCancelDialogVisible = ref<boolean>(false);
const recurring = ref<any>({
  frequency: 1,
  interval: "Weeks",
  days: [],
  endDate: "",
});
let inactivityTimer: ReturnType<typeof setTimeout>;
let inactivity: ReturnType<typeof setTimeout>;

// computed
const eventSteps = computed(() => [
  {
    id: 1,
    title: "Date & Time",
    subtitle: "",
    icon: "tabler-calendar",
    size: 30,
    isValid: true,
  },
  {
    id: 2,
    title: "Description",
    subtitle: "",
    icon: "tabler-users",
    size: 30,
    isValid: true,
  },
  ...(form.value.isRecurring
    ? [
        {
          id: 3,
          title: "Recurring Event",
          subtitle: "",
          icon: "tabler-repeat",
          size: 30,
          isValid: true,
        },
      ]
    : []),
  ...(!form.value.trackTickets
    ? [
        {
          id: 4,
          title: "Payment",
          subtitle: "Click save and pay button.",
          icon: "tabler-cards",
          size: 30,
          isValid: false,
        },
      ]
    : [
        {
          id: 5,
          title: "Track Tickets",
          subtitle: "Ticket configuration",
          icon: "tabler-ticket",
          size: 30,
          isValid: true,
        },
      ]),
]);

// Computed
const haveEditAccess = computed(() => {
  return props?.permission?.includes("Edit");
});

const haveCreateAccess = computed(() => {
  return props?.permission?.includes("Create");
});

const getBookingDiscount = computed(() => {
  return props?.event?.discount || 0;
});

const isEditEvent = computed(() => (props?.event?.id ? true : false));

const bookingStatus = computed(() => props?.event?.status);

/**
 * Computed property to generate a list of available times for booking.
 *
 * @returns {Array} - An array of objects representing the available times.
 * Each object contains the following properties:
 * - label: The display label for the time in 12-hour format with AM/PM.
 * - value: The time value in 24-hour format.
 * - color: The color to be applied to the time label.
 * - isNormalHour: A boolean indicating whether the time is a normal hour.
 */
const timeList = computed(() => {
  const times = [];

  // Check if the date and activity are selected
  if (!form.value.date || isEmpty(form.value?.activity)) {
    return [];
  } else {
    const interval = 60; // Interval for generating time slots
    const now = moment();
    const selectedDate = moment(form.value?.date);
    const isToday = selectedDate.isSame(
      moment(moment().format("DD-MM-YYYY")),
      "day"
    );

    // Function to filter past times
    const filterPastTimes = (hour: any, min: any) => {
      if (isToday) {
        const currentHour = now.hour();
        const currentMinute = now.minute();
        return (
          hour > currentHour || (hour === currentHour && min >= currentMinute)
        );
      }
      return true;
    };

    // Function to format time to 12-hour format
    const formatTimeTo12Hour = (hour: any, min: any) => {
      return moment(`${hour}:${min}`, "HH:mm").format("hh:mm A");
    };

    // Check if the user has edit or create access
    if (haveEditAccess.value || haveCreateAccess.value) {
      const dayName = form.value.date
        ? moment.utc(form.value.date)?.format("dddd").toLowerCase()
        : "sunday";

      // Filter available times for the selected day
      const filteredTimes = availableTimes.value?.filter(
        (timeSlot: any) => timeSlot[dayName]
      );

      // Generate time slots
      for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min += interval) {
          // Filter past times
          if (!filterPastTimes(hour, min)) {
            continue;
          }

          const formattedHour = hour.toString().padStart(2, "0");
          const formattedMin = min.toString().padStart(2, "0");
          const time = `${formattedHour}:${formattedMin}`;
          const timeInMinutes = hour * 60 + min;

          // Check if the time slot is available
          const isAvailable = filteredTimes.some((timeSlot: any) => {
            const startTime = timeSlot.startHour * 60 + timeSlot.startMin;
            const endTime = timeSlot.finishHour * 60 + timeSlot.finishMin;
            return timeInMinutes >= startTime && timeInMinutes < endTime;
          });

          times.push({
            label: formatTimeTo12Hour(hour, min), // Display 12-hour format with AM/PM
            value: time, // Keep value in 24-hour format
            color: !isAvailable
              ? vuetifyTheme.name.value === "dark"
                ? "rgb(72, 72, 72)"
                : "lightgrey"
              : "",
            isNormalHour: isAvailable ? true : false,
          });
        }
      }
      return times;
    }
  }
});

const availableTimes = computed(() =>
  props?.activityList?.reduce((times, activity) => {
    return form.value.activity?.[activity?.id]
      ? [...times, ...(activity?.expand?.availibleTimes || [])]
      : times;
  }, [])
);

/**
 * Computed property to configure the date picker.
 *
 * @returns {Object} - The configuration object for the date picker.
 * @property {string} dateFormat - The format of the date to be displayed.
 * @property {Array} disable - An array of functions to disable specific dates.
 */
const datePickerConfig = computed(() => {
  let disabledDays = ref([...Array(7).keys()]);

  // If there are available times, filter out the days with available times
  if (availableTimes.value.length) {
    availableTimes.value?.forEach((time: any) => {
      daysOfWeek.forEach((day, index) => {
        if (time[day]) {
          // Remove the index from the disabled days array
          disabledDays.value = disabledDays.value?.filter(
            (d: any) => d !== index
          );
        }
      });
    });
  } else {
    // If there are no available times, allow all days
    disabledDays.value = [];
  }

  return {
    dateFormat: "d-m-Y",
    disable: [
      function (date: any) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset the time part to just compare dates

        if (!form.value.id) {
          // Disable past dates
          return date < today;
        }
      },
    ],
  };
});

/**
 * Computes the total booking amount based on the selected activities, duration, and their respective prices.
 *
 * @returns {number} - The total booking amount.
 */
const getBookingAmount = computed(() => {
  // Extract the necessary form values
  const { activity, duration } = form.value;

  // Check if the activity and duration are valid
  if (!isEmpty(activity) && duration) {
    // Convert the duration to minutes
    const durationTotal = mapSelectionToMinutes(
      String(duration).replace(":", ".")
    );

    // Calculate the number of slots based on the duration
    const numberOfSlots = Math.ceil(durationTotal / 60);

    // Calculate the total booking amount
    return Object.keys(activity).reduce((total, activityKey) => {
      // Get the session price for the current activity
      const sessionPrice =
        activityMap.value?.[activityKey]?.sessionFullPrice || 0;

      // Get the number of bays selected for the current activity
      const activityLength = activity[activityKey]?.length || 0;

      // Add the current activity's cost to the total
      return total + sessionPrice * activityLength * numberOfSlots;
    }, 0);
  }

  // Return 0 if the activity or duration is not valid
  return 0;
});

/**
 * Computed property to generate the global info message for the booking.
 *
 * @returns {Object} - The global info message object with properties: color, icon, message, paymentOptions.
 */
const globalInfoMessage = computed(() => {
  const totalPaid = props?.event?.paid || 0;
  const discountAmount =
    getBookingAmount.value * (getBookingDiscount.value / 100);
  const calculatedValue = (
    getBookingAmount.value -
    totalPaid -
    discountAmount
  ).toFixed(2);

  const isDue = props?.event?.due;
  const payOptions =
    haveCreateAccess.value || haveEditAccess.value
      ? paymentOptions.value
      : paymentOptions.value.filter(
          (option) => !["pos", "waveFee"].includes(option.value)
        );

  const baseMessage = {
    color: "dialogHeaderColor",
    message: `$${calculatedValue} - Play or practice in event.`,
    paymentOptions: isDue ? payOptions : undefined,
  };

  if (isEditEvent.value) {
    const statusColorName = isDue
      ? bookingStatus.value === "futureBooking"
        ? "warning"
        : "error"
      : "dialogHeaderColor";

    const statusIcon = isDue
      ? bookingStatus.value === "futureBooking"
        ? "tabler-alert-triangle"
        : "tabler-xbox-x"
      : "";

    return {
      ...baseMessage,
      color: statusColorName,
      icon: statusIcon,
      message: isDue
        ? `The Session fee of $${calculatedValue} has not been paid yet`
        : props?.event?.payMethod?.length > 1
        ? `Booking paid by ${props.event.payMethod.join(", ")}`
        : props?.event?.payMethod?.length === 1 &&
          props.event.payMethod[0] === "Fee Waved"
        ? "Booking fee has been waved."
        : "The Session has been fully paid.",
    };
  }

  return baseMessage;
});

/**
 * Computed property to determine the maximum number of weeks or months a user can book ahead.
 *
 * @returns {number} - The maximum number of weeks or months a user can book ahead.
 */
const getMaxBookAhead = computed(() => {
  // Find the current location in the location list
  const location = props?.locationList?.find(
    (location: any) => location?.id === props?.currentLocation
  );

  // Check the recurring interval and return the corresponding maximum book ahead value
  return recurring?.value?.interval === "Weeks"
    ? location?.maxBookAheadWeeks
    : recurring?.value?.interval === "Months"
    ? location?.maxBookAheadWeeks / 4
    : 0;
});

const inLinePickerConfig = computed(() => {
  return {
    inline: true,
    multiple: true,
    readonly: true,
    defaultDate: getRecurringDates.value(recurring.value),
  };
});

// Methods
const getAvailableProducts = (currentIndex: number) => {
  const selectedIds = form.value.ticketsConfig
    .map(
      (ticket: any, index: number) => index !== currentIndex && ticket.product
    )
    .filter(Boolean); // Exclude empty selections

  return productsList.value?.filter(
    (product) => !selectedIds.includes(product.id)
  );
};

const validateArea = async () => {
  if (isEmpty(form.value.activity)) {
    handleUpdateErrorMessage(
      true,
      `Please select at least 1 ${props?.areaSingular}.`
    );
    return false;
  }
  return true;
};

/**
 * Validates the recurring configuration for a booking.
 *
 * @returns {Promise<boolean>} - Returns true if the recurring configuration is valid, false otherwise.
 */
const validateRecurring = async () => {
  // If the booking is not recurring, return true
  if (!form.value?.isRecurring) {
    return true;
  }

  // Extract the recurring configuration values
  const { interval, endDate, frequency, days } = recurring?.value;

  // Define the required fields and their corresponding error messages
  const requiredFields = [
    {
      field: interval,
      message: "Please select a recurring interval.",
    },
    {
      field: frequency,
      message: "Please specify the recurring frequency.",
    },
    {
      field: days && days?.length > 0,
      message: "Please select at least one recurring day.",
    },
    {
      field: endDate,
      message: "Please specify the recurring end time.",
    },
  ];

  // Find the missing field (if any)
  const missingField = requiredFields.find(({ field }) => !field);

  // If a missing field is found, display an error message and return false
  if (missingField) {
    handleUpdateErrorMessage(true, missingField.message);
    return false;
  }

  // If no missing fields are found, return true
  return true;
};

/**
 * Converts a selected time value to minutes.
 *
 * @param {any} selectedValue - The selected time value to be converted.
 * @returns {number} - The selected time value converted to minutes.
 *
 * @remarks
 * This function handles both whole numbers (e.g., "1") and decimal numbers (e.g., "1.5") representing hours.
 * If the input is a whole number, it is treated as hours and converted to minutes.
 * If the input is a decimal number, it is split into hours and minutes, and both are converted to minutes.
 * The function ensures the input is a string before processing.
 */
const mapSelectionToMinutes = (selectedValue: any) => {
  // Ensure the input is a string
  selectedValue = selectedValue.toString();
  // If the input is a whole number (e.g., "1"), treat it as hours
  if (!selectedValue.includes(".")) {
    return Number(selectedValue) * 60;
  }
  // Split the time string and convert to minutes
  const [hours = "0", minutes = "0"] = selectedValue.split(".").map(Number);
  return hours * 60 + minutes;
};

/**
 * Validates all forms in the booking process.
 *
 * @returns {Promise<boolean>} - Returns true if all forms are valid, false otherwise.
 */
const validateForms = async () => {
  // Validate the date and time form
  const { valid: dateTimeValid } = await dateTimeForRef.value?.validate();

  // Validate the name and description form
  const { valid: nameDescriptionValid } =
    await nameDescriptionFormRef.value?.validate();

  // Validate the recurring form
  const { valid: recurringValid } = await refRecurringVForm.value?.validate();

  // Validate the fifth form (ticket configuration)
  const { valid: trackTicketValid } = await fifthFormRef.value?.validate();

  // Return true if all forms are valid, false otherwise
  return (
    dateTimeValid && nameDescriptionValid && recurringValid && trackTicketValid
  );
};

/**
 * Function to build the recurring details object for a booking.
 *
 * @returns {Object} - The recurring details object.
 * @property {string} id - The ID of the recurring event.
 * @property {boolean} isEditAll - Indicates whether all occurrences of the recurring event should be edited.
 * @property {string} interval - The recurring interval (e.g., Weeks, Months).
 * @property {number} frequency - The recurring frequency.
 * @property {Array} days - The recurring days.
 * @property {string} endDate - The end date of the recurring event in the format "YYYY-MM-DD".
 */
const buildRecurringDetails = () => {
  return form.value.isRecurring
    ? {
        id: props?.recurringDetails?.recurringId || recurringEventId.value,
        isEditAll: props?.recurringDetails?.isEditAll || false,
        interval: recurring.value?.interval,
        frequency: recurring.value?.frequency,
        days: recurring.value?.days,
        endDate: moment(recurring.value?.endDate, "DD-MM-YYYY").toISOString(),
      }
    : {};
};

/**
 * Function to build the booking details object.
 *
 * @returns {Object} - The booking details object.
 * @property {Object} activity - The selected activities.
 * @property {string} date - The booking date in the format "YYYY-MM-DD".
 * @property {string} time - The booking time.
 * @property {number} duration - The booking duration in minutes.
 * @property {string} owner - The owner of the booking.
 * @property {string} name - The name of the booking.
 * @property {string} description - The description of the booking.
 * @property {string} updatedBy - The user ID of the person who updated the booking.
 * @property {boolean} isRecurring - Indicates whether the booking is recurring.
 * @property {Object} recurring - The recurring details of the booking.
 * @property {boolean} trackTickets - Indicates whether tickets are being tracked for the booking.
 * @property {string} ticketsConfig - The list of the tickets products for the booking.
 * @property {number} ticketLimit - The ticket limit for the booking.
 * @property {string} loggedInUser - The user ID of the logged-in user.
 */
const buildBookingDetails = () => {
  return {
    activity: form.value.activity,
    date: form.value?.date,
    time: form.value.time,
    duration: form.value.duration,
    owner: form.value.owner,
    name: form.value.name,
    description: form.value.description,
    updatedBy: props?.userId,
    isRecurring: form.value.isRecurring,
    recurring: buildRecurringDetails(),
    trackTickets: form.value?.trackTickets,
    loggedInUser: props?.userId,
    ticketsConfig: form.value?.ticketsConfig,
    docketId: props?.event?.docket,
  };
};

/**
 * Handles the submission of a booking.
 * Validates all forms, builds the booking details, and updates or creates the event.
 *
 * @returns {Promise<void>}
 */
const handleSubmit = async () => {
  // Validate forms and exit if invalid
  if (!(await validateForms()) || !(await validateRecurring())) return;

  const ticketConfig = cloneDeep(form.value.ticketsConfig);

  showPopup.value = false;

  const bookingDetails = buildBookingDetails();
  const lastStepIndex = eventSteps.value.length - 1;

  try {
    isLoading.value = true;
    // Process booking based on recurrence
    await (form.value.isRecurring
      ? handleRecurringEvent(bookingDetails)
      : updateEvent(bookingDetails));

    // Reset state and clear pending timers
    eventBookingsState.value = "";
    clearPendingTimer();

    // Determine next steps based on trackTickets
    if (!errorDateList.value.length) {
      // Case 1: Event ticket create - Go to last step if no ticketsConfig yet, otherwise wait for ticketConfig data
      // Case 2: Event ticket edit - Close dialog if valid ticketsConfig; otherwise, go to last step

      const hasEventId = props?.event?.id;
      const hasTicketsConfig = !isEmpty(ticketConfig);

      if (hasEventId && hasTicketsConfig && form?.value?.trackTickets) {
        emit("closeDialog", false);
      } else {
        if (currentStep.value !== lastStepIndex) {
          currentStep.value = lastStepIndex;
        } else {
          emit("closeDialog", false);
        }
      }
    }
  } catch (e) {
    handleErrors(e); // Handle any errors
  } finally {
    isLoading.value = false;
  }
};

const createUpdateRecurringBookings = async () => {
  /**
   * Function to create or update recurring bookings.
   *
   * @returns {Promise<void>}
   */
  // Build the booking details object
  const bookingDetails = buildBookingDetails();

  try {
    // Handle the recurring event
    await handleRecurringEvent(bookingDetails);

    // If there are no error dates, go to the agreement tab
    if (!errorDateList.value.length) {
      currentStep.value++;
    }
  } catch (e) {
    // Handle any errors that occur during the process
    handleErrors(e);
  }
};

const handleErrors = (error: any) => {
  const { message } = error?.response || {};
  if (message) {
    handleUpdateErrorMessage(true, message);
  }
};

const confirmCloseBooking = async () => {
  // check and remove pending state booking
  await removeExistingPendingBooking();
  emit("closeDialog", false);
};

const closeDialog = async () => {
  if (
    isEditEvent.value ||
    ![0, 1].includes(currentStep.value) ||
    eventBookingsState.value !== "Pending"
  ) {
    emit("closeDialog", false);
  } else {
    isCancelDialogVisible.value = true;
  }
};

// scroll to active hour in list
const handleMenuUpdate = (isOpen: boolean) => {
  if (isOpen) {
    setTimeout(() => {
      const overlayContainer = document?.querySelector(".select-menu-overlay");
      const list = overlayContainer?.querySelector(".v-list");
      if (list) {
        const normalHourElement = Array?.from(list.children)?.find((el) => {
          return el?.classList?.contains("is-normal-hour");
        });
        if (normalHourElement) {
          normalHourElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    }, 200);
  }
};

const goToNextStep = async () => {
  /**
   * Function to handle the navigation to the next step in the booking process.
   *
   * @returns {Promise<void>}
   */
  try {
    const validateBookingDateTime = async () => {
      // Validate the booking date, time, area, duration, and owner.
      const { valid } = await dateTimeForRef.value.validate();
      if (valid) {
        if (!(await validateArea())) {
          return;
        }
        if (!isEditEvent.value && !form.value?.isRecurring && !eventId.value) {
          try {
            // Reserve a slot for the booking.
            await reserveSlot({
              date: form.value?.date,
              time: form.value.time,
              duration: form.value.duration,
              owner: form.value.owner,
              activity: form.value.activity,
              userId: props?.userId,
              trackTickets: form?.value?.trackTickets,
            });
            eventBookingsState.value = "Pending";
            currentStep.value++;
            // Clear the error message.
            handleUpdateErrorMessage(false, "");
          } catch (e: any) {
            const { message } = e?.response;
            if (message) {
              handleUpdateErrorMessage(true, message);
            }
          }
        } else {
          currentStep.value++;
        }
      }
    };

    if (currentStep.value === 0) {
      // Validate Date, time, area, duration, owner which is in the first step.
      await validateBookingDateTime();
    } else if (currentStep.value === 1) {
      // Validate name and description in the second step.
      let { valid } = await nameDescriptionFormRef.value?.validate();
      if (valid) {
        currentStep.value++;
      }
    } else {
      if (form.value?.isRecurring) {
        if (currentStep.value === 2) {
          // Validate recurring configuration which is in the third step.
          let { valid } = await refRecurringVForm.value?.validate();
          if (valid && (await validateRecurring())) {
            await createUpdateRecurringBookings();
          }
        }
      } else {
        // Go to the payment tab.
        currentStep.value++;
      }
    }
  } catch (e) {}
};

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(showInactivityPopup, 2 * 60 * 1000); // 2 minutes
};

const showInactivityPopup = () => {
  showPopup.value = true;
};

const continueBooking = () => {
  showPopup.value = false;
  resetInactivityTimer();
};

const startInactivityTimer = () => {
  inactivity = setTimeout(async () => {
    showPopup.value = false;
    await confirmCloseBooking();
  }, 2 * 60 * 1000);
};

const clearInactivityTimer = () => {
  if (inactivity !== null) {
    clearTimeout(inactivity);
    inactivity = null;
  }
};

const gotToPos = (docketId: string) => {
  // move user to pos list screen
  router.push({
    name: "pos",
    query: {
      did: docketId,
    },
  });
  setTimeout(() => {
    emit("closeDialog", false);
  }, 1000);
};

const selectRecurringItem = (item: any) => {
  /**
   * Function to handle the selection of recurring days.
   *
   * @param {Object} item - The recurring item (day) to be selected.
   *
   * @returns {void}
   */
  // Get the current selected days from the recurring form
  const { days } = recurring.value || {};

  // Get the maximum number of days that can be selected
  const maxItems = getMaxBookAhead?.value || 0;

  // Check if the maximum number of days has been reached and the item is not already selected
  if (!days?.includes(item?.id) && days?.length === maxItems) {
    // If the maximum number of days has been reached, display an error message
    handleUpdateErrorMessage(true, `You can only select up to ${maxItems}.`);
    return;
  }

  // Check if the item is already selected
  const index = days?.findIndex((i: any) => i === item.id);

  // If the item is already selected, remove it from the selected days
  if (index !== -1) {
    recurring.value?.days?.splice(index, 1);
  } else {
    // If the item is not already selected, add it to the selected days
    recurring.value?.days?.push(item.id);
  }
};

const clearPendingTimer = () => {
  showPopup.value = false;
  window.removeEventListener("mousemove", resetInactivityTimer);
  window.removeEventListener("keydown", resetInactivityTimer);
  window.removeEventListener("touchstart", resetInactivityTimer);
  window.removeEventListener("click", resetInactivityTimer);
  clearTimeout(inactivityTimer);
};

const selectBays = (bayId: string, activityId: string) => {
  // Check if the form's areas already contain the activityId
  if (!form.value?.activity[activityId]) {
    // If not, initialize an empty array for the activityId
    form.value.activity[activityId] = [];
  }

  // Check if the bayId is already selected for this activity
  const index = form.value.activity[activityId].indexOf(bayId);

  if (index === -1) {
    // If the bay is not yet selected, add it to the array
    form.value.activity[activityId].push(bayId);
  } else {
    // If the bay is already selected, remove it (toggle behavior)
    form.value.activity[activityId].splice(index, 1);
  }

  // Remove activity id for no bayId
  if (form.value.activity[activityId].length === 0) {
    delete form.value.activity[activityId];
  }
};

const addTicketFieldSet = async () => {
  const { valid } = await fifthFormRef.value?.validate();
  if (!valid) return;

  form.value.ticketsConfig = form.value.ticketsConfig || [];
  form.value.ticketsConfig.push({
    product: "",
    ticketLimit: 0,
    eventBarValue: 0,
  });
};

// Watch
/**
 * Watch function to handle changes in the props.event.
 *
 * @param {Object} newValue - The new value of props.event.
 * @param {Object} oldValue - The old value of props.event.
 *
 * @returns {void}
 */
watch(
  () => props.event,
  (newValue) => {
    if (newValue && !isEmpty(newValue)) {
      const eventData = { ...props?.event };
      const {
        id,
        startDate,
        duration,
        activity,
        name,
        description,
        owner,
        docket,
        recurringConfig,
        ticketConfig,
      } = eventData;

      form.value = {
        id: id,
        date: startDate ? moment(startDate).format("DD-MM-YYYY") : null,
        duration: duration / 60,
        time: startDate ? moment(startDate).format("HH:mm") : null,
        activity: activity,
        name: name,
        description: description,
        owner: owner,
        isRecurring: false,
        trackTickets: ticketConfig?.trackTickets,
      };

      eventId.value = eventData.id;
      docketId.value = docket;

      if (!isEmpty(recurringConfig) && props?.recurringDetails?.isEditAll) {
        form.value.isRecurring = true;
        recurring.value = {
          endDate: moment(recurringConfig?.endDate).format("DD-MM-YYYY"),

          frequency: recurringConfig?.frequency,
          interval: recurringConfig?.interval,
          days: recurringWeeks?.value
            ?.filter((week) =>
              recurringConfig?.days
                ?.split(", ")
                ?.map((day: string) => day.trim())
                ?.includes(week.value)
            )
            .map((week) => week.id),
        };
      }
    } else {
      // for create booking
      form.value = {
        id: null,
        date: moment().format("DD-MM-YYYY"),
        duration: 1,
        time: null,
        activity: {},
        name: "",
        description: "",
        owner: props?.userId,
        isRecurring: false,
      };
      currentStep.value = 0;
      showPopup.value = false;
      if (haveCreateAccess.value) {
        form.value.agreement = true;
      }
    }
  },
  { immediate: true }
);

/**
 * Watch function to handle changes in form values, date, actualDuration, actualStartTime, and globalErrorMessage.
 *
 * @param {Object} - An array containing form.value.date, form.value.actualDuration, form.value.actualStartTime, and globalErrorMessage.value.
 * @param {Function} - A callback function that handles the changes.
 * @param {Object} - The Vue instance.
 *
 * @returns {void}
 */
watch(
  () => [
    form.value.date,
    form.value.actualDuration,
    form.value.actualStartTime,
    globalErrorMessage.value,
  ],
  ([newDate, newDuration, actualStartTime, newError]) => {
    nextTick(() => {
      // Handle change in date
      if (newDate && !timeList.value?.length) {
        form.value.time = null;
      }

      // Handle change in actualDuration and actualStartTime
      if (newDuration && actualStartTime) {
        actualEndTime.value = moment(actualStartTime)
          .add(newDuration, "minutes")
          .format("YYYY-MM-DD HH:mm");
      }

      // Handle globalErrorMessage changes
      if (newError?.isVisible) {
        // Clear the error message after 2 seconds
        setTimeout(() => {
          handleUpdateErrorMessage(false, "", "", "");
        }, 2000);
      }
    });
  },
  { immediate: true }
);

watch(
  () => [props?.isDrawerOpen],
  async ([newValue]) => {
    if (newValue) {
      if (isEmpty(activityMap.value)) {
        await fetchActivities();
      }
      await fetchTotalSoldEvents(props?.event?.id);
      totalSoldEvents.value = soldEvents?.value?.length || 0;
      const list = await getTicketProducts(props?.event?.id);
      productsList.value = list?.products;
      totalTicket.value = list?.totalTicket || 0;
      form.value.ticketsConfig = list?.ticketConfigs;

      if (props?.event?.docket) {
        // fetch docket data
        const docket = await pb
          .collection("dockets")
          .getOne(props?.event?.docket, {
            expand: "discountCode",
            requestKey: null,
          });
        discountCode.value = docket?.discountCode;
        discountCodeData.value = docket?.expand?.discountCode;
      }
    }
  },
  { immediate: true }
);

// When the drawer is closed
watch(
  () => props?.isDrawerOpen,
  (newValue) => {
    if (!newValue) {
      // Clear the pending timer
      clearPendingTimer();

      // Reset form values
      currentStep.value = 0;
      isDeleteDialogVisible.value = false;
      isCancelDialogVisible.value = false;
      // docketId.value = "";
      // docketLineId.value = "";
      eventId.value = "";
      eventBookingsId.value = [];
      form.value = {
        id: "",
        title: "",
        date: null,
        activity: {},
        time: null,
        name: "",
        description: "",
        owner: "",
        isRecurring: false,
        trackTickets: false,
        ticketsConfig: [
          {
            product: "",
            ticketLimit: 0,
            eventBarValue: 0,
          },
        ],
      };
      currentStep.value = 0;
      showPopup.value = false;
      recurringEventId.value = "";
      recurring.value = {
        frequency: 1,
        interval: "Weeks",
        days: [],
        endDate: "",
      };
      errorDateList.value = [];
      isBookingWarningDialogVisible.value = false;
      eventBookingsState.value = "";
      discountCode.value = null;
    }
  },
  { immediate: true }
);

watchEffect(() => {
  // When the event is not being edited and the event is pending
  if (!isEditEvent.value && isEventPending.value) {
    // Reset the inactivity timer
    resetInactivityTimer();

    // Add event listeners for resetting the inactivity timer
    const resetEvents = ["mousemove", "keydown", "touchstart", "click"];
    resetEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );
  }

  // When the popup is not visible
  if (!showPopup.value) {
    // Clear the inactivity timer
    clearInactivityTimer();
  } else {
    // Start the inactivity timer
    startInactivityTimer();
  }
});
</script>

<template>
  <VDialog
    :model-value="props.isDrawerOpen"
    temporary
    persistent
    no-click-animation
    location="end"
    width="900"
    min-height="500"
    class="scrollable-content"
  >
    <VOverlay
      v-model="isLoading"
      contained
      persistent
      class="align-center justify-center"
    >
      <VProgressCircular indeterminate />
    </VOverlay>

    <!-- Timeout error dialog -->
    <TimeOutError
      :is-visible="showPopup"
      @closeDialog="closeDialog"
      @continueBooking="continueBooking"
    />

    <VCard height="auto" flat class="event_stepper">
      <div
        class="d-flex align-center justify-space-between bg-dialogHeaderColor"
      >
        <!-- 👉 Header -->
        <VCardTitle>
          {{ isEditEvent ? "Update Event" : "Add Event" }}
        </VCardTitle>
        <VBtn
          icon="mdi-close"
          size="large"
          variant="text"
          class="rounded-0"
          color=""
          @click="closeDialog"
        ></VBtn>
      </div>

      <VCardText>
        <VRow>
          <!-- large screen stepper -->
          <VCol cols="12" class="stepper-form-header">
            <CustomStepper
              v-model:current-step="currentStep"
              :items="eventSteps"
              :is-active-step-valid="!isEditEvent ? false : undefined"
              class="stepper-icon-step-bg"
              direction="horizontal"
            />
          </VCol>
          <VCol cols="12" class="pt-0">
            <VWindow v-model="currentStep" class="disable-tab-transition">
              <VForm ref="dateTimeForRef">
                <VWindowItem class="mt-2">
                  <VRow class="pa-0">
                    <VCol
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      cols="12"
                      class="pa-0 pl-4 d-flex align-center"
                    >
                      <span
                        >Choose the booking {{ props?.areaPlural }}, Time, and
                        Duration.</span
                      >
                    </VCol>
                    <VCol
                      lg="6"
                      md="6"
                      sm="6"
                      xs="12"
                      cols="12"
                      class="pa-0 pr-3"
                    >
                      <div class="float-right">
                        <VCheckbox
                          v-model="form.isRecurring"
                          :readonly="isEditEvent"
                          label="Make Recurring"
                        ></VCheckbox>

                        <!-- event track -->
                        <VCheckbox
                          v-model="form.trackTickets"
                          label="Track Tickets"
                        ></VCheckbox>
                      </div>
                    </VCol>
                  </VRow>
                  <VRow>
                    <!-- Activity List on the Left -->
                    <VCol lg="3" md="3" sm="4" xs="12">
                      <div
                        v-for="activity in props?.activityList"
                        :key="activity.id"
                        class="pa-3"
                      >
                        <div class="d-flex align-items-center">
                          <div class="font-weight-medium text-primary">
                            {{ activity.name }} {{ props?.areaPlural }}
                          </div>
                        </div>
                      </div>
                    </VCol>
                    <VCol lg="5" md="5" sm="5" xs="12">
                      <div
                        v-for="activity in props?.activityList"
                        :key="activity.id"
                        class="pa-1"
                      >
                        <div class="d-flex align-center">
                          <VBtn
                            v-for="area in props.areaList?.filter((area) =>
                              area.activities.includes(activity.id)
                            )"
                            icon=""
                            :key="area.id"
                            :color="
                              form?.activity[activity.id]?.includes(area?.id)
                                ? 'primary'
                                : 'grey-400'
                            "
                            size="small"
                            class="ml-2"
                            rounded
                            @click="selectBays(area?.id, activity.id)"
                          >
                            {{ area?.areaNumber }}
                            <VTooltip
                              activator="parent"
                              location="top"
                              open-delay="500"
                            >
                              {{ `${props?.areaSingular} ${area?.areaNumber}` }}
                            </VTooltip>
                          </VBtn>
                        </div>
                      </div>
                    </VCol>

                    <!-- 👉 Date -->
                    <VCol lg="4" md="4" sm="4" xs="12" cols="12">
                      <AppDateTimePicker
                        v-model="form.date"
                        :config="datePickerConfig"
                        :key="datePickerConfig"
                        :rules="[requiredValidator]"
                        label="Date"
                        placeholder="Select Date"
                      />
                    </VCol>

                    <!-- 👉 Start Time -->
                    <VCol lg="4" md="4" sm="4" cols="6">
                      <VSelect
                        v-model="form.time"
                        :items="timeList"
                        :rules="[requiredValidator]"
                        :menu-props="{ contentClass: 'select-menu-overlay' }"
                        item-title="label"
                        item-value="value"
                        label="Booked Time"
                        placeholder="Select Time"
                        class="dynamic_time_list"
                        @update:menu="handleMenuUpdate"
                      >
                        <template v-slot:item="{ props, item }">
                          <VListItem
                            v-bind="props"
                            :class="{
                              'is-normal-hour': item.raw.isNormalHour,
                              dark_theme: $vuetify.theme.current.dark,
                              light_theme: !$vuetify.theme.current.dark,
                              restricted_hours: !item.raw.isNormalHour,
                            }"
                            :style="{
                              backgroundColor: item.raw.color,
                              color: $vuetify.theme.current.dark ? '#fff' : '',
                            }"
                          >
                          </VListItem>
                        </template>
                      </VSelect>
                    </VCol>

                    <!-- 👉 Duration -->
                    <VCol lg="3" md="3" sm="4" xs="6" cols="6">
                      <VCombobox
                        v-model="form.duration"
                        :items="[1, 2, 3, 4]"
                        :rules="[requiredValidator]"
                        label="Duration"
                        placeholder="Select Duration"
                      />
                    </VCol>
                    <!-- have create access show them to set or update  -->
                    <VCol lg="5" md="5" sm="6" cols="12">
                      <VAutocomplete
                        v-model="form.owner"
                        :items="props?.memberList"
                        :rules="[requiredValidator]"
                        chips
                        closable-chips
                        item-title="firstName"
                        item-value="id"
                        color="blue-grey-lighten-2"
                        label="Owner"
                        class="member_list_selection"
                      >
                        <template v-slot:chip="{ props, item }">
                          <UserChip
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                            @removeUser="form.owner = []"
                          />
                        </template>

                        <template v-slot:item="{ props, item }">
                          <UserListItem
                            :bind="props"
                            :item="item"
                            :baseUrl="pb.baseUrl"
                          />
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span>
                            {{
                              item.raw.firstName && item.raw.lastName
                                ? `${item.raw.firstName} ${item.raw.lastName}`
                                : item.raw.username
                            }}
                          </span>
                        </template>
                      </VAutocomplete>
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="nameDescriptionFormRef">
                <VWindowItem>
                  <div class="mb-6">Add name and description</div>
                  <VRow>
                    <!-- 👉 Description -->
                    <VCol cols="12">
                      <VTextField
                        v-model="form.name"
                        :rules="[requiredValidator]"
                        label="Event Name"
                        placeholder="Event Name"
                      />
                    </VCol>
                    <!-- 👉 Description -->
                    <VCol cols="12">
                      <VTextarea
                        v-model="form.description"
                        label="Event Description"
                        placeholder="Event Description"
                      />
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="refRecurringVForm">
                <VWindowItem v-if="eventSteps?.some((el) => el.id === 3)">
                  <VRow>
                    <VCol lg="7" md="7" sm="7" cols="12">
                      <div class="mb-6">Select the Recurring configuration</div>
                      <VRow>
                        <VCol lg="3" md="3" sm="6" xs="6" cols="12">
                          <VTextField
                            v-model="recurring.frequency"
                            :rules="[requiredValidator]"
                            label="Repeat"
                          />
                        </VCol>

                        <!-- 👉 Interval -->
                        <VCol lg="4" md="4" sm="6" xs="6" cols="12">
                          <VSelect
                            v-model="recurring.interval"
                            :items="['Weeks', 'Months']"
                            :rules="[requiredValidator]"
                            label="Interval"
                            @update:modelValue="recurring.days = []"
                          >
                          </VSelect>
                        </VCol>
                      </VRow>
                      <VRow>
                        <VCol cols="12">
                          <VBtn
                            v-for="item in recurringWeeks"
                            icon=""
                            :key="item.id"
                            :color="
                              recurring?.days?.includes(item?.id)
                                ? 'primary'
                                : 'grey-400'
                            "
                            size="small"
                            class="ml-1"
                            @click="selectRecurringItem(item)"
                          >
                            {{ item.label }}
                            <VTooltip
                              activator="parent"
                              location="top"
                              open-delay="500"
                            >
                              {{ item?.value }}
                            </VTooltip>
                          </VBtn>
                        </VCol>
                      </VRow>
                      <VRow>
                        <VCol lg="8" md="8" sm="12" xs="12" cols="12">
                          <AppDateTimePicker
                            v-model="recurring.endDate"
                            :key="getMaxBookAhead"
                            :config="recurringEndDateConfig(getMaxBookAhead)"
                            :rules="[requiredValidator]"
                            append-icon="mdi-date"
                            label="ENDS ON"
                            placeholder="ENDS ON"
                          />
                        </VCol>
                      </VRow>
                    </VCol>
                    <VCol cols="5" class="inline_picker_selected_date">
                      <FlatPicker
                        :config="inLinePickerConfig"
                        :key="inLinePickerConfig"
                      />
                    </VCol>
                  </VRow>
                </VWindowItem>
              </VForm>
              <VForm ref="fourthFormRef">
                <VWindowItem v-if="eventSteps?.some((el) => el.id === 4)">
                  <div>
                    <PaymentForm
                      :have-create-permission="haveCreateAccess"
                      :logged-in-user-id="props?.userId"
                      :location="props?.currentLocation"
                      :owner="form?.owner"
                      :activityMap="activityMap"
                      :event-id="eventId"
                      :total-due="props?.event?.due"
                      type="event"
                      @refetch="refetchEvent()"
                      @updateMessage="
                        handleUpdateErrorMessage(
                          $event?.isVisible,
                          $event?.message,
                          $event?.color,
                          $event?.icon
                        )
                      "
                      @paymentSuccess="
                        async () => {
                          await refetchEvent();
                          emit('fetchListEvent', true);
                        }
                      "
                      @goToPosPayment="gotToPos"
                      @closeDialog="emit('closeDialog', false)"
                    />
                  </div>
                </VWindowItem>
              </VForm>
              <VForm ref="fifthFormRef">
                <VWindowItem v-if="eventSteps?.some((el) => el.id === 5)">
                  <div class="mb-6">Ticket Configuration</div>

                  <!-- Loop through the tickets array to render each ticket field set -->
                  <div class="ticket-config-scrollbox">
                    <div
                      v-for="(ticket, index) in form.ticketsConfig"
                      :key="index"
                      class="ticket-field-set mb-4 ticket-config-set"
                      style=""
                    >
                      <VRow>
                        <VCol lg="5" md="5" sm="5" xs="6" cols="12">
                          <VSelect
                            v-model="ticket.product"
                            :items="getAvailableProducts(index)"
                            :rules="[requiredValidator]"
                            item-title="name"
                            item-value="id"
                            label="Select Ticket Product"
                          />
                        </VCol>
                        <VCol lg="3" md="2" sm="3" xs="6" cols="12">
                          <VTextField
                            v-model="ticket.ticketLimit"
                            :rules="[requiredValidator]"
                            label="Ticket Limit"
                          />
                        </VCol>
                        <VCol lg="2" md="2" sm="2" xs="3" cols="6">
                          <VTextField
                            :model-value="findSoldTicket(ticket.product)"
                            readonly
                            label="Sold"
                          />
                        </VCol>
                        <VCol lg="2" md="2" sm="2" xs="3" cols="6">
                          <VTextField
                            v-model="ticket.eventBarValue"
                            :rules="[requiredValidator]"
                            label="Bar Value"
                          />
                        </VCol>
                      </VRow>
                    </div>
                  </div>

                  <div class="mt-3">
                    <!-- Add Button to create a new ticket field set -->
                    <VBtn color="primary" @click="addTicketFieldSet">Add</VBtn>
                  </div>
                </VWindowItem>
              </VForm>
            </VWindow>
          </VCol>
        </VRow>
      </VCardText>
      <div class="bold_tag">
        <VAlert
          v-if="globalErrorMessage?.isVisible && !form.trackTickets"
          :color="globalErrorMessage?.color || 'error'"
          :icon="globalErrorMessage?.icon || 'tabler-xbox-x'"
        >
          {{ globalErrorMessage?.message }}
        </VAlert>
        <VAlert
          v-else-if="!form.trackTickets"
          :color="globalInfoMessage.color"
          :icon="globalInfoMessage?.icon || 'mdi-info'"
        >
          {{ globalInfoMessage?.message }}
        </VAlert>
        <VAlert
          v-else-if="props.event.ticketConfig"
          color="secondary"
          icon="mdi-ticket-confirmation"
        >
          {{
            totalSoldEvents === totalTicket
              ? `All tickets are sold out for this event.`
              : `Sold ${totalSoldEvents} out of ${totalTicket} tickets. ${
                  totalTicket - totalSoldEvents
                } tickets remaining.`
          }}
        </VAlert>
      </div>
      <VCardText>
        <div class="d-flex button_group">
          <div class="button_group__first_btn">
            <VBtn
              :disabled="currentStep === 0"
              color="secondary"
              variant="tonal"
              @click.prevent="currentStep--"
            >
              <VIcon icon="mdi-arrow-left" start class="flip-in-rtl" />
              Previous
            </VBtn>
          </div>
          <div class="d-flex">
            <!-- for large screen alignment button in between  -->
            <div class="booking_dynamic_buttons__large">
              <div
                v-if="isEditEvent && bookingStatus !== 'completed'"
                class="button_group__multi_btn"
              >
                <VBtn color="error" @click="isDeleteDialogVisible = true">
                  Delete
                </VBtn>
              </div>
            </div>
            <VBtn
              variant="flat"
              color="secondary"
              class="ml-2 booking_dynamic_buttons__large"
              @click="closeDialog"
            >
              Close
            </VBtn>

            <!-- Edit booking -->
            <div
              v-if="isEditEvent"
              class="button_group__multi_btn align-content-center"
            >
              <VBtn
                v-if="
                  currentStep !== eventSteps.length - 1 || form?.trackTickets
                "
                color="success"
                @click="handleSubmit"
              >
                {{
                  isEditEvent
                    ? form?.trackTickets
                      ? "Save"
                      : "Save And Pay"
                    : form?.trackTickets
                    ? "Confirm"
                    : "Confirm And Pay"
                }}
              </VBtn>
            </div>

            <!-- create booking -->
            <div v-else class="align-content-center">
              <VBtn
                v-if="currentStep < eventSteps.length - 2"
                class="ml-2"
                @click="goToNextStep"
              >
                Next
                <VIcon icon="mdi-arrow-right" end class="flip-in-rtl" />
              </VBtn>
              <VBtn v-else color="success" class="ml-2" @click="handleSubmit">
                {{
                  isEditEvent
                    ? form?.trackTickets
                      ? "Save"
                      : "Save And Pay"
                    : form?.trackTickets
                    ? "Confirm"
                    : "Confirm And Pay"
                }}
              </VBtn>
            </div>
          </div>
        </div>

        <!-- small screen alignment button at end -->
        <div
          v-if="isEditEvent"
          class="button_group booking_dynamic_buttons__small align-center"
        >
          <VRow class="d-flex align-center justify-space-between">
            <VCol cols="6">
              <VBtn variant="flat" color="secondary" @click="closeDialog">
                Close
              </VBtn>
            </VCol>
            <VCol cols="6" class="d-flex justify-end">
              <VBtn
                v-if="bookingStatus !== 'completed'"
                color="error"
                @click="isDeleteDialogVisible = true"
              >
                Delete
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </VCardText>
    </VCard>

    <!-- Delete Booking confirm dialog-->
    <BookingDeleteConfirmDialog
      :is-open="isDeleteDialogVisible"
      :activity="props?.activityList[0]"
      :is-recurring="props?.recurringDetails?.bookings?.length ? true : false"
      type="Event"
      @closeDialog="isDeleteDialogVisible = $event"
      @cancelBooking="
        async () => {
          await confirmDelete(), emit('closeDialog', false);
        }
      "
    />

    <!-- Booking cancel confirmation dialog -->
    <BookingCancelConfirmDialog
      :is-open="isCancelDialogVisible"
      :activity="props?.activityList[0] || []"
      type="Event"
      @closeDialog="isCancelDialogVisible = $event"
      @cancelBooking="confirmCloseBooking"
    />

    <!-- Booking warning dialog with dates -->
    <ErrorWarnDatesDialog
      :is-open="isBookingWarningDialogVisible"
      :dates="errorDateList"
      @closeDialog="isBookingWarningDialogVisible = false"
    />
  </VDialog>
</template>

<style scoped lang="scss">
.v-slide-group__content {
  padding: 0.625rem;
}

.stepper-icon-step {
  margin-block-start: 0.625rem;
}

.stepper-icon {
  border-radius: 0.3125rem;
  background-color: rgb(var(--v-theme-primary));
  color: rgba(var(--v-theme-on-primary));
}

.stepper-form-header {
  padding: 1.25rem;
  font-size: 0.625rem;
}

.member_list_selection {
  .v-field__input {
    max-block-size: 7.5rem;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

.button_group {
  display: flex;
  justify-content: space-between;

  &__multi_btn {
    padding-inline-start: 0.625rem !important;
  }
}

.booking_dynamic_buttons {
  &__large {
    display: flex;
  }

  &__small {
    display: none !important;
  }
}

@media (max-width: 720px) {
  .button_group {
    &__first_btn {
      padding: 8px;
    }
  }

  .booking_dynamic_buttons {
    &__large {
      display: none;
    }

    &__small {
      display: flex !important;
    }
  }

  .inline_picker_selected_date {
    display: none;
  }
}
</style>
