/* eslint-disable import/order */
import "@/@iconify/icons-bundle";
import App from "@/App.vue";
import layoutsPlugin from "@/plugins/layouts";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "@/plugins/webfontloader";
import router from "@/router";
import "@core/scss/template/index.scss";
import "@styles/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as Sentry from "@sentry/vue";

// toast config
const toastConfig = {
  autoClose: 1000,
  closeButton: true,
  hideProgressBar: true,
  theme: "auto",
  type: "default",
  // position: POSITION.TOP_LEFT,
};

loadFonts();

// Create vue app
const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://f43cd47142232c706a3e2e32be8ffcea@o4508427843600384.ingest.us.sentry.io/4508427843796992",
  integrations: [],
});

const pinia = createPinia();
// Use plugins
app.use(vuetify);
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(layoutsPlugin);
app.use(Vue3Toastify, toastConfig as ToastContainerOptions);
// Mount vue app
router.isReady().then(() => {
  app.mount("#app");
});
// app.mount('#app')
