<script setup lang="ts">
import { useGlobalSetupStore } from "@/utils/GlobalSetupStore";
import { pb } from "@/utils/PocketBaseAdapter";
import { useUserStore } from "@/utils/UserStore";
import { useUserRole } from "@/utils/useUserRole";
import { moment } from "@/utils/useTimeZone";

// stores
const userStore = useUserStore();
const globalStore = useGlobalSetupStore();
const userRole = useUserRole();

const { getCurrentLocation } = userStore;
const { getGlobalSetup, getHomeAlerts } = globalStore;

const { currentLocationDetails, currentLocation, userId } =
  storeToRefs(userStore);
const { globalSetup, homeAlerts } = storeToRefs(globalStore);
const { permission } = storeToRefs(userRole);

// Data
const currentDateTime = ref(moment().format("YYYY-MM-DD HH:mm:ss"));
const tooltipText = ref("copy");

// Computed
const getHeaderLogo = computed(() => {
  return `${pb.baseUrl}/api/files/${globalSetup.value.collectionId}/${globalSetup.value.id}/${globalSetup.value.headerLogo}`;
});

const activeAlerts = computed(() => {
  return homeAlerts.value?.filter((alert) => {
    const startTime = moment(alert?.startTime);
    const endTime = moment(alert?.endTime);
    const currentTime = moment(currentDateTime.value);

    return (
      startTime.isSameOrBefore(currentTime) &&
      endTime.isSameOrAfter(currentTime)
    );
  });
});

// Methods
// copy email id to the clipboard
const copyToClipboard = (text: any) => {
  if (!text) return;
  navigator.clipboard
    .writeText(text)
    .then(() => {
      tooltipText.value = "copied";
    })
    .catch((err) => {
      tooltipText.value = "failed to copy";
    })
    .finally(() => {
      setTimeout(() => {
        tooltipText.value = "copy";
      }, 1000);
    });
};

const subscribeHomeAlerts = async () => {
  try {
    await pb.collection("homeAlerts").subscribe("*", async (res: any) => {
      await getHomeAlerts(currentLocation?.value);
    });
  } catch (e) {
    console.log(error);
  }
};

// watchers to watch user's current location
watch(currentLocation, async (newCurrentLocation) => {
  await getCurrentLocation();
  await getHomeAlerts(newCurrentLocation);
});

// lifecycle hooks
onMounted(async () => {
  if (currentLocation.value) {
    await getCurrentLocation();
  }

  userId.value = pb.authStore.model?.id;
  await getHomeAlerts(currentLocation.value);
  await subscribeHomeAlerts();
});

onUnmounted(() => {
  pb.collection("homeAlerts").unsubscribe("*");
});
</script>

<template>
  <div>
    <VSheet class="text-center pa-8 mb-3 rounded-sm">
      <div>
        <img
          v-if="getHeaderLogo"
          :src="getHeaderLogo"
          alt="Baybooker"
          class="center logoImage"
          style="display: block; margin: auto"
        />
      </div>
      <VDivider class="mx-14 my-6" />
      <div class="text-h5">
        Welcome to
        <span class="font-weight-bold">{{ globalSetup.companyName }}</span>
      </div>
    </VSheet>
    <VCard v-if="activeAlerts.length" class="mb-2">
      <div v-for="alert in activeAlerts" :key="alert?.id" class="bold_tag my-2">
        <VAlert
          :color="alert?.color || 'error'"
          :type="alert?.type?.toLowerCase()"
          :text="alert?.message"
        />
      </div>
    </VCard>

    <!-- Show 4 Booking of logged in user -->
    <PriorityBookings v-if="pb?.authStore?.model?.id" />

    <VCard class="rounded-sm mt-3" v-if="pb?.authStore?.model?.id">
      <VRow no-gutters>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn
              :to="`/user/${userStore.userId}`"
              block
              class="me-2"
              prepend-icon="mdi-account-outline"
            >
              Profile
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn to="bookings" block prepend-icon="mdi-calendar-select">
              Bookings
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn to="calendar" block prepend-icon="mdi-calendar-month-outline">
              Calendar
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn
              to="faqs/user"
              block
              class="me-2"
              prepend-icon="mdi-frequently-asked-questions"
            >
              FAQ
            </VBtn>
          </VSheet>
        </VCol>
      </VRow>

      <VRow
        no-gutters
        v-if="permission && permission?.canSeeAdmin?.includes(true)"
      >
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn
              to="/user"
              block
              class="me-2"
              prepend-icon="mdi-account-group-outline"
            >
              Users
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn block to="dockets" class="me-2" prepend-icon="mdi-cart">
              Dockets
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn to="/pos" block class="me-2" prepend-icon="mdi-network-pos">
              POS
            </VBtn>
          </VSheet>
        </VCol>
        <VCol>
          <VSheet class="pa-2 ma-2">
            <VBtn to="reports" block prepend-icon="mdi-chart-bar">
              Reports
            </VBtn>
          </VSheet>
        </VCol>
      </VRow>
    </VCard>

    <VCard class="mt-6 px-8 py-4 rounded-sm">
      <VRow no-gutters>
        <VCol>
          <VSheet class="text-left">
            <VCardTitle class="ps-4">
              {{ globalSetup.companyName }}
            </VCardTitle>
            <VCardText class="py-0 ps-4">
              <div>
                <p>{{ currentLocationDetails.address }}</p>
                <p>{{ currentLocationDetails.suburb }}</p>
                <p>
                  {{ currentLocationDetails.city }}
                  {{ currentLocationDetails.postcode }}
                </p>
                <p>{{ currentLocationDetails.country }}</p>
                <p class="pt-1">
                  <a
                    :href="`https://maps.google.com/?q=${currentLocationDetails.address},${currentLocationDetails.suburb},${currentLocationDetails.city} ${currentLocationDetails.postcode},${currentLocationDetails.country}`"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Google Maps
                    <VIcon icon="mdi-map-marker" size="small" />
                  </a>
                </p>
              </div>
            </VCardText>
          </VSheet>
        </VCol>
        <VCol />

        <VCol>
          <VSheet class="text-left">
            <VCardTitle class="ps-4"> Contact details </VCardTitle>
            <VCardText class="d-flex flex-column align-start ps-4">
              <VBtn
                prepend-icon="mdi-phone"
                variant="plain"
                class="px-0"
                @click="copyToClipboard(currentLocationDetails.phone)"
              >
                {{ currentLocationDetails.phone || "phone" }}
                <VTooltip activator="parent" location="right">
                  {{ tooltipText }}
                </VTooltip>
              </VBtn>
              <VBtn
                prepend-icon="mdi-email"
                variant="plain"
                class="px-0"
                @click="copyToClipboard(currentLocationDetails.email)"
              >
                {{ currentLocationDetails.email || "email" }}
                <VTooltip activator="parent" location="right">
                  {{ tooltipText }}
                </VTooltip>
              </VBtn>
              <VBtn
                :href="currentLocationDetails.website"
                prepend-icon="mdi-globe"
                variant="plain"
                class="px-0 website_url"
                target="_blank"
              >
                <div>
                  {{ currentLocationDetails.website || "website" }}
                </div>
              </VBtn>
            </VCardText>
          </VSheet>
        </VCol>
        <VCol />
      </VRow>
    </VCard>
  </div>
</template>

<style scoped lang="scss">
p {
  margin: 0;
}

.logoImage {
  block-size: 294px;
  inline-size: 400px;
  object-fit: contain;

  @media (max-width: 600px) {
    block-size: auto;
    inline-size: 100%;
  }
}

@media (max-width: 390px) {
  .website_url {
    margin-inline-start: 10px;
  }
}
</style>
