import { getBookingStatus } from "@/utils/Formatting";
import { pb } from "@/utils/PocketBaseAdapter";
import { useGlobalErrorStore } from "@/store/useGlobalError";
import { moment } from "@/utils/useTimeZone";
import { useListStore } from "./listStore";
import type { User } from "@/store/types";

export const useCalendarStore = defineStore("calendar", () => {
  // store
  const list = useListStore();
  const globalErrorStore = useGlobalErrorStore();

  const { handleUpdateErrorMessage } = globalErrorStore;
  const { getListArea, fetchActivities: fetchActivityList } = list;

  const { listArea: areasList, activityList } = storeToRefs(list);

  // data
  const bookingList = ref<any>();
  const availableCalendars = ref<any>([]);
  const selectedCalendars = ref<any>([]);
  const isBookingStepperVisible = ref<boolean>(false);
  const isSimplerBookingStepperVisible = ref<boolean>(false);
  const isConfirmEditBookingDialog = ref<boolean>(false);
  const editBookingData = ref<any>("");
  const usersList = ref<User[]>([]);
  const areaList = ref<any[]>([]);
  const bookingStatusList = ref([
    {
      color: "grey",
      name: "Show Completed",
      value: "completed",
    },
    {
      color: "green",
      name: "Show In Progress",
      value: "inprogress",
    },
  ]);
  const selectedBookingStatus = ref<any>([]);
  const currentViewType = ref<string>("");
  const calendarUpdateKey = ref<number>(1);
  const visibleStartDate = ref<any>();
  const recurringDetails = ref<any>({});
  const visibleRange = ref<any>({});

  // computed
  const getBookingList = computed(() => {
    // Filter booking based on selected calendars
    const filteredBookings =
      bookingList.value?.filter((el: any) =>
        selectedCalendars?.value?.includes(el.activity)
      ) || [];

    // Further filter based on view type and status
    if (currentViewType.value === "listMonth") {
      const today = moment().startOf("day"); // Start of today
      // Filter bookings that start today or in the future
      const filteredByDate = filteredBookings.filter((el: any) => {
        const startDate = moment(el.calendar.startDate).startOf("day"); // Start of booking's startDate
        return startDate.isSameOrAfter(today); // Include today or future dates
      });

      // Further filter based on selected booking status
      if (selectedBookingStatus.value.length) {
        return filteredByDate.filter(
          (el: any) =>
            selectedBookingStatus.value.includes(el.calendar.status) ||
            el.calendar.status === "futureBooking"
        );
      }

      return filteredByDate?.filter(
        (booking: any) => booking?.calendar?.status === "futureBooking"
      );
    }

    // Filter by selected booking status for other views
    if (selectedBookingStatus.value.length) {
      return filteredBookings.filter(
        (el: any) =>
          selectedBookingStatus.value.includes(el.calendar.status) ||
          el.calendar.status === "futureBooking"
      );
    }

    // no selection on side status
    return filteredBookings?.filter(
      (booking: any) => booking?.calendar?.status === "futureBooking"
    );
  });

  // Methods
  const fetchBookings = async () => {
    try {
      const objectMap = availableCalendars.value.reduce(
        (acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        },
        {}
      );

      // Activity list of selected location
      const activitiesList = availableCalendars.value?.map(
        (el: any) => `activity="${el.id}"`
      );
      const filter = `(${activitiesList.join(" || ")}) && startTime <= '${
        visibleRange.value.endDate
      }' && endTime >= '${visibleRange.value.startDate}' && state != 'Deleted'`;

      let bookings = await pb.collection("bookings").getFullList({
        filter: filter,
        expand: "users,area,activity,docketLine",
      });

      if (bookings) {
        const bookingIds = bookings.map((booking) => booking.id);
        const [recurringBookingsList, eventsList] = bookingIds.length
          ? await Promise.all([
              pb.collection("recurringBookings").getFullList({
                filter: bookingIds
                  .map((id) => `bookings ~ '${id}'`)
                  .join(" || "),
              }),
              pb.collection("events").getFullList({
                filter: bookingIds
                  .map((id) => `bookings ~ '${id}'`)
                  .join(" || "),
                expand:
                  "bookings.owner,bookings.docketLine.docket,bookings.area", // Ensure to expand all necessary fields
              }),
            ])
          : [[], []];

        const recurringEventsList = eventsList.length
          ? await pb.collection("recurringEvents").getFullList({
              filter: eventsList
                .map((event) => `events ~ '${event.id}'`)
                .join(" || "),
              expand: "events.bookings.docketLine.docket",
            })
          : [];

        // Map bookings with user names and recurring booking details
        bookings.map((el) => {
          // Create user names
          const userNames =
            el.expand?.users?.map(
              (user: any) => `${user.firstName} ${user.lastName}`
            ) || [];
          el.title = userNames.join(", ");
          el.start = el.startTime;
          el.end = moment(el.startTime)
            .clone()
            .add(el.duration, "minutes")
            .toISOString();
          el.calendar = {
            eventColor: objectMap[el?.activity]?.color,
            activityName: objectMap[el?.activity]?.name,
            due: el.due,
            id: el.id,
            startDate: el.startTime,
            endTime: moment(el.startTime)
              .add(el.duration, "minutes")
              .toISOString(),
            duration: el.duration,
            statusClass: getBookingStatus(
              el.actualStartTime,
              el.actualDuration
            ),
            status: getBookingStatus(el.actualStartTime, el.actualDuration),
          };

          // Check for recurring bookings
          const matchedRecurring = recurringBookingsList?.find((recurring) =>
            recurring?.bookings?.includes(el.id)
          );
          el.calendar.recurringBookingDetails = matchedRecurring || {};

          // Check for event details
          const matchedEvent = eventsList?.find((event) =>
            event?.bookings?.includes(el.id)
          );
          el.calendar.eventDetails = matchedEvent || {};

          // Check for recurring event details within the expanded events
          const matchedRecurringEvent = recurringEventsList?.find(
            (recurringEvent) =>
              recurringEvent.expand?.events?.some((event: any) =>
                event.expand?.bookings?.some(
                  (booking: any) => booking.id === el.id
                )
              )
          );
          el.calendar.recurringEventDetails = matchedRecurringEvent || {};
        });

        bookingList.value = bookings;

        // Trigger calendar update after a delay
        setTimeout(() => {
          calendarUpdateKey.value++;
        }, 2000);
      }
    } catch (e) {}
  };

  const fetchActivities = async () => {
    try {
      await fetchActivityList();
      availableCalendars.value = activityList.value;
      selectedCalendars.value = availableCalendars.value.map(
        (el: any) => el.id
      );

      return activityList.value;
    } catch (e) {}
  };

  const getUserList = async () => {
    try {
      if (usersList.value.length) {
        return;
      }
      const records = await pb.collection("users").getFullList({
        fields: "id,name,email,firstName,lastName,avatar,username,leftHanded",
        filter: 'status != "Suspended"',
      });
      if (records) {
        usersList.value = records;
      }
    } catch (e) {}
  };

  const getAreasList = async () => {
    try {
      await getListArea();
      areaList.value = areasList.value;
    } catch (e) {}
  };

  const deleteBooking = async ({
    bookingId,
    recurringId,
    isAll,
  }: {
    bookingId: string;
    recurringId?: string;
    isAll: boolean;
  }) => {
    if (!bookingId && !recurringId) return;

    const currentDate = new Date();

    try {
      const processBooking = async (booking: any) => {
        const bookingDate = new Date(booking.startTime);

        if (bookingDate >= currentDate) {
          if (booking.docketLine) {
            await pb.collection("bookings").update(booking.id, {
              state: "Deleted",
              value: 0,
            });
          } else {
            await pb.collection("bookings").delete(booking.id);
          }
        }
      };

      if (recurringId && isAll) {
        const recurringBooking = await pb
          .collection("recurringBookings")
          .getOne(recurringId, { expand: "bookings" });

        const bookings = recurringBooking?.expand?.bookings || [];
        await Promise.all(bookings.map(processBooking));
      } else {
        const { docketLine, startTime, id } = await pb
          .collection("bookings")
          .getOne(bookingId);

        const bookingDate = new Date(startTime);
        if (bookingDate >= currentDate) {
          await processBooking({ id, docketLine, startTime });
        }
      }

      // Trigger calendar update after deletion
      setTimeout(() => {
        calendarUpdateKey.value++;
      }, 2000);

      // Hide booking stepper after processing
      isBookingStepperVisible.value = false;
    } catch (error) {
      console.error("Error removing existing pending booking:", error);
    }
  };

  const updateVisibleDateRange = (start: any, end: any) => {
    visibleRange.value = {
      startDate: moment(start)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"), // start of the day
      endDate: moment(end).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"), // end of the day
    };
  };

  // Hooks
  onMounted(async () => {
    await getUserList();
  });

  return {
    availableCalendars,
    selectedCalendars,
    getBookingList,
    editBookingData,
    usersList,
    areaList,
    bookingStatusList,
    selectedBookingStatus,
    currentViewType,
    calendarUpdateKey,
    visibleStartDate,
    isBookingStepperVisible,
    isSimplerBookingStepperVisible,
    recurringDetails,
    isConfirmEditBookingDialog,
    visibleRange,
    deleteBooking,
    fetchBookings,
    getAreasList,
    fetchActivities,
    updateVisibleDateRange,
    getUserList,
  };
});
