import type { ErrorMessage } from "@/store/types";

export const useGlobalErrorStore = defineStore("globalError", () => {
  // data
  const globalErrorMessage = ref<ErrorMessage>({});
  const isBookingWarningDialogVisible = ref<boolean>(false);
  const errorDateList = ref<any[]>([]);

  // Method
  const handleUpdateErrorMessage = (
    isVisible: boolean,
    message: string,
    color: any = null,
    icon: any = null
  ) => {
    const error = {
      isVisible: isVisible,
      message: message,
      color: color ? color : "error",
      icon: icon ? icon : "tabler-xbox-x",
    };

    globalErrorMessage.value = error;
  };

  return {
    globalErrorMessage,
    isBookingWarningDialogVisible,
    errorDateList,
    handleUpdateErrorMessage,
  };
});
