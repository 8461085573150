import { pb } from "@/utils/PocketBaseAdapter";
import { useUserStore } from "@/utils/UserStore";
import { debounce } from "lodash";

export const useListStore = defineStore("list", () => {
  // store
  const userStore = useUserStore();

  const { currentLocation } = storeToRefs(userStore);

  // Data
  const productsList = ref<any[]>([]);
  const listArea = ref<any[]>([]);
  const activityList = ref<any>([]);
  const locationList = ref<any[]>([]);
  const adminUsersList = ref<any[]>([]);
  const isAreasLoading = ref<boolean>(false);
  const isActivityLoading = ref<boolean>(false);

  // computed
  const productsMap = computed(() => {
    return productsList.value.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {});
  });

  const activityMap = computed(() => {
    return activityList.value?.reduce((map: any, activity: any) => {
      map[activity.id] = activity;
      return map;
    }, {});
  });

  // methods
  const getProducts = async () => {
    try {
      if (productsList.value.length) {
        return;
      }
      const products = await pb.collection("products").getFullList({
        expand: "category",
      });
      if (products) {
        productsList.value = products;
      }
    } catch (e) {}
  };

  const getListArea = async () => {
    try {
      if (isAreasLoading.value) {
        return;
      }

      isAreasLoading.value = true;
      const response = await pb.collection("areas").getFullList({
        requestKey: null
      });
      if (response && response.length) {
        listArea.value = response;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isAreasLoading.value = false;
    }
  };

  const fetchActivities = async () => {
    try {
      if (isActivityLoading.value) {
        return;
      }
      isActivityLoading.value = true;

      const records = await pb.collection("activities").getFullList({
        expand: "availibleTimes",
        filter: `location = '${currentLocation.value}'`,
        requestKey: null
      });
      if (records) {
        activityList.value = records;
      }
    } catch (e) {
    } finally {
      isActivityLoading.value = false;
    }
  };

  const getLocationList = debounce(async () => {
    try {
      const list = await pb.collection("locations").getFullList({});
      if (list) {
        locationList.value = list;
      }
    } catch (e) {
      console.log(e);
    }
  }, 500);

  /**
   * Function to fetch and filter the list of admin users from the "users" collection.
   *
   * @returns {Promise<void>} - A promise that resolves when the admin users list is fetched and filtered.
   * The filtered list of admin users is stored in the `adminUsersList` reactive variable.
   * If an error occurs during the fetch or filter operation, an error message is logged to the console.
   */
  const getAdminUsersList = async () => {
    try {
      if (adminUsersList.value.length) {
        return;
      }

      // Fetch all users with their roles expanded
      const records = await pb.collection("users").getFullList({
        fields: "id,username,email,roles,expand",
        filter: "roles != '[]'",
        expand: "roles",
      });

      // Filter users whose roles include "Admin" in the role name
      adminUsersList.value = records.filter((record) =>
        record?.expand?.roles?.some((role: any) =>
          role?.name?.includes("Admin")
        )
      );
    } catch (error) {
      console.log("Error getting admin users: ", error);
    }
  };

  onMounted(async () => {
    await getProducts();
  });

  return {
    productsList,
    productsMap,
    activityMap,
    listArea,
    activityList,
    locationList,
    adminUsersList,
    getLocationList,
    getProducts,
    getAdminUsersList,
    getListArea,
    fetchActivities,
  };
});
