import { defineStore } from "pinia";
import { pb } from "@/utils/PocketBaseAdapter";
import type {
  GlobalSetupRecordModel,
  HomeAlerts,
} from "@/utils/PocketBaseAdapter";

export const useGlobalSetupStore = defineStore("GlobalSetup", {
  state: () => ({
    globalSetup: <GlobalSetupRecordModel>{},
    homeAlerts: ref<HomeAlerts[]>([]),
    maxSizeInMB: 2,
  }),

  getters: {
    maxSizeInBytes: (state) => state.maxSizeInMB * 1024 * 1024,
  },

  actions: {
    // 👉 get globalSetup data
    async getGlobalSetup() {
      const record = await pb
        .collection("globalSetup")
        .getOne("hjrxf532dcxwsdp");

      this.globalSetup = record;
    },
    async getHomeAlerts(location: string) {
      try {
        const records = await pb.collection("homeAlerts").getFullList({
          filter: `location= "${location}"`,
        });

        if (records) {
          this.homeAlerts = records;
        }
      } catch (e) {}
    },
  },
});
