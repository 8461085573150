<script lang="ts" setup>
// Interfaces
interface Emit {
  (e: "closeDialog", value: boolean): void;
  (e: "print", value: string): void;
}

interface Props {
  isVisible?: boolean;
  emailed?: string;
  printed?: string;
  haveEditAccess?: boolean;
}

// Props and Emits
const props = defineProps<Props>();
const emit = defineEmits<Emit>();

// References
const email = ref<string>("");
const refReceiptVForm = ref<any>();
const emailedDate = ref<string>(props.emailed || "");
const printedDate = ref<string>(props.printed || "");

// Config
const dateTimeConfig = {
  dateFormat: "d-M-Y h:i K", // Date and Time format with AM/PM
  enableTime: true, // Enable time selection
  time_24hr: false, // Use 12-hour format
};

// Methods
const print = async (type: string) => {
  const { valid } = await refReceiptVForm.value?.validate();
  if (valid) emit("print", type);
};

const closeDialog = () => emit("closeDialog", false);

// Watch Props
watch(
  () => props.isVisible,
  (isVisible) => {
    if (!isVisible) {
      emailedDate.value = "";
      printedDate.value = "";
      email.value = "";
    } else {
      emailedDate.value = props.emailed || "";
      printedDate.value = props.printed || "";
    }
  },
  { immediate: true }
);
</script>

<template>
  <VDialog
    persistent
    :model-value="props.isVisible"
    no-click-animation
    width="600"
  >
    <VCard flat>
      <!-- Header -->
      <div
        class="d-flex align-center justify-space-between bg-dialogHeaderColor"
      >
        <VCardTitle>Print Ticket</VCardTitle>
        <VBtn
          icon="mdi-close"
          size="large"
          variant="text"
          class="rounded-0"
          @click="closeDialog"
        ></VBtn>
      </div>

      <!-- Body -->
      <span class ="pl-4 pt-2">Ticket will be emailed to the booking owner.</span>
       
      <VCardText>
        <VResponsive class="mx-auto">
          <VForm ref="refReceiptVForm" class="mt-2">
            <VRow v-if="haveEditAccess">
              <VCol cols="12">
                <AppDateTimePicker
                  v-model="emailedDate"
                  :config="dateTimeConfig"
                  readonly
                  label="Emailed Date"
                  placeholder="Emailed Date"
                />
              </VCol>
              <VCol cols="12">
                <AppDateTimePicker
                  v-model="printedDate"
                  :config="dateTimeConfig"
                  readonly
                  label="Printed Date"
                  placeholder="Printed Date"
                />
              </VCol>
            </VRow>
          </VForm>
        </VResponsive>

        <!-- Actions -->
        <div class="d-flex justify-center flex-wrap gap-4 my-4 mt-6">
          <VBtn color="primary" @click="print('EMAIL')">Email Ticket</VBtn>
          <VBtn color="primary" @click="print('PRINT')">Print Ticket</VBtn>
          <VBtn color="error" @click="closeDialog">Close</VBtn>
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>
