<script setup lang="ts">
import { useRouter } from "vue-router";

// Interface
interface Props {
  isOpen: boolean;
  dates: string[]; // Assuming `warningDates` is an array of dates that cannot be updated
}

interface Emit {
  (e: "closeDialog", val: boolean): void;
}

// Props
const props = defineProps<Props>();

// Emit
const emit = defineEmits<Emit>();

// Router
const router = useRouter();

// Method
const openBooking = (date: string) => {
  window.open(`${location?.href}?date=${date}`);
};
</script>

<template>
  <VDialog :model-value="props.isOpen" class="v-dialog-md" persistent>
    <VCard class="pricing-dialog pa-4 pa-sm-6">
      <VCardText>
        <div v-if="props.dates.length > 0" class="warning-container">
          <VIcon class="warning-icon" size="large">mdi-alert-circle</VIcon>
          <h3 class="headline">Update Warning</h3>
          <p class="subtext">The following date slots could not be updated:</p>
          <ul class="date-list">
            <li
              v-for="date in props.dates"
              @click="openBooking(date)"
              :key="date"
            >
              {{ date }}
            </li>
          </ul>
          <p class="subtext">Please review these dates and try again.</p>
        </div>
        <div v-else>
          <p class="subtext">All dates have been successfully processed.</p>
        </div>
      </VCardText>

      <VCardActions class="d-flex justify-end">
        <VBtn
          class="close-btn"
          variant="contained"
          @click="emit('closeDialog', false)"
        >
          Close
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style scoped>
.v-dialog-md {
  max-width: 450px; /* Compact width for a modern look */
}

.pricing-dialog {
  max-width: 100%;
  background: #ffffff;
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
  overflow: hidden;
  transition: transform 0.3s ease; /* Smooth transition effect */
}

.pricing-dialog-enter-active,
.pricing-dialog-leave-active {
  transition: opacity 0.3s ease;
}

.pricing-dialog-enter,
.pricing-dialog-leave-to {
  opacity: 0;
}

.close-btn {
  color: #1976d2; /* Blue color for the close button */
}

.warning-container {
  text-align: center;
  padding: 1rem;
  border: 1px solid #ff5722; /* Orange border for warning */
  border-radius: 8px; /* Rounded corners for warning container */
  background-color: #fff3e0; /* Light orange background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Animation for transform and shadow */
}

.warning-container:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.warning-icon {
  color: #ff5722; /* Orange color for the warning icon */
  transition: color 0.2s ease; /* Smooth color transition */
}

.warning-container:hover .warning-icon {
  color: #e64a19; /* Darker orange on hover */
}

.headline {
  font-size: 1.25rem; /* Slightly smaller headline */
  font-weight: 600;
  color: #333; /* Darker text color */
  margin: 0.5rem 0; /* Reduced margin */
}

.subtext {
  font-size: 0.875rem; /* Smaller font size for subtext */
  color: #555; /* Dark gray color for secondary text */
  margin: 0.5rem 0;
}

.date-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  cursor: pointer;
}

.date-list li {
  background: #ffe0b2; /* Light orange background for the date items */
  border: 1px solid #ff5722; /* Orange border */
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  color: #ff5722; /* Orange text color */
  font-weight: 500; /* Semi-bold text for better readability */
  transition: background 0.2s ease, transform 0.2s ease; /* Animation for background and transform */
}

.date-list li:hover {
  background: #ffd180; /* Slightly darker orange on hover */
  transform: translateY(-2px); /* Lift item on hover */
}

.v-btn {
  font-weight: 500; /* Semi-bold text for the button */
}

.close-btn {
  background-color: #d32f2f; /* Red background */
  color: #000 !important; /* Black text */
  font-weight: 600; /* Semi-bold text */
  border-radius: 8px; /* Slightly rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  padding: 8px 16px; /* Adjust padding for better button size */
  text-transform: uppercase; /* Uppercase text for emphasis */
}

.close-btn:hover {
  background-color: #c62828; /* Darker red on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  transform: scale(1.05); /* Slight scale effect on hover */
}

.close-btn:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 4px rgba(211, 47, 47, 0.3); /* Focus ring */
}

.close-btn .v-btn__content {
  text-shadow: none; /* No text shadow for black text */
}
</style>
