<script setup lang="ts">
import { useTheme } from "vuetify";
import ScrollToTop from "@core/components/ScrollToTop.vue";
import { useThemeConfig } from "@core/composable/useThemeConfig";
import { hexToRgb } from "@layouts/utils";
import { collections, pb } from "@/utils/PocketBaseAdapter";
import { useRoute } from "vue-router";
import { useCheckoutStore } from "@/utils/Docket";

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges,
} = useThemeConfig();

const { global } = useTheme();
const route = useRoute();
const checkoutStore = useCheckoutStore();
const { docketNumber } = storeToRefs(checkoutStore);

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme();
syncConfigThemeWithVuetifyTheme();
handleSkinChanges();

const monitorDocketTable = async () => {
  try {
    pb.collection(collections.dockets).subscribe("*", async (dkt) => {
      setTimeout(() => {
        let docketPrint = JSON.parse(
          localStorage.getItem("printDocket") || "{}"
        );
        if (
          dkt &&
          (dkt.record.id == route?.query?.did ||
            dkt.record.id == docketPrint.id)
        ) {
          console.log("update docket id");
          // update the docketNumber to show the correct one
          docketNumber.value = dkt.record.docketNumber;
        }
      }, 500);
    });
  } catch (e) {
    console.log(e);
  }
};

onMounted(async () => {
  await monitorDocketTable();
});
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        global.current.value.colors.primary
      )}`"
    >
      <RouterView />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
