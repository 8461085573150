interface RequestedRoute {
  fullPath?: string;
  hash?: string;
  href?: string;
  matched?: any[];
  meta?: any;
  name?: string;
  params?: any;
  path?: string;
  query?: any;
  redirectedFrom?: string;
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// import { pb } from "@/utils/PocketBaseAdapter";
// export const isUserLoggedIn = async (to: RequestedRoute) => {
//   try {
//     if (pb?.authStore?.isValid) {
//       await pb.collection("users").authRefresh();
//     }
//     const isValid =
//       pb.authStore?.isValid &&
//       pb.authStore["baseModel"]?.firstName &&
//       pb.authStore["baseModel"]?.lastName &&
//       pb.authStore["baseModel"]?.homeLocation;
//     const status = pb?.authStore["baseModel"]?.status;
//     return { isLoggedIn: isValid, userStatus: status };
//   } catch (error) {
//     pb.authStore.clear();
//     sessionStorage.setItem("userOnWayTo", JSON.stringify(to));
//     return { isLoggedIn: false, userStatus: null };
//   }
// };

export const isUserLoggedIn = async (to: RequestedRoute) => {
  try {
    const authData = JSON.parse(
      localStorage.getItem("pocketbase_auth") || "{}"
    );

    const isValid =
      authData?.token &&
      authData?.model?.firstName &&
      authData?.model?.lastName &&
      authData?.model?.homeLocation;

    const status = authData?.model?.status;

    return { isLoggedIn: isValid, userStatus: status };
  } catch (error) {
    console.log(error);
  }
};
