import csvDownload from "json-to-csv-export";

export const useConvertToCSV = (
  data: any[],
  headers: Array<{ title: string; key: string }>,
  filename?: string
) => {
  // Filter data to only include keys specified in headers (excluding the last header)
  const filterDataByHeaders = (
    data: any[],
    headers: Array<{ title: string; key: string }>
  ) => {
    return data.map((item) => {
      const filteredItem: { [key: string]: any } = {};
      headers.slice(0, -1).forEach((header) => {
        if (item.expand && item.expand[header.key]) {
          filteredItem[header.key] =
            item.expand[header.key].name || item.expand[header.key];
        } else {
          filteredItem[header.key] = item[header.key] ?? "";
        }
      });
      return filteredItem;
    });
  };

  // Get the filtered data
  const filteredData = filterDataByHeaders(data, headers);

  // Get the headers titles excluding the last header
  const headerTitles = headers.slice(0, -1).map((header) => header.title);

  const dataToConvert = {
    data: filteredData,
    filename,
    delimiter: ",",
    headers: headerTitles,
  };

  // CSV download
  csvDownload(dataToConvert);
};
