<script setup lang="ts">
// interface
interface Props {
  title: string;
  isVisible: boolean;
  alertMessage?: string;
}

interface Emit {
  (e: "closeDialog", value: boolean): void;
  (e: "confirmEdit", value: string): void;
}

// Props
const props = defineProps<Props>();

// Emit
const emit = defineEmits<Emit>();

// Data
const selectedValue = ref<string>("ALL");
const editRefVForm = ref<any>("");

// method
const handleSubmit = async () => {
  let { valid } = await editRefVForm.value?.validate();
  if (valid) {
    emit("confirmEdit", selectedValue.value);
  }
};
</script>

<template>
  <VDialog
    :model-value="props.isVisible"
    persistent
    no-click-animation
    max-width="500"
  >
    <VCard>
      <div
        class="d-flex align-center justify-space-between bg-dialogHeaderColor"
      >
        <VCardTitle>
          {{ props?.title }}
        </VCardTitle>
        <VBtn
          icon="mdi-close"
          size="large"
          variant="text"
          class="rounded-0"
          color=""
          @click="emit('closeDialog', false)"
        ></VBtn>
      </div>
      <div class="bold_tag">
        <VAlert color="warning" :icon="'mdi-warning'">
          {{ props?.alertMessage }}
        </VAlert>
      </div>
      <VCardText>
        <VForm ref="editRefVForm">
          <VRadioGroup v-model="selectedValue">
            <VRadio label="Just this one" value="SINGLE" />
            <VRadio label="The Entire Series" value="ALL" />
          </VRadioGroup>
        </VForm>
      </VCardText>

      <VCardText class="d-flex align-center justify-center gap-2">
        <VBtn variant="elevated" @click="handleSubmit"> OK </VBtn>

        <VBtn
          color="secondary"
          variant="tonal"
          @click="emit('closeDialog', false)"
        >
          Cancel
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>
